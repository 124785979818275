import { ITag } from "@deeplang/shared";
import { useFloatToolbarStore } from "../store";
import {
  AddPdfExtractRequest,
  DetailRequest,
  DetailResponse,
  ExtractDetailResponse,
} from "../types";

export function useApi() {
  const { request } = useFloatToolbarStore();

  const detail = (data: DetailRequest) => {
    const { type, keyword, colors, channel_type, isFile = true } = data;
    return request<ExtractDetailResponse[]>({
      url: "/api/plugin/extract/detail",
      method: "post",
      data: {
        type,
        keyword,
        colors,
        channel_type,
        is_file: isFile,
      },
    });
  };

  const addPdfExtract = (data: AddPdfExtractRequest) => {
    const { type, content, tags, color, meta_info, channel_type, fileId } =
      data;
    return request<DetailResponse>({
      url: "/api/plugin/extract/add",
      method: "post",
      data: {
        tags,
        color,
        meta_info,
        channel_type,
        file_id: fileId,
        content,
        type,
      },
    });
  };

  const updateExtract = (
    id: string,
    options: { color?: number; tags?: ITag[] },
  ) => {
    return request({
      url: `/api/plugin/extract/update`,
      method: "POST",
      data: {
        extract_id: id,
        ...options,
      },
    });
  };

  const removeExtract = (id: string) => {
    return request({
      url: `/api/plugin/extract/del`,
      method: "POST",
      data: {
        extract_id: id,
      },
    });
  };

  return {
    detail,
    addPdfExtract,
    updateExtract,
    removeExtract,
  };
}
