"use client";
import { createContext } from "react";
import { Maybe } from "@deeplang/shared";
import { IAnyHooks } from "./types";
import { useStore } from "./store/useStore";

export interface Options {
  children?: React.ReactNode;
  defaultParams: IAnyHooks;
}

export const AnyHooksContext =
  createContext<Maybe<IAnyHooks & ReturnType<typeof useStore>>>(null);

export const AnyHooksProvider = (options: Options) => {
  const { children, defaultParams } = options;
  const store = useStore();

  return (
    <AnyHooksContext.Provider value={{ ...defaultParams, ...store }}>
      {children}
    </AnyHooksContext.Provider>
  );
};
