export function scrollIntoView(
  element: HTMLElement,
  spot: { top: number | undefined; left: number | undefined } = {
    top: undefined,
    left: undefined,
  },
  scrollMatches = false,
) {
  let parent = element.offsetParent;
  if (!parent) {
    console.error("offsetParent is not set -- cannot scroll");
    return;
  }

  // 如果父元素的 id 是 pdf-view-container 的话，则不需要进入循环
  const isPdfContainer = (parent as HTMLElement).id === "pdf-view-container";
  let offsetY = element.offsetTop + element.clientTop;
  let offsetX = element.offsetLeft + element.clientLeft;
  while (
    !isPdfContainer ||
    (scrollMatches &&
      (parent.classList.contains("markedContent") ||
        getComputedStyle(parent).overflow === "hidden"))
  ) {
    offsetY += (parent as HTMLElement).offsetTop;
    offsetX += (parent as HTMLElement).offsetLeft;
    parent = (parent as HTMLElement).offsetParent;
    // 如果父元素的 id 是 pdf-view-container 的话，则跳出循环
    if (!parent || (parent as HTMLElement).id === "pdf-view-container") {
      break;
    }
  }
  if (!parent) {
    return;
  }

  if (spot) {
    if (spot.top !== undefined) {
      offsetY += spot.top;
    }
    if (spot.left !== undefined) {
      offsetX += spot.left;
      parent.scrollLeft = offsetX;
    }
  }

  parent.scrollTop = offsetY;
}
