import { IState, AppLayoutProps, IRef } from "../types";
import { useEffect, useRef, useState } from "react";
import { produce } from "immer";
import localforage from "localforage";
import { useBatchAnalyzingDocument } from "@/modules/upload/hooks/useBatchAnalyzingDocument";
import { useUserSetting } from "@/client/hooks/useUserSetting";

export const defaultRef: IRef = {
  previousRoute: "",
  readingReportStatus: true,
};

export const defaultState: IState = {
  sidebar: false,
  userGuideList: [],
  hasGotGuideList: false,
};

function useStore(props: AppLayoutProps) {
  const stateRef = useRef<IRef>(defaultRef);
  const [state, setState] = useState<IState>(defaultState);
  const multiUploadTime = useRef("");
  const pdfIdHashMap = new Map();
  const {
    analyzingList,
    analyzingModalRef,
    updateAnalyzingList,
    updateAnalyzingListByOrder,
    removeAnalyzingItemByOrder,
    setAnalyzingList,
  } = useBatchAnalyzingDocument();

  const { userSetting, updateUserSetting } = useUserSetting();

  useEffect(() => {
    return () => {
      console.log("shoundle onsuhashu ");
      //清除indexDB数据
      localforage
        .clear()
        .then(function () {
          // 当数据库被全部删除后，此处代码运行
          console.log("Database is now empty.");
        })
        .catch(function (err) {
          // 当出错时，此处代码运行
          console.log("Database clear failed", err);
        });
      //清除map数据
      pdfIdHashMap.clear();
    };
  }, []);

  const getMultiUploadTime = () => {
    return multiUploadTime.current;
  };

  const setMultiUploadTime = (time: string) => {
    multiUploadTime.current = time;
  };

  const updateState = (options: Partial<IState> | string, value?: any) => {
    setState((prev) => {
      let newStatus = prev;
      if (typeof options === "string" && value) {
        newStatus = produce(newStatus, (draft: any) => {
          draft[options] = value;
        });
      }
      if (typeof options === "object") {
        newStatus = produce(newStatus, (draft) => {
          Object.assign(draft, options);
        });
      }
      return newStatus;
    });
  };

  const updateStateRef = (options: Partial<IRef>) => {
    if (typeof options === "object") {
      Object.assign(stateRef.current, options);
    }
  };

  return {
    stateRef,
    analyzingModalRef,
    state,
    pdfIdHashMap,
    analyzingList,
    userSetting,
    updateAnalyzingList,
    updateStateRef,
    updateState,
    setState,
    updateAnalyzingListByOrder,
    removeAnalyzingItemByOrder,
    updateUserSetting,
    setAnalyzingList,
  };
}

export default useStore;
