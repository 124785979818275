"use client";
import { useEffect, useRef, useState } from "react";
import { PDFDocumentProxy } from "pdfjs-dist";
import { notFound } from "next/navigation";
import { PDFViewer } from "pdfjs-dist/types/web/pdf_viewer";

import styles from "./index.module.css";
import { IFileRenderData } from "../type";
import "./index.css";
import { FileDownloadExecutor } from "./file-download-executor";

import { useApi } from "../hooks/useApi";
import { usePdfReaderStore } from "../store";
import { extractPdfText, initPDF as initPdf } from "./pdf";

import { Reader } from "./reader";
import { useAppLayout } from "@/provider/app-layout";
import localforage from "localforage";
import { reject } from "lodash-es";
import { useAnyHooksStore } from "@/provider/any-hooks-layout";

/**
 * 这个是 main 方法，是pdf阅读器的中间部分
 * @returns
 */

export default function Main() {
  const [pDocument, setPdfDocument] = useState<PDFDocumentProxy | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const pdfViewRef = useRef<PDFViewer | null>(null);
  const { uploadOriginText, downloadPdfFile } = useApi();

  const [isViolation, setIsViolation] = useState(false);
  const [loadMaterialFail, setLoadMaterialFail] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { pdfIdHashMap } = useAppLayout();
  const { captureException } = useAnyHooksStore();

  const {
    isAutoGeneratedRef,
    materialIsNotExist,
    eventBus,
    options,
    material,
    originTextRef,
    handelRenderPdfDocument,
    updateOriginTextFromPdf,
    cachePdf,
  } = usePdfReaderStore();
  const { disabledAutoGenerated } = options;

  const handleDownloadMaterialError = (e: Error) => {
    captureException(e);
    setLoadMaterialFail(true);
    setIsLoading(false);
    setIsViolation(false);
  };
  useEffect(() => {
    // 没查到相关的资源 id
    if (!material) {
      return;
    }

    const { id, is_safe } = material;

    if (!is_safe) {
      setLoadMaterialFail(false);
      setIsLoading(false);
      setIsViolation(true);
      return;
    }
    try {
      FileDownloadExecutor.setLatestFileId(id);
      //判断是否存在文件缓存
      const pdfHashCache = pdfIdHashMap.get(id);
      if (pdfHashCache) {
        //根据缓存获取文件哈希
        localforage
          .getItem<ArrayBuffer>(pdfHashCache)
          .then(function (value) {
            console.log("读取成功", value);
            const data = {
              isAutoGenerate: isAutoGeneratedRef.current,
              fileId: id,
            };
            if (!value) {
              //需要重新发http
              reject("value is empty");
              return;
            }
            console.log(data);
            fileRender(value, data);
            FileDownloadExecutor.removeExecutor(id);
          })
          .catch(function (err) {
            // 数据库读取失败
            console.log(err);
            //如果读数据失败，还是要http请求数据
            FileDownloadExecutor.addExecutor(
              id,
              downloadPdfFile,
              fileRender,
              {
                isAutoGenerate: isAutoGeneratedRef.current,
                fileId: id,
              },
              handleDownloadMaterialError,
            );
          });
        return;
      }
      FileDownloadExecutor.addExecutor(
        id,
        downloadPdfFile,
        fileRender,
        {
          isAutoGenerate: isAutoGeneratedRef.current,
          fileId: id,
        },
        handleDownloadMaterialError,
      );
    } catch (e) {
      captureException(e as Error);
      setLoadMaterialFail(true);
      setIsLoading(false);
      setIsViolation(false);
    }
  }, [material]);

  const fileRender = async (fileContent: BinaryData, data: IFileRenderData) => {
    let originText;
    const { fileId } = data;
    if (fileId === undefined) {
      return;
    }
    cachePdf && cachePdf(fileId, fileContent);
    try {
      const pdfDocument = await renderPdfViewer(
        fileContent as unknown as string,
      );

      handelRenderPdfDocument && handelRenderPdfDocument(pdfDocument);

      if (disabledAutoGenerated) {
        return;
      }

      originText = await extractPdfText(pdfDocument);
      originTextRef.current = originText;
      updateOriginTextFromPdf(originText);
    } catch (err) {
      console.error(err);
      originText = "";
    }
  };

  useEffect(() => {
    return () => {
      FileDownloadExecutor.clearExecutor();
    };
  }, []);

  const renderPdfViewer = async (fileContent: string) => {
    const pdfDocument = await initPdf(fileContent);

    setPdfDocument(pdfDocument);

    setLoadMaterialFail(false);
    setIsLoading(false);
    setIsViolation(false);

    return pdfDocument;
  };

  if (materialIsNotExist) {
    return notFound();
  }

  return (
    <>
      <div
        className={styles.container}
        ref={containerRef}
        id="pdf-main-container"
      >
        <Reader
          loadMaterialFail={loadMaterialFail}
          isViolation={isViolation}
          isLoading={isLoading}
          pdfDocument={pDocument}
          containerRef={containerRef}
          pdfViewRef={pdfViewRef}
        />
      </div>
    </>
  );
}
