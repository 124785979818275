import { HistoryType } from "@deeplang/shared";
import { useEffect } from "react";

import HistoryResult from "@/components/library-search/modules/HistoryResult";
import { useSearchHistory } from "../hooks/useSearchHistory";

import { useApi } from "../hooks/useApi";
import { InitSearch } from "./InitSearch";

export function History() {
  const { histories, requestHistories } = useSearchHistory();
  const { addHistory, deleteHistory } = useApi();

  useEffect(() => {
    requestHistories();
  }, []);

  if (histories.length === 0) {
    return <InitSearch />;
  }
  return (
    <HistoryResult
      historyList={histories}
      addHistory={addHistory}
      deleteHistory={deleteHistory}
      historyType={HistoryType.Chart}
      getHistories={requestHistories}
    />
  );
}
