import {
  ID_DIVISION,
  DATASET_IDENTIFIER,
  CAMEL_DATASET_IDENTIFIER,
  CAMEL_DATASET_IDENTIFIER_EXTRA,
} from './const';

import { RootElement, Interaction } from './types';

export const isHighlightWrapNode = (node: HTMLElement): boolean => {
  if (node.dataset) {
    return CAMEL_DATASET_IDENTIFIER in node.dataset;
  }
  return false;
};

const findAncestorWrapperInRoot = (node: HTMLElement, root: RootElement) => {
  let isInSideRoot = false;
  let wrapper: HTMLElement | null = null;
  while (node) {
    if (isHighlightWrapNode(node)) {
      wrapper = node;
    }

    if (node === root) {
      isInSideRoot = true;
      break;
    }

    node = node.parentNode as HTMLElement;
  }

  return isInSideRoot ? wrapper : null;
};

export const getHighlightId = (node: HTMLElement, root: RootElement): string => {
  node = findAncestorWrapperInRoot(node, root) as HTMLElement;
  if (!node) {
    return '';
  }

  return node.dataset[CAMEL_DATASET_IDENTIFIER] as string;
};

export const getExtraHighlightIds = (node: HTMLElement, root: RootElement): string[] => {
  node = findAncestorWrapperInRoot(node, root) as HTMLElement;
  if (!node) {
    return [];
  }
  return node.dataset[CAMEL_DATASET_IDENTIFIER_EXTRA]?.split(ID_DIVISION).filter((id) => id) || [];
};

/**
 * Get all highlight wrap nodes in root
 */
export const getHighlightsByRoot = (
  roots: RootElement | RootElement[],
  wrapTag: string,
): HTMLElement[] => {
  if (!Array.isArray(roots)) {
    roots = [roots];
  }

  const highlightWraps: HTMLElement[] = [];
  for (const root of roots) {
    const list = Array.from(
      root.querySelectorAll(`${wrapTag}[data-${DATASET_IDENTIFIER}]`),
    ) as HTMLElement[];
    highlightWraps.push(...list);
  }

  return highlightWraps;
};

export const getHighlightsById = (
  root: RootElement,
  id: string,
  wrapTag: string,
): HTMLElement[] => {
  const highlights: HTMLElement[] = [];
  const reg = new RegExp(`(${id}\\${ID_DIVISION}|\\${ID_DIVISION}?${id}$)`);
  const list = root.querySelectorAll<HTMLElement>(`${wrapTag}[data-${DATASET_IDENTIFIER}]`);
  list.forEach(item => {
    const node = item;
    const highlightId = node.dataset[CAMEL_DATASET_IDENTIFIER];
    if (highlightId === id) {
      highlights.push(node);
      return;
    }

    const extraHighlightIds = node.dataset[CAMEL_DATASET_IDENTIFIER_EXTRA];
    if (reg.test(extraHighlightIds as string)) {
      highlights.push(node);
      return;
    }
  });

  return highlights;
};

export const each = (
  nodes: NodeList,
  callback: (node: HTMLElement, index: number, array: NodeList) => void,
) => {
  for (let i = 0; i < nodes.length; i++) {
    callback(nodes[i] as HTMLElement, i, nodes);
  }
};

export const removeEventListener = (el: RootElement, event: string, handler: EventListener) => {
  el.removeEventListener(event, handler);
};

export const addEventListener = (el: RootElement, event: string, handler: EventListener) => {
  el.addEventListener(event, handler);

  return () => removeEventListener(el, event, handler);
};

export const addClass = (el: HTMLElement, className: string | string[]) => {
  if (!Array.isArray(className)) {
    className = [className];
  }

  el.classList.add(...className);
};

export const removeClass = (el: HTMLElement, className: string) => {
  el.classList.remove(className);
};

export const removeAllClass = (el: HTMLElement) => {
  el.className = '';
};

export const hasClass = (el: HTMLElement, className: string) => {
  return el.classList.contains(className);
};

export const getInteraction = (): Interaction => {
  // TODO support touch event
  return {
    PointerEnd: 'mouseup',
    PointerTap: 'click',
    PointerOver: 'mouseover',
  };
};
