import { useState } from "react";
import { useApi } from "./useApi";

export function useSearchHistory() {

  const [histories, setHistories] = useState<string[]>([]);

  const { getHistories } = useApi();
  const requestHistories = async () => {

    try {
      const res = await getHistories();
      if(!res) {
        return;
      }

      setHistories(res);
    } catch{
      setHistories([]);
    }
  };  

  
  return {
    requestHistories,
    histories,
  }
}