import { useState, useEffect, useRef } from "react";

import useTag from "../../hooks/useTag";
import TagInput, { Action } from "../tag-input";
import TagList from "../tag-list";

import buryHatchet from "@/batch/index";
import { BATCH } from "@/batch/type";

import styles from "./TagPanel.module.css";
import { ThirdParty } from "../../types";
import { ITag, ReadingSource } from "@deeplang/shared";
import { useApi } from "../../hooks/useApi";
import Button, { ButtonType } from "@/ui/button";

export type TagPanelProps = {
  // 摘录/图片生成后的 id
  id: string;
  extract: any;
  tags?: ITag[];
  thirdParty: ThirdParty;
  updateExtract: (tags: ITag[]) => void;
  channelType: ReadingSource;
  close: () => void;
};

const MAX_TAG_COUNT = 10;

function TagPanel(props: TagPanelProps) {
  const { id, updateExtract, channelType, close } = props;
  const [value, setValue] = useState("");
  const [lock, setLock] = useState(false);
  const [listTags, setListTags] = useState<ITag[]>([]);
  const [selectTags, setSelectTags] = useState<ITag[]>([]);
  const { list: tags, getTagList, onCreateTag } = useTag();
  const firstUpdate = useRef(true);
  const tagClicked = useRef(false);
  const track = buryHatchet.track<BATCH.TModuleName>("Tag");

  const { detail } = useApi();

  useEffect(() => {
    // @ts-expect-error
    setListTags(tags);
  }, [tags]);

  useEffect(() => {
    try {
      getTagList();
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    async function getExtract() {
      try {
        const res = await detail({
          type: 0,
          keyword: id,
          channel_type: channelType,
          isFile: true,
        });

        // @ts-expect-error
        setSelectTags(res[0]?.tags || []);
      } catch (err) {
        console.log(err);
      }
    }

    if (id) {
      getExtract();
    }
  }, [id]);

  useEffect(() => {
    if (lock) {
      return;
    }
    if (value) {
      setListTags(listTags.filter((tag) => tag.tag.includes(value)));
    } else {
      // @ts-expect-error
      setListTags(tags);
    }
  }, [lock, value]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (tagClicked.current) {
      updateExtract?.(selectTags);
      tagClicked.current = false;
    }
  }, [selectTags]);

  // select tag when click
  const handleTagSelect = (tag: ITag) => {
    tagClicked.current = true;
    let included = false;
    for (const t of selectTags) {
      if (t.id === tag.id) {
        included = true;
      }
    }

    if (!included) {
      // if the tag is not selected, add it to the selected tags
      setSelectTags([...selectTags, tag]);
    } else {
      // if the tag is selected, remove it from the selected tags
      setSelectTags(selectTags.filter((t) => t.id !== tag.id));
    }
    setValue("");
  };

  const handleTag = async (action: Action) => {
    tagClicked.current = true;
    const { action: tagAction, value: tagValue } = action;

    if (tagAction === "add" && tagValue) {
      const newTag = tagValue.toLowerCase();
      const findTag = listTags.find((tag) => tag.tag === newTag);
      if (findTag) {
        const includeTag = selectTags.find((tag) => tag.tag === newTag);
        console.log(includeTag);
        if (includeTag) {
          return;
        }
        setSelectTags([...selectTags, findTag]);
        return;
      }
      const result: any = await onCreateTag(newTag);

      let isSuccess = false;

      if (result) {
        setSelectTags([
          ...selectTags,
          {
            id: result.id,
            tag: newTag,
          },
        ]);
        getTagList();
        setValue("");
        isSuccess = true;
      }
      // track
      track(BATCH.EventName.CreateLabel, {
        label_content: newTag,
        label_content_type: "摘录",
        label_source: "摘录",
        is_success: isSuccess,
      });
    } else if (tagAction === "remove") {
      if (!tagValue) {
        if (selectTags.length === 0) {
          return;
        } else {
          setSelectTags(selectTags.slice(0, selectTags.length - 1));
        }
      } else {
        setSelectTags(selectTags.filter((tag) => tag.tag !== tagValue));
      }
    }
  };

  const handleClear = () => {
    if (selectTags.length === 0) {
      return;
    }
    setSelectTags([]);
    updateExtract?.([]);
  };

  return (
    <div className={styles.panel}>
      <div className={styles.header}>
        <span className={styles.titleText}>标签</span>
        <span className={styles.clear} onClick={handleClear}>
          清空
        </span>
      </div>
      <div className={styles.main}>
        <TagInput
          tags={selectTags}
          value={value}
          onChange={setValue}
          onAction={handleTag}
          onLock={setLock}
          maxTagCount={MAX_TAG_COUNT}
        />
        <TagList
          value={value}
          maxTagCount={MAX_TAG_COUNT}
          tags={listTags}
          selectedTags={selectTags}
          onTagSelect={handleTagSelect}
          onAction={handleTag}
        />
      </div>
      <div className={styles.footer}>
        <Button onClick={close} type={ButtonType.Primary} className="text-sm">
          确定
        </Button>
      </div>
    </div>
  );
}

export default TagPanel;
