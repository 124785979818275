import { eventBus } from "@/utils/event-bus";
import { ThirdParty } from "./type";
import { throttle } from "lodash-es";

type TSection = {
  positionId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  index?: string;
  elementId?: string;
};
export function checkIsThirdPartyAvailability(thirdParty: ThirdParty) {
  const { request, PubSub } = thirdParty;
  if (!request) {
    throw new Error(`Request must be provided`);
  }
  if (!PubSub) {
    throw new Error(`PubSub must be provided`);
  }
  return true;
}

abstract class BaseSectionSDK {
  protected _sectionDomMapByPositionId = new Map<string, HTMLDivElement>();
  // 存储 positionId 关联的 positionIds
  protected _positionIdRelationShipMap = new Map<string, string[]>();
  // 存储所有监听事件的移除函数
  protected removedListeners: (() => void)[] = [];
  protected positionType: "text" | "" = "";

  protected highlightElement(element: HTMLDivElement) {
    element.style.border = "1px solid rgba(226, 220, 174, 0.80)";
    element.style.backgroundColor = "rgba(250, 255, 0, 0.10)";
    element.setAttribute("data-highlight", "true");
  }
  protected unHighlightElement(element: HTMLDivElement) {
    element.style.border = "0";
    element.style.backgroundColor = "transparent";
    element.setAttribute("data-highlight", "false");
    document.body.style.cursor = "";
  }

  protected getPageContainer(pageNumber: number, container: Element) {
    return container.querySelector<HTMLDivElement>(
      `div[data-page-number="${pageNumber}"]`,
    );
  }

  updatePositionIdRelationShipMap(value: Map<string, string[]>) {
    // clone value
    this._positionIdRelationShipMap = new Map(value);
  }
  clearPositionIdRelationShipMap() {
    this._positionIdRelationShipMap.clear();
  }
  /**
   * 根据位置 id 查找 section dom
   * @param positionId 位置 id
   * @param pageNumber 页码
   * @returns
   */
  findSectionDomByPositionId(positionId: string) {
    const targetSection = this._sectionDomMapByPositionId.get(positionId);
    // this._sectionDomMap.forEach((sections) => {
    //   const section = sections.find((section) => {
    //     return section.getAttribute("data-position-id") === positionId;
    //   });
    //   if (section) {
    //     targetSection = section;
    //   }
    // });
    if (!targetSection) {
      return null;
    }

    return targetSection;
  }

  createSection(pageNumber: number, section: TSection, container: Element) {
    const pageContainer = this.getPageContainer(pageNumber, container);
    if (!pageContainer) {
      Promise.reject(new Error(`${pageNumber} PageContainer not found`));
      return;
    }

    // 为了配合 pdfjs 的懒加载模式，不需要判断是否已经存在 section，直接创建
    const sectionDom = this.createSectionDom(section, pageNumber);
    pageContainer.appendChild(sectionDom);
    this._sectionDomMapByPositionId.set(section.positionId, sectionDom);
  }

  protected createSectionDom(sec: TSection, pageNumber: number) {
    const section = document.createElement("div");
    section.style.position = "absolute";
    section.style.border = "0";
    section.style.borderRadius = "8px";
    section.style.left = `${sec.x}px`;
    section.style.top = `${sec.y}px`;
    section.style.width = `${sec.width}px`;
    section.style.height = `${sec.height}px`;
    section.style.zIndex = "1";
    section.setAttribute("data-index", sec?.index || "");
    section.setAttribute("data-element-id", sec?.elementId || "");
    section.setAttribute("data-position-id", sec.positionId);
    section.setAttribute("data-highlight", "false");
    section.setAttribute("data-page-number", pageNumber + "");
    if(this.positionType !== "") {
      section.setAttribute("data-position-type", this.positionType);
    }

    return section;
  }

  addHighlightByPositionId(positionId: string) {
    const section = this._sectionDomMapByPositionId.get(positionId);
    if (!section) {
      return;
    }
    this.highlightElement(section);
  }

  removeHighlight() {
    this._sectionDomMapByPositionId.forEach((section) => {
      this.unHighlightElement(section);
    });
  }
  destroyAllSections() {
    this._sectionDomMapByPositionId.clear();
  }

  removeListeners() {
    this.removedListeners.forEach((removeListener) => {
      removeListener();
    });
  }

  clearRemovedListeners() {
    this.removedListeners = [];
  }

  // 销毁所有内存数据
  destroy() {
    this.destroyAllSections();
    this.clearPositionIdRelationShipMap();
    this.removeListeners();
    this.clearRemovedListeners();
  }
}
class TwoWaySectionSDK extends BaseSectionSDK {
  createPagesEvent(pageNumber: number, container: Element) {
    const pageContainer = this.getPageContainer(pageNumber, container);
    if (!pageContainer) {
      return;
    }
    const removeListener = this.createPageEvent(pageContainer, pageNumber);
    this.removedListeners.push(removeListener);
  }

  createPageEvent(pageContainer: HTMLDivElement, pageNumber: number) {
    const throttledMouseMove = throttle(
      (e: MouseEvent, pageContainer: HTMLDivElement) => {
        const validSections = this.isMouseInPageContainer(
          pageContainer,
          e.clientX,
          e.clientY,
          pageNumber,
        );

        if (validSections?.length) {
          validSections.forEach((section) => {
            // 从 positionIdRelationShipMap 中获取关联的 positionIds
            const positionId = section?.getAttribute("data-position-id");
            const elementId = section?.getAttribute("data-element-id");

            if (!positionId) {
              return;
            }

            if (elementId && section) {
              // this.highlightElement(section);
              document.body.style.cursor = "zoom-in";
              return;
            }

            const positionIds = this._positionIdRelationShipMap.get(positionId);
            if (!positionIds) {
              return;
            }
            // 高亮关联的 section
            positionIds.forEach((positionId) => {
              const section = this._sectionDomMapByPositionId.get(positionId);
              if (section) {
                this.highlightElement(section);
              }
            });
          });
        } else {
          // 鼠标不在 section 中，移除所有高亮
          this.removeHighlight();
        }
      },
      50,
    );

    const handleMouseMove = (e: MouseEvent) => {
      throttledMouseMove(e, pageContainer);
    };

    pageContainer.addEventListener("mousemove", handleMouseMove);

    const handleClick = (e: MouseEvent) => {
      // 计算按下时，鼠标在 pageContainer 中的相对位置
      const validSections = this.isMouseInPageContainer(
        pageContainer,
        e.clientX,
        e.clientY,
        pageNumber,
      );
      // 如果鼠标在 section 中，触发点击事件
      if (!validSections?.length) {
        // 点击空白处，移除所有高亮
        // this.removeHighlight();
        return;
      }
      validSections.forEach((section) => {
        const positionId = section?.getAttribute("data-position-id");
        if (!positionId) {
          return;
        }

        const elementId = section?.getAttribute("data-element-id");
        if (elementId && section) {
          const index = section.getAttribute("data-index");
          eventBus.emit("PDF_IMG_CLICK", { index });
        }

        // 从 positionIdRelationShipMap 中获取关联的 positionIds
        const positionIds = this._positionIdRelationShipMap.get(positionId);
        if (!positionIds) {
          return;
        }
        // 发送关联的 positionIds
        console.log("click section", positionId);
      });
    };
    pageContainer.addEventListener("click", handleClick);

    return () => {
      pageContainer.removeEventListener("mousemove", handleMouseMove);
      pageContainer.removeEventListener("click", handleClick);
    };
  }

  // 判断鼠标位置是否在 pageContainer 中
  private isMouseInPageContainer(
    pageContainer: HTMLDivElement,
    x: number,
    y: number,
    pageNumber: number,
  ) {
    // 计算鼠标在 pageContainer 中的相对位置
    const rect = pageContainer.getBoundingClientRect();
    const relativeX = x - rect.left;
    const relativeY = y - rect.top;
    // 获取当前页面的所有 section
    const sections = Array.from(this._sectionDomMapByPositionId).map(
      (section) => {
        if (section[1].getAttribute("data-page-number") === pageNumber + "") {
          return section[1];
        }
        return null;
      },
    );

    // 判断鼠标是否在 sections 中
    const validSections = sections.filter((section) => {
      if (!section) {
        return false;
      }
      const sectionRect = section.getBoundingClientRect();
      return (
        relativeX >= sectionRect.left - rect.left &&
        relativeX <= sectionRect.left - rect.left + sectionRect.width &&
        relativeY >= sectionRect.top - rect.top &&
        relativeY <= sectionRect.top - rect.top + sectionRect.height
      );
    });
    return validSections;
  }
}

class OneWaySectionSDK extends BaseSectionSDK {
  // 因为 pdfjs 的懒加载模式，只渲染附近的几页，多文档下，高亮的原文信息覆盖多页
  // 会出现高亮的原文信息覆盖不全的情况，所以需要记录当前高亮的 positionId
  private currentHighlightPositionId: string[] = [];

  constructor() {
    super();
    this.positionType = "text";
  }

  createPageEvent(pagesContainer: HTMLDivElement) {
    // 定义点击事件回调函数
    const handleClick = () => {
      this.removeHighlight();
    };
    // 监听页面点击事件，点击页面空白处，移除所有高亮
    pagesContainer.addEventListener("click", handleClick);

    // 增加移除监听事件的函数
    this.removedListeners.push(() => {
      pagesContainer.removeEventListener("click", handleClick);
    });
  }

  // check 一下当前需要高亮的 positionId 是否在已经被 pdfjs 渲染的页面中高亮
  // 如果没有，需要重新渲染
  checkHighlightPositionIdInRenderedPage(pagesContainer: HTMLDivElement) {
    // 获取所有已经被渲染的页面，该页面的 data-loaded 属性为 true
    const renderedPageNumbers = Array.from(
      pagesContainer.querySelectorAll<HTMLDivElement>(
        `div[data-loaded="true"]`,
      ),
    ).map((page) => {
      return parseInt(page.getAttribute("data-page-number") || "");
    });
    // 如果当前需要高亮的 positionId 在已经被渲染的页面中未高亮，则重新高亮
    this.currentHighlightPositionId.forEach((positionId) => {
      const section = this._sectionDomMapByPositionId.get(positionId);
      if (!section) {
        return;
      }
      const pageNumber = parseInt(
        section.getAttribute("data-page-number") || "",
      );

      if (!renderedPageNumbers.includes(pageNumber)) {
        return;
      }

      // 如果已经高亮了，不需要再次高亮
      if (section.getAttribute("data-highlight") === "true") {
        return;
      }
      this.highlightElement(section);
    });
  }
  removeHighlight() {
    super.removeHighlight();
    this.currentHighlightPositionId = [];
  }

  // 这里跟 removeHighlight 逻辑不太一样，因为需要保留当前高亮的 positionId，所以需要单独写一个方法
  clearHighlight() {
    super.removeHighlight();
  }

  updateCurrentHighlightPositionId(positionIds: string[]) {
    this.currentHighlightPositionId = positionIds;
  }
}

const twoWaySectionSDK = new TwoWaySectionSDK();

export const oneWaySectionSDK = new OneWaySectionSDK();
export const imgViewSectionSDK = new TwoWaySectionSDK();

export default twoWaySectionSDK;
