import { singleton } from "../utils";
import { BATCH } from "./type";
import { Sensors } from "./sensors";

/**
 * 为了让 singleton 返回的（通过 proxy 代理了）类型与 BuryHatchet 类的类型保持一致，
 * 这里需要将 BuryHatchet 类导出，否则 ts 无法推断私有或保护属性是否可用
 */
export class BuryHatchet {
  private _self: BATCH.Strategy | null = null;

  init(name: BATCH.Name) {
    if (this._self) {
      return;
    }

    switch (name) {
      case "sensors":
        this._self = new Sensors();
        break;
    }
  }

  track<K = BATCH.TModuleName>(moduleName: K) {
    if (!this._self) {
      throw new Error("埋点实例未初始化");
      // return;
    }

    return this._self.track(moduleName);
  }

  destroy() {
    this._self = null;
  }

  login(uid: string) {
    this._self?.login(uid);
  }

  setProfile(properties: BATCH.ProfileProperties) {
    this._self?.setProfile(properties);
  }

  setOnceProfile(properties: BATCH.OnceProfileProperties) {
    this._self?.setOnceProfile(properties);
  }

  registerPublicProperties(publicProperties: BATCH.PublicProperties) {
    this._self?.registerPublicProperties(publicProperties);
  }
}

const SingleBuryHatchet = singleton(BuryHatchet);
export default new SingleBuryHatchet();

// export default new BuryHatchet();
