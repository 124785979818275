import { useContext } from "react";
import { IRelatedCharts, ThirdParty } from "../types";
import { RelatedChartsContext } from "./provider";
import { useStore } from "./all-store";

export * from "./provider";

type CommonType = ThirdParty & {
  material: IRelatedCharts["material"];
  defaultElementListRef: IRelatedCharts["defaultElementListRef"];
} & ReturnType<typeof useStore>;
type State = {
  [P in keyof CommonType]: CommonType[P];
};
export const useRelatedChartsStore = (): State => {
  const state = useContext(RelatedChartsContext);

  if (state === null) {
    throw new Error(
      "useRelatedChartsStore must be used within a SideBarProvider",
    );
  }

  const {
    thirdParty,
    material,
    drawerRef,
    externalMaterial,
    updateExternalMaterial,
    imageViewerRef,
    isCollapsed,
    activeElement,
    defaultElementListRef,
    setActiveElement,
    selectedRelateChartRef,
    imageViewerOperationBarRef,
    chartListContainerRef,
  } = state;

  return {
    ...thirdParty,
    material,
    drawerRef,
    externalMaterial,
    updateExternalMaterial,
    imageViewerRef,
    isCollapsed,
    activeElement,
    defaultElementListRef,
    setActiveElement,
    selectedRelateChartRef,
    imageViewerOperationBarRef,
    chartListContainerRef,
  };
};
