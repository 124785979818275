import { useContext } from 'react';
import { ThirdParty } from '../types';
import { FloatToolbarContext } from './provider';

export * from './provider';

type CommonType = ThirdParty;
type State = {
  [P in keyof CommonType]: CommonType[P];
}
export const useFloatToolbarStore = (): State => {
  const state = useContext(FloatToolbarContext);

  if (state === null) {
    throw new Error('useFloatToolbarStore must be used within a SideBarProvider');
  }

  const { thirdParty } = state;
  
  return {
    ...thirdParty,
  };
};
