import { useRef, useState } from "react";
import { useApi } from "./useApi";
import { CheckCanAddToLibraryRequest } from "@deeplang/shared";

export function useCheckCanAddToLibrary() {
  const [canAdd, setCanAdd] = useState(false);
  const canAddRef = useRef(false);
  const { checkCanAddToLibrary } = useApi();

  const runCheckCanAddToLibrary = async (
    props: CheckCanAddToLibraryRequest,
  ) => {
    try {
      const res = await checkCanAddToLibrary(props);

      if (!res) {
        setCanAdd(false);
        return;
      }

      setCanAdd(res.can_copy);
      canAddRef.current = res.can_copy;
    } catch {
      setCanAdd(false);
      canAddRef.current = false;
    }
  };

  return {
    runCheckCanAddToLibrary,
    canAdd,
    setCanAdd,
    canAddRef,
  };
}
