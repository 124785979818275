export const mockUrlSet = new Set<string>([
  //   "/api/multi/merge",
  // "/api/readers/parse/status",
  // "/api/multi/detail",
  // "/api/readers/url/parse/stop",
  // "/api/readers/url/upload",
  // "/api/multi/summary_stop",
  // "/api/plugin/file/batch/delete",
  // "/api/url/batch/upload",
  // "/api/readers/url/parse/stop",
  // "/api/plugin/file/add",
  // "/api/readers/url/retry",
  '/api/plugin/users/inviteTest/status',
]);
