"use client";
import { Spin } from "@deeplang/dui";
import styles from "./loading.module.css";
import Image from "next/image";

export function PDFRenderLoading() {
  return (
    <div className="absolute top-0 left-0 flex w-full h-full bg-[#DDDDDD] z-[1000]">
      <div className="w-full flex items-center justify-center dl-skeleton-item2">
        <Image
          width={100}
          height={65}
          alt="loading"
          src="https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/lingoreader/pic/lingo_text_white_logo.svg"
        />
      </div>
    </div>
  );
}
