import { nanoid } from 'nanoid';
import HighlightSource from './source';
import { CAMEL_DATASET_IDENTIFIER, ROOT_IDX, UNKNOWN_IDX } from './const';
import { DOMMeta, DOMNode, HookMap } from './types';
import type Hook from './hook';

const countGlobalNodeIndex = (node: Node, root: HTMLElement | Document): number | string => {

  if(node instanceof HTMLElement) {
    const id = node.getAttribute('id') || '';
    if(id !== null && id !== '') {
      return id;
    }
  }
  const tagName = (node as HTMLElement).tagName;
  const tags = root.getElementsByTagName(tagName);
  for (let i = 0; i < tags.length; i++) {
    if (tags[i] === node) {
      return i;
    }
  }

  return UNKNOWN_IDX;
};

const getTextPreOffset = (root: Node, text: Node): number => {
  const nodeStack: Node[] = [root];
  let curNode: Node | null;
  let offset = 0;

  while ((curNode = nodeStack.pop() || null)) {
    const children = curNode.childNodes;
    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i];
      if(!child) {
        continue;
      }
      nodeStack.push(child);
    }

    // If we're not at the text node yet, keep adding to the offset.
    if (curNode.nodeType === Node.TEXT_NODE && curNode !== text) {
      offset += curNode.textContent?.length ?? 0;
    } else if (curNode === text) {
      // If we're at the text node, we're done.
      break;
    }
  }
  return offset;
};

export const formatDOMNode = (node: DOMNode): DOMNode => {
  // If the node is a text, comment, or CDATA node, it has no children.
  // Return the node as-is.
  if (
    node.node.nodeType === Node.TEXT_NODE ||
    node.node.nodeType === Node.COMMENT_NODE ||
    node.node.nodeType === Node.CDATA_SECTION_NODE
  ) {
    return node;
  }

  // Otherwise, return the first child node of the given node.
  return {
    node: node.node.childNodes[node.offset] || node.node,
    offset: 0,
  };
};

export const removeSelection = (): void => {
  window.getSelection()?.removeAllRanges();
};

export const getDOMRange = (): Range | null => {
  const selection = window.getSelection();
  if (!selection || selection.isCollapsed || selection.rangeCount === 0) {
    return null;
  }
  return selection.getRangeAt(0);
};

const getOriginParent = (node: HTMLElement | Text): HTMLElement => {
  if (node instanceof HTMLElement && (!node.dataset || !node.dataset[CAMEL_DATASET_IDENTIFIER])) {
    return node;
  }
  let originParent = node.parentNode as HTMLElement;
  while (originParent?.dataset?.[CAMEL_DATASET_IDENTIFIER]) {
    originParent = originParent.parentNode as HTMLElement;
  }

  let hasParentIdElement = originParent;
  while(hasParentIdElement) {
    if(hasParentIdElement.getAttribute('id') !== null) {
      break;
    }
    if(hasParentIdElement.className === 'textLayer') {
      hasParentIdElement = originParent;
      break;
    }
    hasParentIdElement = hasParentIdElement.parentNode as HTMLElement;
  } 
  return hasParentIdElement;
};

export const getDOMMeta = (
  node: HTMLElement | Text,
  offset: number,
  root: Document | HTMLElement,
): DOMMeta => {
  const originParent = getOriginParent(node);
  const parentIndex = originParent === root ? ROOT_IDX : countGlobalNodeIndex(originParent, root);
  const parentTagName = originParent.tagName;
  const preNodeOffset = getTextPreOffset(originParent, node);
  return {
    parentTagName,
    parentIndex,
    textOffset: preNodeOffset + offset,
  };
};

class HighlightRange {
  static removeDOMRange = removeSelection;
  startNode: DOMNode;
  endNode: DOMNode;
  text: string;
  id: string;
  frozen: boolean;

  constructor(startNode: DOMNode, endNode: DOMNode, text: string, id: string, frozen = false) {
    this.startNode = startNode;
    this.endNode = endNode;
    this.text = text;
    this.id = id;
    this.frozen = frozen;
  }

  static fromSelection(idHook: Hook<any>) {
    const range = getDOMRange();
    if (!range) {
      return null;
    }

    const startNode: DOMNode = {
      node: range.startContainer,
      offset: range.startOffset,
    };
    const endNode: DOMNode = {
      node: range.endContainer,
      offset: range.endOffset,
    };

    const text = range.toString();
    let id = idHook.call(startNode, endNode, text);
    id = id ?? nanoid();

    return new HighlightRange(startNode, endNode, text, id);
  }

  serialize(root: Document | HTMLElement, hooks: HookMap): HighlightSource {
    const startNodeMeta = getDOMMeta(this.startNode.node as Text, this.startNode.offset, root);
    const endNodeMeta = getDOMMeta(this.endNode.node as Text, this.endNode.offset, root);
    let extra;
    if (!hooks.Serialize.RecordInfo.isEmpty()) {
      extra = hooks.Serialize.RecordInfo.call(this.startNode, this.endNode, root);
    }

    this.frozen = true;

    return new HighlightSource(startNodeMeta, endNodeMeta, this.text, this.id, extra);
  }
}

export default HighlightRange;
