import extractPageContent from "./usePage";
import { SummaryType, TabTypeEnum } from "../constants";
import {
  CommunicationStoreProps,
  IUseLibrary,
  IUseQuery,
  IUseRead,
  TTabType,
} from "../types";
import { ReadingSource } from "@deeplang/shared";

/**
 * @title 通信hooks
 * @description: 基于全局store和 storage，来实现面板之间相互通信
 * @param {boolean} open 是否需要打开面板
 * @param {TTabType} tab 目标面板
 * @param {string} url 页面的 url
 * @param {string} title 页面的 title
 * @param {*} data 通信参数，根据各个业务需求，参数为实际的参数对象
 * @return {}
 */

interface NavigateViews {
  openPanel: (tab?: TTabType) => void;
  openRead: (data: IUseRead) => void;
  openReadExtract: () => void;
  openQuery: (data: IUseQuery) => void;
  openLibrary: (data: IUseLibrary) => void;
}

function useCommunication(
  props: CommunicationStoreProps | undefined,
): NavigateViews {
  if (!props) {
    return {
      openLibrary(data) {
        return;
      },
      openPanel(tab) {
        return;
      },
      openQuery(data) {
        return;
      },
      openRead(data) {
        return;
      },
      openReadExtract() {
        return;
      },
    };
  }

  const {
    open,
    setOpen,
    setPluginTab,
    setGenerateTarget,
    setRegenerateTarget,
    setGeneratePosition,
    setBatchParams,
    setSummaryBatchParams,
    setWebInfo,
    setParagraphInfo,
    setActiveTab,
    setQuery,
  } = props;

  return {
    // 仅打开面板
    openPanel: (tab?: TTabType) => {
      setOpen(true);
      if (!tab) {
        const pageInfo = extractPageContent(SummaryType.Article, 50);
        const { url, title, originalContent, content } = pageInfo;
        setWebInfo({
          url,
          title,
          html: content,
          content: originalContent,
          entryType: ReadingSource.LingoUrl,
        });
      }
      setPluginTab(tab || TabTypeEnum.Read);
      if (!tab) {
        setActiveTab("generate");
      }
    },

    // 打开阅读辅助面板, 展示生成tab
    openRead: (data: IUseRead) => {
      const { auto = true } = data;
      const pageInfo = extractPageContent(data.summaryType, 50);
      if (!open) {
        setOpen(true);
      }
      setPluginTab(TabTypeEnum.Read);
      const { url, title, originalContent, content } = pageInfo;
      setActiveTab("generate");
      // 生成全文概述
      if (data.summaryType === SummaryType.Article) {
        setWebInfo({
          url,
          title,
          html: content,
          content: originalContent,
          entryType: ReadingSource.LingoPdf,
        });
        setBatchParams({
          button_name: "固定工具栏生成",
          button_position: "固定工具栏",
          content_source: "全文",
        });
        setSummaryBatchParams({
          button_name: "固定工具栏生成",
          button_position: "固定工具栏",
          content_source: "全文",
        });
        if (auto) {
          setGenerateTarget(data.summaryType);
          setGeneratePosition("sidebar");
        }
      }
      // 生成段落概述
      if (data.summaryType === SummaryType.Paragraph) {
        setWebInfo({
          url,
          title,
          content: originalContent,
          entryType: ReadingSource.LingoUrl,
        });
        setParagraphInfo({
          original: content,
          metaInfo: JSON.stringify({}),
          // metaInfo: metaInfo
        });
        setBatchParams({
          button_name: "浮动工具栏生成",
          button_position: "浮动工具栏",
          content_source: "段落",
        });
        if (auto) {
          setGenerateTarget(data.summaryType);
          setGeneratePosition("toolbar");
        }
      }
    },

    // 打开阅读辅助棉服，展示摘录tab
    openReadExtract: () => {
      if (!open) {
        setOpen(true);
      }
      setPluginTab(TabTypeEnum.Read);
      const pageInfo = extractPageContent(SummaryType.Article, 50);
      const { url, title, originalContent, content } = pageInfo;
      setWebInfo({
        url,
        title,
        html: content,
        content: originalContent,
        entryType: ReadingSource.LingoUrl,
      });
      setActiveTab("extract");
      setGenerateTarget(undefined);
      setRegenerateTarget(undefined);
    },

    // 打开查询面板
    openQuery: (data: IUseQuery) => {
      if (!open) {
        setOpen(true);
      }
      setPluginTab(TabTypeEnum.Query);
      setQuery(data);
    },

    // 打开个人资料库
    openLibrary: (data: IUseLibrary) => {
      if (!open) {
        setOpen(true);
      }
      setPluginTab(TabTypeEnum.Library);
      setQuery(data);
    },
  };
}

export default useCommunication;
