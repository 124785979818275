// extract
export const EXTRACT_ADD = "/api/plugin/extract/add";
export const EXTRACT_UPDATE = "/api/plugin/extract/update";
export const EXTRACT_REMOVE = "/api/plugin/extract/del";
export const EXTRACT_LIST = "/api/plugin/extract/detail";

// tag
export const TAG_ADD = "/api/plugin/tag/create";
export const TAG_LIST = "/api/plugin/tag/list";
export const TAG_QUERY = "/api/plugin/tag/query";
export const TAG_UPDATE = "/api/plugin/tag/update";
export const TAG_REMOVE = "/api/plugin/tag/delete";

// pdf
export const PDF_UPLOAD = "/api/plugin/file/add";
export const PDF_REMOVE = "/api/plugin/file/delete";
export const PDF_LIST = "/api/plugin/file/list";
export const PDF_UPDATE = "/api/plugin/file/update";
export const PDF_STATUS = "/api/plugin/file/status";
export const PDF_DOWNLOAD = "/api/plugin/file/download";

// url
export const URL_BATCH_UPLOAD = "/api/url/batch/upload";

// library & sug & sug history
export const HAS_READ_LIST = "/api/hasRead/list";
export const HAS_READ_HISTORY_LIST = "/api/hasRead/history/list";
export const HAS_READ_REPORT_OPEN = "/api/hasRead/report_open";
export const HAS_READ_HISTORY_DELETE = "/api/hasRead/history/delete";

export const HAS_READ_SEARCH = "/api/hasRead/search";
export const HAS_READ_STATISTICS = "/api/hasRead/statistics";
export const LIBRARY_EXTRACT_ITEMS = "/api/plugin/items";
export const LIBRARY_EXTRACT_ALL = "/api/plugin/others/all";
export const SUG_HISTORY_LIST = "/api/plugin/queryHistory/list";
export const ADD_HISTORY = "/api/plugin/queryHistory/add/";
export const DELETE_HISTORY = "/api/plugin/queryHistory/delete/";
export const PRESET_DATA = "/api/articles/list";
export const EXPERIENCE_DATA = "/api/plugin/experience/list";

// reader exclude web & pdf
export const READER_DETAIL = "/api/entry/detail";
// 多文档 - 文档详情
export const MULTI_DOCUMENT_DETAIL = "/api/multi/detail";

// reader web & pdf keywords
export const ENTRY_KEYWORD_LIST = "/api/focus/article/detail";
export const USER_KEYWORD_HISTORY = "/api/focus/query";
export const ENTRY_KEYWORD_APPLY = "/api/focus/article/use";

// sensitive word check
export const SENSITIVE_WORD_CHECK = "/api/plugin/safety/word_check";

// entry delete
export const DELETE_PDF_ENTRY = "/api/plugin/file/delete";
export const DELETE_URL_ENTRY = "/api/readers/url/delete";

// 截图搜索
export const SCREENSHOT_QUERY = "/api/desktop/screenshot/query";
// multi docs
export const MERGE_MULTI_DOCS = "/api/multi/merge";
// export const MERGE_MULTI_DOCS = "/api/multiple/add";
export const GET_MULTI_DOCS_STATUS = "/api/multiple/status";
export const GET_MULTI_DOCS_DETAIL = "/api/multiple/detail";
export const GENERATE_MULTI_DOCS_SUMMARY = "/api/multiple/outline";

// 前端用于错误埋点
export const GET_FRONT_END_SNAPSHOT = "/api/plugin/crashes";
