import { IDDrawerRef, ImageViewerRef } from "@deeplang/dui";
import {
  ChartElement,
  GetMaterialDetailResponse,
  MaterialDetail,
  RecommendElement,
} from "@deeplang/shared";
import { useImperativeHandle, useRef, useState } from "react";
import { IRelatedCharts } from "../types";
import { MOperationBarRef } from "../src/modules/image-viewer/MOperationBar";

export function useStore(props: IRelatedCharts) {
  const { relatedChartsRef, activeElementRef, defaultChartListCollapsedRef } =
    props;
  const drawerRef = useRef<IDDrawerRef>(null);
  const selectedRelateChartRef = useRef<
    (RecommendElement & ChartElement) | null
  >(null);
  const imageViewerRef = useRef<ImageViewerRef>(null);
  const imageViewerOperationBarRef = useRef<MOperationBarRef | null>(null);
  const chartListContainerRef = useRef<HTMLDivElement | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(
    defaultChartListCollapsedRef.current,
  );
  const [activeElement, setActiveElement] = useState<
    (RecommendElement & ChartElement) | null
  >(activeElementRef.current);

  const [externalMaterial, setExternalMaterial] = useState<
    (
      | GetMaterialDetailResponse["file_info"]
      | GetMaterialDetailResponse["url_info"]
    ) &
      MaterialDetail
  >();

  useImperativeHandle(relatedChartsRef, () => ({
    updateIsCollapsed: (value) => {
      setIsCollapsed(value);
    },

    updateActiveElement: (value) => {
      setActiveElement(value);
    },
  }));

  const updateExternalMaterial = (value: typeof externalMaterial) => {
    setExternalMaterial(value);
  };

  return {
    drawerRef,
    externalMaterial,
    updateExternalMaterial,
    imageViewerRef,
    isCollapsed,
    activeElement,
    setActiveElement,
    selectedRelateChartRef,
    imageViewerOperationBarRef,
    chartListContainerRef,
  };
}
