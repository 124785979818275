import dayjs from '@/third-party/dayjs';

// 格式化实体内容展示时间
export const formatTime = (time: string) => {
  const now = dayjs(dayjs.utc().toDate());
  const utcTime = dayjs(dayjs.utc(time).toDate());
  // const yearDiff = now.diff(utcTime, 'year', true);
  const dayDiff = now.diff(utcTime, 'day', true);
  let str = '';
  if (dayDiff > 1 || utcTime.isYesterday()) {
    str = utcTime.format('YYYY/MM/DD');
  } else {
    str = utcTime.format('HH:mm');
  }
  return str;
};

// 格式化上报时间(utc时间);
export const formatBatchTime = (time?: string) => {
  const utcTime = time ? dayjs(dayjs.utc(time).toDate()) : dayjs(dayjs.utc().toDate());
  return utcTime.format('YYYY-MM-DD HH:mm:ss.SSS');
};

export const getTimeRange = (params?: any) => {
  const { beforeCount = 0, hasEnd = true } = params || {};
  const day = dayjs().subtract(beforeCount, 'day').format('YYYY-MM-DD');
  const startUnix = dayjs(`${day} 00:00:00`).unix();
  const endUnix = dayjs(`${day} 23:59:59`).unix();

  if (!hasEnd) return [0, endUnix];

  return [startUnix, endUnix];
};
