import { Main } from "./main";
import styles from "./index.module.css";

export function RelatedCharts() {
  return (
    <div className={styles.container}>
      <Main />
    </div>
  );
}
