import type { EventMap } from './event.emitter';
import EventEmitter from './event.emitter';
import type HighlightSource from './source';
import { ERROR } from './types';

class Cache extends EventEmitter<EventMap<unknown | HighlightSource>> {
  private cache: Map<string, HighlightSource> = new Map();

  constructor() {
    super();
  }

  get data() {
    return this.getAll();
  }

  set data(map) {
    throw ERROR.CACHE_SET_ERROR;
  }

  save(sources: HighlightSource | HighlightSource[]): void {
    if (!Array.isArray(sources)) {
      sources = [sources];
    }
    sources.forEach((source) => {
      this.cache.set(source.id, source);
    });
  }

  get(id: string): HighlightSource | undefined {
    return this.cache.get(id);
  }

  remove(id: string): void {
    this.cache.delete(id);
  }

  getAll(): HighlightSource[] {
    const allHighlightSources: HighlightSource[] = [];
    for (const highlightId of this.cache.keys()) {
      allHighlightSources.push(this.cache.get(highlightId)!);
    }

    return allHighlightSources;
  }

  removeAll(): string[] {
    const removedHighlightIds: string[] = [];
    for (const highlightId of this.cache.keys()) {
      removedHighlightIds.push(highlightId);
    }
    this.cache = new Map();

    return removedHighlightIds;
  }
}

export default Cache;
