import { Component } from "react";
import { TextToolbarProps, ThirdParty, ToolBarInstance } from "./types";
import TextToolbar from "./TextToolbar";
import ReactDOM from "react-dom/client";
import { FloatToolbarProvider } from "./store";

export const toolbar: ToolBarInstance = {
  config: {
    mountNode: document.body || null,
    thirdParty: null,
    currentPageNumber: 1,
  },
  root: null,
  updateFileId: (fileId: string) => {
    toolbar.config.fileId = fileId;
  },
  updateCurrentPageNumber: (pageNumber: number) => {
    toolbar.config.currentPageNumber = pageNumber;
  },
  destroy() {
    const mountNode = toolbar.config.mountNode;
    if (!mountNode) {
      return;
    }

    // 使用同步的方法调用 unmount 会导致警告，网上说的是使用异步销毁，待分析其原理。
    setTimeout(() => {
      toolbar.root?.unmount();
      toolbar.root = null;
    }, 0);
    const container = mountNode.querySelector(
      "#wrapper-text-tool-bar-container",
    );
    if (!container) {
      return;
    }
    mountNode.removeChild(container);
  },
  renderText() {
    const {
      mountNode,
      fileId,
      thirdParty,
      options = {
        noCheckSafe: false,
        hideCloseButton: false,
        isDisableSearch: false,
      },
    } = toolbar.config;

    if (!mountNode) {
      throw new Error("No mount node provided!");
    }

    if (!thirdParty) {
      throw new Error("No third party provided");
    }

    const div = document.createElement("div");
    div.setAttribute("id", "wrapper-text-tool-bar-container");
    mountNode.appendChild(div);

    toolbar.root = ReactDOM.createRoot(div);
    toolbar.root.render(
      <FloatToolbarProvider
        defaultParams={{
          thirdParty,
        }}
      >
        <TextToolbar
          fileId={fileId}
          isToolbarVisible={true}
          setToolbarVisible={(visible: boolean) => {}}
          thirdParty={thirdParty}
          options={options}
          toolbar={toolbar}
        />
      </FloatToolbarProvider>,
    );
  },
};

type WrapperTextToolbarProps = Omit<TextToolbarProps, "thirdParty">;

export class WrapperTextToolbar extends Component<
  WrapperTextToolbarProps,
  WrapperTextToolbarProps
> {
  // 定义静态属性
  static thirdPartyLibrary: ThirdParty;

  static inject = (thirdPartyLibrary: ThirdParty) => {
    WrapperTextToolbar.thirdPartyLibrary = thirdPartyLibrary;
    const { request, PubSub } = thirdPartyLibrary;

    if (!request) {
      throw new Error("You must specify a request object");
    }

    if (!PubSub) {
      throw new Error("You must specify a pubsub object");
    }
  };

  constructor(props: WrapperTextToolbarProps) {
    super(props);
  }

  render() {
    return (
      <TextToolbar
        {...this.props}
        thirdParty={WrapperTextToolbar.thirdPartyLibrary}
      ></TextToolbar>
    );
  }
}
