import React from "react";
import Image from "next/image";

function EmptyContent() {
  return (
    <div className="flex flex-col items-center justify-center py-[100px]">
      <Image
        src="https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/lingoreader/pic/content_empty.png"
        className="empty-img"
        alt=""
        width={265}
        height={265}
      />
      <div className="text-base text-center text-secondary">
        <p>未找到相关内容</p>
        <p>试试搜索其他关键词吧</p>
      </div>
    </div>
  );
}

export default EmptyContent;
