import { ReadingMaterial, materialDetailAdapter } from "@deeplang/shared";
import { useRef, useState } from "react";
import { useApi } from "./useApi";
import { useRelatedChartsStore } from "../../store";

export function useMaterialById() {
  const isAutoGeneratedRef = useRef(false);
  const materialIdRef = useRef("");
  const readingMaterialRef = useRef(ReadingMaterial.PDF);
  const { updateExternalMaterial } = useRelatedChartsStore();

  const [materialIsNotExist, setMaterialIsNotExist] = useState(false);
  const { getMaterialDetail } = useApi();

  const runMaterialDetail = async () => {
    try {
      const res = await getMaterialDetail({
        entry_id: materialIdRef.current,
        entry_type: readingMaterialRef.current,
      });
      if (!res) {
        setMaterialIsNotExist(true);
        return;
      }

      const { file_info, url_info } = res;

      // 当 url 上的 rid 不存在后端库中时，后端会返回一个空对象，例如： { file_info : {}}
      if (
        typeof file_info === "object" &&
        Object.keys(file_info).length === 0
      ) {
        setMaterialIsNotExist(true);
        updateExternalMaterial(undefined);
        return;
      }

      // 同上
      if (typeof url_info === "object" && Object.keys(url_info).length === 0) {
        setMaterialIsNotExist(true);
        updateExternalMaterial(undefined);
        return;
      }

      if (file_info) {
        const result = materialDetailAdapter.from(file_info);
        updateExternalMaterial(result);
        return;
      }

      if (url_info) {
        updateExternalMaterial(materialDetailAdapter.from(url_info));
        return;
      }

      // 如果不在这两种之中，说明也是无法识别的
      setMaterialIsNotExist(true);
    } catch (e) {
      console.log(e);
    }
  };

  return {
    runMaterialDetail,
    isAutoGeneratedRef,
    materialIsNotExist,
    materialIdRef,
    readingMaterialRef,
  };
}
