type HookCallback<T = unknown> = (...args: T[]) => void;

class Hook<T = unknown> {
  name = '';
  private readonly callbacks: HookCallback<T>[] = [];

  constructor(name: string) {
    this.name = name;
  }

  tap(callback: HookCallback<T>) {
    if (!this.callbacks.includes(callback)) {
      this.callbacks.push(callback);
    }

    return () => {
      this.remove(callback);
    };
  }

  call(...args: T[]): T | undefined {
    let returnValue: T | undefined;

    this.callbacks.forEach((callback) => {
      const result = callback(...args);
      if (result !== undefined) {
        returnValue = result;
      }
    });

    return returnValue;
  }

  remove(callback: HookCallback<T>) {
    const index = this.callbacks.indexOf(callback);

    if (index < 0) {
      return;
    }

    this.callbacks.splice(index, 1);
  }

  isEmpty() {
    return this.callbacks.length === 0;
  }
}

export default Hook;
