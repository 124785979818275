import { OperationBar, BarItem } from "@deeplang/dui";
import { BackOriginal, IconX, MyIcon } from "@deeplang/icons";
import "./MOperationBar.css";

interface MOperationBarProps {
  handleClickClose: () => void;
  handleClickBackOriginal: () => void;
  handleClickSaveLibrary: () => void;
  canAdd: boolean;
}
export function MOperationBar(props: MOperationBarProps) {
  const {
    handleClickClose,
    handleClickBackOriginal,
    handleClickSaveLibrary,
    canAdd,
  } = props;
  const list = [
    <>
      {canAdd && (
        <BarItem
          key={"保存到我的库"}
          title="保存到我的库"
          content={<MyIcon width={22} height={22} fill="#7A7B7B" />}
          onClick={handleClickSaveLibrary}
        />
      )}
    </>,
    <BarItem
      key={"在新标签页中打开"}
      title="在新标签页中打开"
      content={
        <BackOriginal
          color="#7A7B7B"
          size={22}
          onClick={handleClickBackOriginal}
        />
      }
    />,
    <BarItem
      key={"关闭"}
      title="关闭"
      content={<IconX fill="#7A7B7B" size={22} />}
      onClick={handleClickClose}
    />,
  ];
  return (
    <div className="related-charts-half-screen-container">
      {" "}
      <OperationBar list={list} />
    </div>
  );
}
