import axios, {
  Axios,
  AxiosDefaults,
  AxiosHeaderValue,
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  InternalAxiosRequestConfig,
} from "axios";
import { getBaseURL } from "./helper";
import { getPrefixKeys, refreshToken, storage } from "@/packages/passport";
import { DPResponseType, Merge } from "@deeplang/shared";
import { downloadFile } from "./download";
import {
  GENERATE_MULTI_DOCS_SUMMARY,
  MULTI_DOCUMENT_DETAIL,
  PDF_UPLOAD,
  READER_DETAIL,
  URL_BATCH_UPLOAD,
} from "@/constant/api";
import { cloneDeep } from "lodash-es";
import { mockUrlSet } from "./mock-url";
import { nanoid } from "nanoid";

const baseURL = getBaseURL();
// 用于后端区分业务来源，方便后续问题定位、数据统计和分析
const WebSite = 3;

export type CustomAxiosInstance = Axios & {
  <T = any, D = any>(config: AxiosRequestConfig<D>): Promise<T>;
  <T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<T>;
  defaults: Omit<AxiosDefaults, "headers"> & {
    headers: HeadersDefaults & {
      [key: string]: AxiosHeaderValue;
    };
  };
};

// const instance = axios.create({ baseURL });

type CustomInterceptors<T = DPResponseType<any>> = Merge<
  typeof axios.interceptors,
  {
    response: AxiosInterceptorManager<AxiosResponse<T, any>>;
  }
>;

type DeepAxiosRequestConfig = InternalAxiosRequestConfig & {
  hasRefresh: boolean;
};

export const setHeadersAuthInfo = async (headers: any) => {
  if (!headers) headers = {};
  const keys = getPrefixKeys();
  const [uid, bid, authToken, accessToken] = await Promise.all([
    storage.get(keys.uid),
    storage.get(keys.bid),
    storage.get(keys.authToken),
    storage.get(keys.accessToken),
  ]);

  headers["B-Id"] = bid;
  headers["U-Id"] = uid;
  headers["Auth-Token"] = authToken;
  headers["Access-Token"] = accessToken;
};

type CustomResponse<T> = AxiosResponse<T>;
type CustomRequestConfig = AxiosRequestConfig;

// 下载文件
export const downloadService = axios.create({
  baseURL,
});

downloadService.interceptors.request.use(
  async (config) => {
    config.headers["Content-Type"] =
      "application/json; application/octet-stream";

    config.responseType = "blob";

    await setHeadersAuthInfo(config.headers);

    return config;
  },
  (error) => Promise.reject(error),
);

downloadService.interceptors.response.use(
  async (response) => {
    const { status, data } = response;
    if (axios.isCancel(data)) {
      return Promise.reject(data);
    }

    if (status !== 200) {
      return Promise.reject(response);
    }

    // 需要前置完成登录态的校验
    downloadFile(response);

    return response;
  },
  (error) => Promise.reject(error),
);

// const customInstance = instance as CustomAxiosInstance;

// export default customInstance;

const requestInterceptor = async (config: InternalAxiosRequestConfig) => {
  if (
    config.method === "post" &&
    typeof config.data === "object" &&
    config.headers["Content-Type"] === undefined
  ) {
    config.headers["Content-Type"] = "application/json";
  }

  config.headers["Web-Site"] = WebSite;
  config.headers["Trace-Id"] = nanoid();

  if (config.url === GENERATE_MULTI_DOCS_SUMMARY) {
    config.timeout = 60 * 60 * 1000;
  }

  if (
    mockUrlSet.has(config.url || "") &&
    process.env.NODE_ENV === "development"
  ) {
    config.url = "http://localhost:3008" + config.url;
  }

  await setHeadersAuthInfo(config.headers);

  return config;
};

export const requestErrorInterceptor = (error: any) => {
  // 处理请求错误
  console.error("Request Error:", error);
  return Promise.reject(error);
};

export const responseErrorInterceptor = (error: any) => {
  // 处理响应错误
  console.error("Response Error:", error);
  return Promise.reject(error);
};

const urlsForTraceId = new Set([
  READER_DETAIL,
  MULTI_DOCUMENT_DETAIL,
  PDF_UPLOAD,
  URL_BATCH_UPLOAD,
]);

const responseInterceptor = (client: AxiosInstance) => {
  return async (response: any) => {
    const { status, data } = response;
    if (axios.isCancel(data)) {
      console.log("Request canceled" + data);
      return Promise.reject(data);
    }
    if (status !== 200) {
      return Promise.reject(response);
    }

    if (
      data.code === 10010 &&
      !(response.config as DeepAxiosRequestConfig).hasRefresh
    ) {
      try {
        const result = await refreshToken(response.config, client);
        return Promise.resolve(result);
      } catch (error) {
        // window.location.href = "/";
        return Promise.reject(error);
      }
    }

    if (data.code !== 0) {
      return Promise.reject(data);
    }

    // 该接口需要获取响应头中的traceId，拼接到返回中，用来进行阅读时长的上报
    if (urlsForTraceId.has(response.config.url || "")) {
      const traceId = response.headers?.["trace-id"];
      const res = cloneDeep(data.data);
      if (traceId) {
        res.traceId = traceId;
      }
      return Promise.resolve(res);
    }

    return Promise.resolve(data.data);
  };
};
// client instance
function createApiClient(baseURL: string) {
  const apiClient = axios.create({
    baseURL,
    timeout: 3 * 60 * 1000,
  });

  apiClient.interceptors.request.use(
    requestInterceptor,
    requestErrorInterceptor,
  );

  (apiClient.interceptors as CustomInterceptors).response.use(
    responseInterceptor(apiClient),
    responseErrorInterceptor,
  );

  return apiClient as CustomAxiosInstance;
}

export const apiClient = createApiClient(process.env.NEXT_PUBLIC_API_URL || "");
export const chatClient = createApiClient(
  process.env.NEXT_PUBLIC_CHAT_API_URL || "",
);

export const get = <T>(url: string, config?: CustomRequestConfig) =>
  apiClient.get<CustomResponse<T>>(url, config);

export const post = <T>(
  url: string,
  data?: object,
  config?: CustomRequestConfig,
) => {
  return apiClient.post<CustomResponse<T>>(url, data, config);
};
export default apiClient;
