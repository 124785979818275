import { useState, useEffect, useRef, useLayoutEffect } from "react";

import Tag from "../tag";

import styles from "./TagInput.module.css";
import { ITag } from "@deeplang/shared";

export type Action = {
  action: "add" | "remove";
  value?: string;
};

type TagInputProps = {
  value: string;
  tags: ITag[];
  onChange: (value: string) => void;
  onAction: (action: Action) => void;
  onLock: (lock: boolean) => void;
  maxTagCount?: number;
};

function TagInput(props: TagInputProps) {
  const {
    value = "",
    tags,
    onChange,
    onAction,
    onLock,
    maxTagCount = 10,
  } = props;
  const [inputWidth, setInputWidth] = useState(0);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const measureRef = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    setInputWidth(measureRef.current?.scrollWidth || 0);
  }, [value]);

  useEffect(() => {
    inputRef.current?.focus({ preventScroll: true });
  }, []);

  const handleBoxClick = () => {
    inputRef.current?.focus();
  };

  const handleTagClose = (tag: string) => {
    onAction({ action: "remove", value: tag });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target?.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { value } = e.target as HTMLInputElement;
    if (e.code === "Backspace" && value === "") {
      onAction({ action: "remove" });
    } else if (e.code === "Enter" && !e.nativeEvent.isComposing && value) {
      e.preventDefault();
      onAction({ action: "add", value });
    }
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.box} onClick={handleBoxClick}>
        {tags.map((tag) => (
          <Tag
            className={styles.tag}
            key={tag.id}
            closable
            onClose={() => handleTagClose(tag.tag)}
          >
            {tag.tag}
          </Tag>
        ))}
        <div className={styles.inputbox}>
          {tags.length === 0 && !value && (
            <p className={styles.placeholder}>搜索或新增标签</p>
          )}
          <div className={styles.input} style={{ width: inputWidth }}>
            <span ref={measureRef} className={styles.mirror} aria-hidden>
              {value}&nbsp;
            </span>
            <input
              disabled={maxTagCount === tags.length}
              className={styles.input}
              ref={inputRef}
              value={value}
              onCompositionStart={() => onLock(true)}
              onCompositionEnd={() => onLock(false)}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TagInput;
