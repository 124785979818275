import {
  ChartElement,
  GetRelatedChartsRequest,
  GetRelatedChartsResponse,
  GetRelatedChartsResponseByAdapt,
  Merge,
  RecommendElement,
} from "@deeplang/shared";
import { useRef, useState } from "react";
import { useApi } from "./useApi";
import { chartsAdapter } from "../../sdk";

export function useRelatedCharts() {
  const [allRelatedCharts, setAllRelatedCharts] = useState<
    GetRelatedChartsResponseByAdapt[]
  >([]);

  const allRelatedChartsRef = useRef<GetRelatedChartsResponseByAdapt[]>([]);
  const { getRelatedCharts } = useApi();

  const requestRelatedCharts = async (props: GetRelatedChartsRequest) => {
    try {
      const res = await getRelatedCharts(props);
      if (!res) {
        setAllRelatedCharts([]);
        return;
      }

      const resByAdapt = res.map((item) => {
        const itemByAdapt: Merge<
          GetRelatedChartsResponse[0],
          {
            recommend_elements: (RecommendElement & ChartElement)[];
          }
        > = { element_id: "", recommend_elements: [] };
        for (let i = 0; i < item.recommend_elements.length; i++) {
          itemByAdapt.element_id = item.element_id;
          const element = item.recommend_elements[i];
          if (!element) {
            continue;
          }
          const elementByAdapt = chartsAdapter.from(element);
          itemByAdapt.recommend_elements.push(elementByAdapt);
        }
        return itemByAdapt;
      });

      allRelatedChartsRef.current = resByAdapt;
      setAllRelatedCharts(resByAdapt);
    } catch {
      setAllRelatedCharts([]);
    }
  };

  return {
    allRelatedCharts,
    requestRelatedCharts,
    allRelatedChartsRef,
  };
}
