import { useContext } from "react";
import { IPdfReader, ThirdParty } from "../type";
import { PdfReaderContext } from "./provider";
import { RemoveUndefined } from "@deeplang/shared";
import useStore from "./all-store";
import { ReaderLayoutStore } from "@/provider/reader-layout";
import { IPdfToolbarProps } from "@/modules/toolbar/types";

export * from "./provider";

type CommonType = ThirdParty & {
  options: RemoveUndefined<IPdfReader["options"]>;
  isAutoGeneratedRef: IPdfReader["isAutoGeneratedRef"];
  canRenderKeywords?: boolean;
  readerLayout?: ReaderLayoutStore;
  topBarOptions?: IPdfToolbarProps["options"];
} & ReturnType<typeof useStore>;
type State = {
  [P in keyof CommonType]: CommonType[P];
};
export const usePdfReaderStore = (): State => {
  const state = useContext(PdfReaderContext);

  if (state === null) {
    throw new Error(
      "usePdfReaderStore must be used within a PdfReaderProvider",
    );
  }

  const {
    thirdParty,
    options = { toolbar: true, disabledAutoGenerated: false },
    isAutoGeneratedRef,
    material,
    materialIsNotExist,
    setMaterialIsNotExist,
    originTextRef,
    canRenderKeywords,
    readerLayout,
    pdfReaderRef,
    updateOriginTextFromPdf,
    originTextFromPdf,
    ...rest
  } = state;

  return {
    ...thirdParty,
    material,
    materialIsNotExist,
    options,
    isAutoGeneratedRef,
    setMaterialIsNotExist,
    originTextRef,
    canRenderKeywords,
    readerLayout,
    pdfReaderRef,
    updateOriginTextFromPdf,
    originTextFromPdf,
    ...rest,
  };
};
