import { Close } from '@deeplang/icons';
import styles from './Tag.module.css';

type TagProps = {
  className?: string;
  children: React.ReactNode;
  closable: boolean;
  onClick?: () => void;
  onClose?: (tag: string) => void;
};

function Tag(props: TagProps) {
  const { className, children, onClick, onClose } = props;

  return (
    <div className={`${styles.tag} ${className}`} onClick={onClick}>
      <span className={styles.name}>{children}</span>
      {props.closable && (
        <span
          className={styles.close}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClose?.(children as string);
          }}
        >
          <Close />
        </span>
      )}
    </div>
  );
}

export default Tag;
