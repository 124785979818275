import { ConfigProvider, Tour, TourProps } from "@deeplang/dui";
import { useEffect, useState } from "react";
import ReactDOM, { Root } from "react-dom/client";
import styles from "./CTour.module.css";
import "./CTour.css";
import { Maybe } from "@deeplang/shared";

const TOUR_ROOT_CONTAINER = "wrapper-tour-related-charts-container";
interface RelatedChartsTourProps {
  isFinished: boolean;
  mountNode: HTMLElement;
  onTourClose?: () => void;
}
const RelatedChartsTour: React.FC<RelatedChartsTourProps> = (
  props: RelatedChartsTourProps,
) => {
  const { isFinished, onTourClose, mountNode } = props;
  const [open, setOpen] = useState(!isFinished);
  const handleClose = () => {
    setOpen(false);
    onTourClose && onTourClose();
  };

  const handleFinish = () => {
    onTourClose && onTourClose();
  };

  const steps: TourProps["steps"] = [
    {
      title: <span className="text-base text-white">图表推荐</span>,
      description: (
        <div className="flex flex-col gap-2 w-full">
          <video
            loop
            autoPlay
            className="w-full"
            style={{ objectFit: "contain" }}
            muted
          >
            <source
              src={
                "https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/lingoreader/videos/chart_guide.mp4"
              }
              type="video/mp4"
            ></source>
          </video>
          <span>📑 多多上传研报，相关图表纷至沓来，读一篇知全貌！</span>
          <span className="text-xs font-light opacity-80 m-1">
            *“新能源汽车”和“芯片”行业有惊喜
          </span>
        </div>
      ),
      placement: "top",
      target: () => mountNode,
      nextButtonProps: {
        children: "我知道了",
        style: {
          borderColor: "#d9d9d9",
          color: "#3667e1",
          fontSize: "14px",
        },
      },
    },
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3667E1",
        },
        components: {
          Tour: {
            primaryNextBtnHoverBg: "#fff",
          },
        },
      }}
    >
      <Tour
        type="primary"
        open={open}
        onFinish={handleFinish}
        onClose={handleClose}
        steps={steps}
        indicatorsRender={(current, total) => null}
      />
    </ConfigProvider>
  );
};

export type TTour = {
  config: {
    mountNode: HTMLElement | null;
    isFinished: boolean;
    hasCreated: boolean;
    onTourClose?: () => void;
  };
  root: Maybe<Root>;
  createTour: () => void;
  destroy: () => void;
};

export const tour: TTour = {
  config: {
    mountNode: null,
    isFinished: true,
    hasCreated: false,
  },
  root: null,
  destroy: () => {
    // const mountNode = tour.config.mountNode;
    // if (!mountNode) {
    //   return;
    // }
    // // 使用同步的方法调用 unmount 会导致警告，网上说的是使用异步销毁，待分析其原理。
    // setTimeout(() => {
    //   tour.root?.unmount();
    //   tour.root = null;
    // }, 0);
    // const container = mountNode.querySelector(`#${TOUR_ROOT_CONTAINER}`);
    // if (!container) {
    //   return;
    // }
    // mountNode.removeChild(container);
  },
  createTour: () => {
    if (tour.config.hasCreated || true) {
      return;
    }

    // try {
    //   const div = document.createElement("div");
    //   const body = document.querySelector("body");
    //   if (!body) {
    //     Promise.reject(new Error("rootNode is required"));
    //     return;
    //   }

    //   div.setAttribute("id", TOUR_ROOT_CONTAINER);
    //   body.appendChild(div);
    //   tour.root = ReactDOM.createRoot(div);
    //   tour.root.render(
    //     <RelatedChartsTour
    //       isFinished={tour.config.isFinished}
    //       onTourClose={tour.config.onTourClose}
    //       mountNode={tour.config.mountNode || document.body}
    //     />,
    //   );
    // } catch (e) {
    //   console.log(e);
    // }
  },
};
