import Image from "next/image";

export default function SkeletonScreen() {
  return (
    <div className="h-screen mx-auto justify-center overflow-hidden animate-pulse">
      <div className="bg-neutral-3 h-4 w-3/5 mt-10"></div>
      <div className="py-4">
        <div className="bg-neutral-3 h-4 w-3/4 mb-4"></div>
        <div className="bg-neutral-3 h-4 w-1/2"></div>
        <div className="bg-neutral-3 h-4 w-1/2 mt-4"></div>
      </div>
      <div className="mt-4">
        <Image
          src="/lingo-image-placeholder.png"
          width={192}
          height={192}
          alt=""
        />
      </div>
    </div>
  );
}
