export {
  initHighlightBBox,
  clearCanvasHighlights,
  isPageRandered,
  checkHighlightCanvas,
};

let _textHighlightColor = "rgba(251,255,41,0.14)";
let _highlightTextJson = [];
let currentPage = 1;
let _scrollType = 2; // _scrollType（可选） - 1:smooth滑动版(默认) 2:直接跳转版
let startRenderHighlight = false;
let _PDFViewerApplication = {
  pdfViewer: null,
};

/**
 * 根据提供的 JSON 数据初始化高亮。
 * @param {Array} json - 包含高亮信息的 JSON 数组。
 *   示例 JSON: [{"idx":0,"captions":[],"type":"title","bbox":[0.069,0.059,0.157,0.144],"ocr_res":"每日","table":{},"img_url":"","page_num":1}]
 *   - idx
 *   - captions
 *   - type
 *   - bbox: 定义高亮区域的边界框坐标 [x1,y1,x2,y2]。
 *   - ocr_res
 *   - table
 *   - img_url
 *   - page_num: 高亮所在的页码。
 *
 * @param {string} backgroundColor 传入rgba形式的背景颜色
 * @throws {Error} 如果参数不是对象数组，则抛出错误。
 */
function initHighlightBBox(json, PDFViewerApplication, backgroundColor) {
  _PDFViewerApplication = PDFViewerApplication;
  if (!!json && Array.isArray(json)) {
    if (backgroundColor) _textHighlightColor = backgroundColor;
    _highlightTextJson = json;
    clearCanvasHighlights();
    startRenderHighlight = true;
    scrollTo(json[0].page_num + 1, json[0].bbox, _PDFViewerApplication);
    renderHighlight();
  } else {
    startRenderHighlight = false;
    throw new Error("参数错误。参数必须为对象数组类型。");
  }
}

/**
 * 覆盖原版样式，优化页面样式
 */
function searchStyleRefine() {
  // 创建一个新的 <style> 元素
  var styleElement = document.createElement("style");
  document.head.appendChild(styleElement);

  // 获取样式表对象
  var styleSheet = styleElement.sheet;
  // 添加新的 CSS 规则2
  styleSheet.insertRule(".textLayer { z-index:100;}", 0);
}

/**
 * 在画布上绘制文本高亮。
 * @param {number[]} bbox - 边界框坐标的数组，格式为 [x1, y1, x2, y2]，
 *                          其中 (x1, y1) 表示左上角，(x2, y2) 表示右下角。
 * @param {number} pageNum - 高亮应该绘制在哪一页。
 */
function highlightText(bbox, pageNum) {
  const canvasId = generateCanvasId(pageNum);
  const canvasElement = document.querySelector("#" + canvasId);

  if (!canvasElement) {
    console.error("找不到画布元素: " + canvasId);
    return; // 如果找不到画布元素，则退出函数
  }

  const ctx = canvasElement.getContext("2d");
  ctx.fillStyle = _textHighlightColor;
  const x = bbox[0] * canvasElement.width;
  const y = bbox[1] * canvasElement.height;
  const w = bbox[2] * canvasElement.width - x;
  const h = bbox[3] * canvasElement.height - y;

  ctx.beginPath();
  ctx.fillRect(x, y, w, h);
}

/**
 * 从数组中提取包含指定 page_num 的集合。
 * @param {Array} dataArray - 包含对象的数组。
 * @returns {Array} 包含唯一 page_num 的数组。
 */
function getPageNumArray(dataArray) {
  let pageNumSet = new Set();
  for (let item of dataArray) {
    pageNumSet.add(item.page_num);
  }
  return [...pageNumSet];
}

/**
 * 渲染所有高亮。
 */
async function renderHighlight() {
  let pages = getPageNumArray(_highlightTextJson);
  if (startRenderHighlight) {
    // console.log('renderHighlight', pages);
    for (var i = 0; i < pages.length; i++) {
      await renderHighlightPage(pages[i] + 1);
    }
  }
}

/**
 * 渲染特定页码的高亮。
 * @param {number} pageNum - 要渲染高亮的页码。
 */
async function renderHighlightPage(pageNum) {
  try {
    let res = await isPageRandered(pageNum);
    if (res) {
      // console.log(pageNum, res);
      let canvasElement = await checkHighlightCanvas(
        res.pageDiv,
        res.canvasWrapper,
        pageNum,
      );
      const isCanvasRendered = canvasElement.getAttribute("is-rendered");
      // console.log('isCanvasRendered ', pageNum, isCanvasRendered)
      if (!isCanvasRendered || isCanvasRendered == "false") {
        let bboxs = _highlightTextJson.filter((e) => {
          return e.page_num === pageNum - 1;
        });
        if (!canvasElement) {
          console.log("canvasElement不存在");
        } else {
          // 绘制 bbox
          for (var i = 0; i < bboxs.length; i++) {
            // 绘制某页的bbox
            highlightText(bboxs[i].bbox, pageNum);
          }
          canvasElement.setAttribute("is-rendered", true);

          let parentNode = canvasElement.parentNode;
          console.log(canvasElement.parentNode);
          if (parentNode) {
            parentNode.addEventListener("click", handleClearHighlight);
          }
        }
      }
    }
  } catch (error) {
    console.error("页面渲染超时:", error);
  }
}

/**
 * 检查给定页码的页面是否已经渲染。
 * @param {number} pageNum - 要检查的页码。
 * @returns {Promise} - 返回一个 Promise 对象，表示页面渲染的状态。
 */
function isPageRandered(pageNum) {
  console.log("isPageRandered start====", pageNum);
  return new Promise((resolve, reject) => {
    let canvasId = "page-highlight-" + pageNum;
    let counter = 1;
    // 等待 PDF 页面渲染完成后再绘制 canvas
    let renderPageInterval = setInterval(() => {
      let pageDiv = document.querySelector(
        '.page[data-page-number="' + pageNum + '"]',
      );
      if (!pageDiv) {
        return;
      }

      let canvasWrapperDiv = pageDiv.querySelector(".canvasWrapper");
      if (!canvasWrapperDiv) {
        return;
      }

      let canvasWrapper = canvasWrapperDiv.querySelector("canvas");
      if (!canvasWrapper) {
        return;
      }

      // 检查 PDF 页面是否已渲染完成
      if (canvasWrapper.clientWidth > 0 && canvasWrapper.clientHeight > 0) {
        clearInterval(renderPageInterval); // 停止等待页面渲染的间隔
        resolve({
          pageDiv,
          canvasWrapper,
        });
      } else {
        counter++;
      }
      if (counter > 20) {
        reject("页面渲染超时");
      }
    }, 50);
  });
}

/**
 * 检查给定页码的 canvas 是否存在，不存在则创建一个新的。
 * @param {object} pageDiv - 包含页码的 div 元素。
 * @param {object} canvasWrapper - 页面 canvas 的包装器元素。
 * @param {number} pageNum - 要检查的页码。
 * @returns {Promise} - 返回一个 Promise 对象，表示 canvas 的状态。
 */
async function checkHighlightCanvas(pageDiv, canvasWrapper, pageNum) {
  return new Promise((resolve, reject) => {
    let canvasId = generateCanvasId(pageNum);
    let canvasElement = document.querySelector("#" + canvasId);
    try {
      if (canvasElement) {
        canvasElement.style.zIndex = "3";
        resolve(canvasElement);
      } else {
        canvasElement = document.createElement("canvas");
        canvasElement.setAttribute("class", "canvas-highlight");
        canvasElement.setAttribute("id", canvasId);
        canvasElement.width = canvasWrapper.clientWidth;
        canvasElement.height = canvasWrapper.clientHeight;
        canvasElement.style.position = "absolute";
        canvasElement.style.top = "0";
        canvasElement.style.left = "0";
        canvasElement.style.zIndex = "3";
        pageDiv.appendChild(canvasElement);
        resolve(document.querySelector("#" + canvasId));
      }
    } catch (error) {
      reject();
    }
  });
}
// 定义函数来向上查询父节点，直到找到具有指定类名的节点
function findParentWithClass(element, className) {
  var parent = element.parentNode;
  while (parent) {
    if (parent.classList.contains(className)) {
      return parent;
    }
    parent = parent.parentNode;
  }
  return null; // 如果没有找到具有指定类名的父节点，则返回 null
}

// 检查是否点击事件发生在高亮区域
function handleClearHighlight(e) {
  let pageDiv = findParentWithClass(e.target, "page");
  if (pageDiv) {
    let pageNum = parseInt(pageDiv.getAttribute("data-page-number"));
    let rect = pageDiv.getBoundingClientRect();
    let mouseX = event.clientX - rect.left;
    let mouseY = event.clientY - rect.top;
    let bBoxMouse = getBbox(
      mouseX,
      mouseY,
      parseInt(pageDiv.style.width),
      parseInt(pageDiv.style.height),
    );
    let canvasElement = pageDiv.querySelector(".canvas-highlight");
    let figureBBox = isInFigureBBox(
      bBoxMouse[0],
      bBoxMouse[1],
      pageNum,
      canvasElement,
    );
    if (!figureBBox) {
      clearCanvasHighlights();
    }
  }
}

/**
 * 判断点击位置是否在图片 bbox 内
 * @param {number} x - 点击 x 坐标
 * @param {number} y - 点击 y 坐标
 * @param {number} pageNum - 页码
 * @param {HTMLCanvasElement} canvas - Canvas 元素
 * @returns {Object|null} - 图片 bbox 信息或 null
 */
function isInFigureBBox(x, y, pageNum, pageDiv) {
  let info = _highlightTextJson.filter((e) => {
    return e.page_num == pageNum - 1;
  });
  for (var i = 0; i < info.length; i++) {
    if (
      isPointBetweenTwoPoints(
        x,
        y,
        info[i].bbox[0],
        info[i].bbox[1],
        info[i].bbox[2],
        info[i].bbox[3],
      )
    ) {
      return info[i];
    }
  }
}

/**
 * 根据 x, y, 宽度和高度计算 bbox 的 x 和 y 值
 * @param {number} x - x 坐标
 * @param {number} y - y 坐标
 * @param {number} width - 宽度
 * @param {number} height - 高度
 * @returns {number[]} - bbox 的 x 和 y 值
 */
function getBbox(x, y, width, height) {
  return [x / width, y / height];
}

/**
 * 检查点是否在两点之间
 * @param {number} xC - 待检查点 x 坐标
 * @param {number} yC - 待检查点 y 坐标
 * @param {number} xA - 第一个点 x 坐标
 * @param {number} yA - 第一个点 y 坐标
 * @param {number} xB - 第二个点 x 坐标
 * @param {number} yB - 第二个点 y 坐标
 * @returns {boolean} - 点是否在两点之间
 */
function isPointBetweenTwoPoints(xC, yC, xA, yA, xB, yB) {
  const isXBetween = xC >= Math.min(xA, xB) && xC <= Math.max(xA, xB);
  const isYBetween = yC >= Math.min(yA, yB) && yC <= Math.max(yA, yB);

  return isXBetween && isYBetween;
}

/**
 * 生成用于 canvas 元素的唯一 ID。
 * @param {number} pageNum - 页码。
 * @returns {string} - 生成的 ID。
 */
function generateCanvasId(pageNum) {
  return "page-highlight-" + pageNum;
}

/**
 * 清除高亮 canvas 内容
 */
function clearCanvasHighlights() {
  const canvasHighlights = document.querySelectorAll(".canvas-highlight"); // 获取所有带有类名为 "canvas-highlight" 的 canvas 元素

  canvasHighlights.forEach((canvas) => {
    canvas.setAttribute("is-rendered", false);
    const context = canvas.getContext("2d"); // 获取 canvas 2D 上下文
    context.clearRect(0, 0, canvas.width, canvas.height); // 清空 canvas 内容
    canvas.style.zIndex = "-1";
  });
  startRenderHighlight = false;
}

/**
 * 获取当前页码
 * @returns {number} 当前页码
 */
function getCurrentPageNumber() {
  if (window.PDFViewerApplication) {
    return window.PDFViewerApplication.pdfViewer.currentPageNumber; // 使用 PDF.js 的 API 获取当前页码
  } else {
    return currentPage;
  }
}

/**
 * 当 DOMContentLoaded 事件被触发时调用。
 */
document.addEventListener("DOMContentLoaded", function () {
  console.log("DOMContentLoaded");
});

//已获取装载pdfjs viewer的iframe后，再获取iframe window里的pdfViewer这个全局变量

// 处理pdf缩放问题重新渲染问题
const t = window.setInterval(() => {
  const pdfViewerApplication = _PDFViewerApplication;
  const pdfViewer = _PDFViewerApplication?.pdfViewer;
  if (pdfViewer) {
    window.clearInterval(t);
    //通过pdfViewer.eventBus来监听各种事件;
    pdfViewer.eventBus.on("pagechanging", (e) => {
      // console.log('pagechanging', e);
      currentPage = e.pageNumber;
      renderHighlight(_highlightTextJson);
    });
    pdfViewer.eventBus.on("scalechanging", (e) => {
      renderHighlight(_highlightTextJson);
      // console.log('scalechanging', e);
    });

    //直接获取总页数
    console.log(pdfViewerApplication.pagesCount);
    //直接获取当前页数
    console.log(pdfViewerApplication.page);
  }
}, 200);

/**
 * 跳转到高亮位置。
 * @param {number} pageNum - 高亮应该绘制在哪一页。
 * @param {number[]} bbox - 边界框坐标的数组，格式为 [x1, y1, x2, y2]，
 *                          其中 (x1, y1) 表示左上角，(x2, y2) 表示右下角。
 */
function scrollTo(pageNum, bbox, _PDFViewerApplication) {
  // PDFViewerApplication.pdfViewer.currentPageNumber = pageNum;
  const viewerContainer = document.getElementById("pdf-view-container");
  if (viewerContainer) {
    let pageHeights = _PDFViewerApplication?.pdfViewer._pages.map(
      (e) => e.viewport.height,
    );
    let scroll = 0;
    for (let i = 0; i < pageNum - 1; i++) {
      scroll += pageHeights[i];
    }
    let y = bbox[1];
    setTimeout(() => {
      let pageDiv = viewerContainer.querySelector(
        '.page[data-page-number="' + pageNum + '"]',
      );
      if (_scrollType === 1) {
        viewerContainer.scrollTo({
          top:
            scroll +
            parseInt(pageDiv.getBoundingClientRect().height) * y -
            100 +
            pageNum * 10,
          behavior: "smooth",
        });
      } else {
        viewerContainer.scrollTop =
          scroll +
          parseInt(pageDiv.getBoundingClientRect().height) * y -
          100 +
          pageNum * 10;
      }
    }, 100);
  }
}
