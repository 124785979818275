"use client";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import {
  DownloadBottomLined,
  CopyLined,
  CrossLined,
  SpinnerGapLined,
} from "@deeplang/icons";
import { browser } from "@deeplang/shared";
import { message } from "@deeplang/dui";
import useExtract from "@/client/hooks/useExtract";

import "./index.css";

interface ShareImageViewerProps {
  footer?: React.ReactNode;
}
interface IOptions {
  extractId: string;
}

export interface ShareImageViewerRef {
  onOpen: (options: IOptions) => void;
  onClose: () => void;
}
const ShareImageViewer = forwardRef<ShareImageViewerRef, ShareImageViewerProps>(
  (props, ref) => {
    const [visible, setVisible] = useState(false);
    const shareMaskRef = useRef<HTMLDivElement>(null);
    const { loading, shareImgUrl, shareExtract } = useExtract();

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.code === "Escape") {
          // 执行你想要的操作
          setVisible(false);
        }
      };

      shareMaskRef.current?.addEventListener("click", onClose);
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        shareMaskRef.current?.removeEventListener("click", onClose);
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [visible]);

    const onOpen = (options: IOptions) => {
      const { extractId } = options;
      shareExtract(extractId);
      setVisible(true);
    };
    const onClose = () => {
      setVisible(false);
    };

    useImperativeHandle(ref, () => ({
      onOpen,
      onClose,
    }));

    const onCopyImage = () => {
      if (!shareImgUrl) {
        message.error("图片复制失败");
        return;
      }
      browser.copyImage(shareImgUrl);
      message.success("图片复制成功");
    };

    const onDownloadImage = () => {
      if (!shareImgUrl) {
        message.error("图片下载失败");
        return;
      }
      let link: HTMLAnchorElement | null = document.createElement("a");
      link.style.display = "none";
      document.body.appendChild(link);
      link.href = shareImgUrl || "";
      link.click();
      document.body.removeChild(link);
      link = null;
      // message.success("图片下载成功");
    };

    if (!visible) {
      return null;
    }

    return createPortal(
      <div>
        <div
          ref={shareMaskRef}
          className="share-image-mask"
          style={{ zIndex: Number.MAX_SAFE_INTEGER - 100 }}
        ></div>
        <div
          className="share-image-viewer"
          style={{ zIndex: Number.MAX_SAFE_INTEGER - 10 }}
        >
          <div
            className="text-[#FFFFFF] fixed text-[42px] top-[48px] right-[48px]"
            onClick={onClose}
          >
            <CrossLined className="cursor-pointer" />
          </div>
          <div className="share-image-viewer__content">
            {loading ? (
              <div className="text-[#FFFFFF] text-[48px] text-center">
                <SpinnerGapLined spin />
              </div>
            ) : (
              <img
                className="w-[380px] m-auto"
                src={shareImgUrl}
                alt="share-image"
              />
            )}
          </div>
          <div className="share-image-viewer__footer">
            <CopyLined onClick={onCopyImage} />
            <DownloadBottomLined onClick={onDownloadImage} />
          </div>
        </div>
      </div>,
      document.body,
    );
  },
);

ShareImageViewer.displayName = "ShareImageViewer";

export default ShareImageViewer;
