import { MultiDocSource, PdfSource, WebSource } from "../constants/common";
import { singleton } from ".";
class HistoryMaterialAdapter {
    _downloadFileUrl = "";
    initial(downloadFileUrl) {
        this._downloadFileUrl = downloadFileUrl;
    }
    encodeLink(HistoryMaterial) {
        if (PdfSource.has(HistoryMaterial.channel_type)) {
            return HistoryMaterial.entry_id;
        }
        if (WebSource.has(HistoryMaterial.channel_type)) {
            return encodeURIComponent(HistoryMaterial.url);
        }
        return "";
    }
    fromWebPage(webPage) {
        return {
            fullName: webPage.title === "" ? "无标题" : webPage.title,
            name: this.dropSuffixForFullName(webPage.title),
            resourceType: webPage.channel_type,
            link: webPage.url,
            ...webPage,
        };
    }
    fromPdf(pdfFile) {
        return {
            name: this.dropSuffixForFullName(pdfFile.title === "" ? "无标题.pdf" : pdfFile.title),
            fullName: pdfFile.title === "" ? "无标题.pdf" : pdfFile.title,
            resourceType: pdfFile.channel_type,
            link: this._downloadFileUrl + "?file_id=" + pdfFile.entry_id,
            ...pdfFile,
        };
    }
    from(HistoryMaterial) {
        if (MultiDocSource.has(HistoryMaterial.channel_type)) {
            return {
                name: this.dropSuffixForFullName(HistoryMaterial.title === "" ? "无标题" : HistoryMaterial.title),
                resourceType: HistoryMaterial.channel_type,
                link: HistoryMaterial.url,
                fullName: HistoryMaterial.title === "" ? "无标题" : HistoryMaterial.title,
                ...HistoryMaterial,
            };
        }
        if (PdfSource.has(HistoryMaterial.channel_type)) {
            return {
                name: this.dropSuffixForFullName(HistoryMaterial.title === "" ? "无标题.pdf" : HistoryMaterial.title),
                resourceType: HistoryMaterial.channel_type,
                link: this._downloadFileUrl + "?file_id=" + HistoryMaterial.entry_id,
                fullName: HistoryMaterial.title === "" ? "无标题.pdf" : HistoryMaterial.title,
                ...HistoryMaterial,
            };
        }
        if (WebSource.has(HistoryMaterial.channel_type)) {
            return {
                fullName: HistoryMaterial.title === "" ? "无标题" : HistoryMaterial.title,
                name: this.dropSuffixForFullName(HistoryMaterial.title === "" ? "无标题" : HistoryMaterial.title),
                resourceType: HistoryMaterial.channel_type,
                link: HistoryMaterial.url,
                ...HistoryMaterial,
            };
        }
    }
    /**
     *
     * @param fullName 去除文件名的后缀
     * @returns
     */
    dropSuffixForFullName(fullName) {
        const [name] = fullName.split(".");
        // 去掉扩展名后返回文件名
        return name || "";
    }
}
const SingleAdapter = singleton(HistoryMaterialAdapter);
export default new SingleAdapter();
