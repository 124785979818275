import { useContext } from "react";
import { ThirdParty } from "./types";
import { AnyHooksContext } from "./provider";
import { useStore } from "./store/useStore";

export * from "./provider";

type CommonType = ThirdParty & ReturnType<typeof useStore>;
type State = {
  [P in keyof CommonType]: CommonType[P];
};
export const useAnyHooksStore = (): State => {
  const state = useContext(AnyHooksContext);

  if (state === null) {
    throw new Error("useAnyHooksStore must be used within a SideBarProvider");
  }

  const { thirdParty, ...rest } = state;

  return {
    ...thirdParty,
    ...rest,
  };
};
