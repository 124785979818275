import { DOMMeta, DOMNode, RootElement } from './types';

import HighlightRange from './range';
import { ROOT_IDX } from './const';


const queryElementByIndex = (source: HighlightSource, root: Element) => {

  const startTags = root.getElementsByTagName(source.start.parentTagName);
  const endTags = root.getElementsByTagName(source.end.parentTagName);
  const start =
    source.start.parentIndex === ROOT_IDX
      ? root
      : startTags[source.start.parentIndex as number];
  const end =
    source.end.parentIndex === ROOT_IDX
      ? root
      : endTags[source.end.parentIndex as number];


  return {
    start,
    end,
  }
};

const queryElementById = (source: HighlightSource, root: Element) => {
  const startTags = root.getElementsByTagName(source.start.parentTagName);
  const startTagsLength = startTags.length;

  let start = root;
  for (let i = 0; i < startTagsLength; i++) {
    const tag = startTags[i];
    if (tag?.getAttribute('id') === source.start.parentIndex) {
      start = tag;
      break;
    }
  }

  const endTags = root.getElementsByTagName(source.end.parentTagName);
  const endTagsLength = endTags.length;

  let end = root;
  for (let i = 0; i < endTagsLength; i++) {
    const tag = endTags[i];
    if (tag?.getAttribute('id') === source.end.parentIndex) {
      end = tag;
      break;
    }
  }

  return {
    start,
    end,
  }
};
/**
 * get start and end parent element from meta info
 *
 * @param source
 * @param root
 * @returns
 */
export const queryElementNode = (source: HighlightSource, root: Element) => {


  let result = queryElementById(source, root);
  if (result.start === root || result.end === root) {
    // @ts-expect-error
    result = queryElementByIndex(source, root);
  }

  return {
    start: result.start,
    end: result.end,
  };
};

export const getTextChildByOffset = (root: Node, offset: number): DOMNode => {
  const nodeStack: Node[] = [root];

  let curNode: Node | null = null;
  let curOffset = 0;
  let startOffset = 0;

  while ((curNode = nodeStack.pop() || null)) {
    const children = curNode.childNodes;
    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i];
      if(!child){
        continue;
      }
      nodeStack.push(child);
    }

    if (curNode.nodeType === Node.TEXT_NODE) {
      startOffset = offset - curOffset;
      curOffset += curNode.textContent?.length ?? 0;
      if (curOffset >= offset) {
        break;
      }
    }
  }

  if (!curNode) {
    curNode = root;
  }

  return {
    node: curNode,
    offset: startOffset,
  };
};

class HighlightSource {
  start: DOMMeta;
  end: DOMMeta;
  text: string;
  id: string;
  extra?: unknown;

  constructor(start: DOMMeta, end: DOMMeta, text: string, id: string, extra?: unknown) {
    this.start = start;
    this.end = end;
    this.text = text;
    this.id = id;

    if (extra) {
      this.extra = extra;
    }
  }

  deserialize(root: Element) {
    const { start, end } = queryElementNode(this, root);
    const startNode = getTextChildByOffset(start, this.start.textOffset);
    const endNode = getTextChildByOffset(end, this.end.textOffset);
    const range = new HighlightRange(startNode, endNode, this.text, this.id, true);
    return range;
  }
}

export default HighlightSource;
