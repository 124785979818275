import { IFigure } from "@deeplang/shared";
import { ChartItemProps, ChartItem } from "./ChartItem";

type FigureProps = Omit<ChartItemProps, "children" | "chartTitle"> & {
  figure: IFigure;
};

export function Figure(props: FigureProps) {
  const { figure } = props;
  const Main = (
    <img
      src={figure.figure_url}
      style={{ width: "100%" }}
      alt="figure picture"
    />
  );
  return (
    <ChartItem {...props} chartTitle={figure.figure_caption}>
      {Main}
    </ChartItem>
  );
}
