import {
  ChartElement,
  ComplexMaterialDetail,
  GetElementListRequest,
  Merge,
  ReadingMaterial,
  RecommendElement,
} from "@deeplang/shared";
import { useEffect, useRef, useState } from "react";
import { useApi } from "./useApi";
import { chartsAdapter } from "../../sdk";

export function useElementList(
  props: Merge<
    Omit<GetElementListRequest, "channel_type">,
    {
      materialDetail: ComplexMaterialDetail | undefined;
    }
  >,
) {
  const { materialDetail, resource_id, element_type_list } = props;
  const [elementList, setElementList] = useState<
    (RecommendElement & ChartElement)[]
  >([]);
  const elementListRef = useRef<(RecommendElement & ChartElement)[]>([]);

  const { getElementList } = useApi();
  const requestElementList = async () => {
    try {
      const res = await getElementList({
        resource_id,
        // @ts-ignore
        channel_type: materialDetail?.channel_type || ReadingMaterial.PDF,
        element_type_list,
      });
      if (!res) {
        return;
      }

      const resByAdapt = res.map((item) => {
        const itemByAdapt = chartsAdapter.from(item);

        return itemByAdapt;
      });

      elementListRef.current = resByAdapt;
      setElementList(resByAdapt);
    } catch {
      elementListRef.current = [];
      setElementList([]);
    }
  };
  useEffect(() => {
    if (!materialDetail) {
      return;
    }
    requestElementList();
  }, [materialDetail]);

  return {
    elementList,
    elementListRef,
  };
}
