import {
  ChartElement,
  ElementPos,
  ElementType,
  PdfSource,
  ReadingMaterial,
  ReadingSource,
  RecommendElement,
} from "@deeplang/shared";
import cx from "classnames";
import styles from "./ChartList.module.css";
import { Figure } from "./Figure";
import { Table } from "./Table";
import { useRelatedChartsStore } from "../../store";
import { useMaterialById } from "../hooks/useMaterialById";
import { ImageViewListItem } from "../../types";
import { textToolbar } from "@/modules/float-toolbar";

interface ChartListProps {
  chartList: (RecommendElement & ChartElement)[];
  options?: {
    isShowTitle: boolean;
    showChartItemColCount: number;
    isShowBorder: boolean;
    isShowTitleUnderline: boolean;
    isGoBackOrigin: boolean;
    openFixedHeightContainer: boolean;
  };
}
export function ChartList(props: ChartListProps) {
  const {
    chartList,
    options = {
      isShowTitle: true,
      showChartItemColCount: 2,
      isShowBorder: false,
      isShowTitleUnderline: true,
      isGoBackOrigin: false,
      openFixedHeightContainer: true,
    },
  } = props;
  const {
    isShowTitle,
    showChartItemColCount,
    isShowBorder,
    isShowTitleUnderline,
    isGoBackOrigin,
    openFixedHeightContainer,
  } = options;
  const {
    drawerRef,
    imageViewerRef,
    isCollapsed,
    selectedRelateChartRef,
    chartListContainerRef,
  } = useRelatedChartsStore();
  const { materialIdRef, readingMaterialRef, runMaterialDetail } =
    useMaterialById();
  const configDrawer = async (
    resource_title: string,
    resource_id: string,
    channel_type: ReadingSource,
  ) => {
    if (isGoBackOrigin) {
      window.open(
        `${window.location.origin}/reader/pdf/${selectedRelateChartRef.current?.resource.resource_id || ""}?elementId=${selectedRelateChartRef.current?.element_id || ""}`,
      );
      return;
    }
    materialIdRef.current = resource_id;
    readingMaterialRef.current = PdfSource.has(channel_type)
      ? ReadingMaterial.PDF
      : ReadingMaterial.WebSite;
    drawerRef.current?.updateTitle(resource_title);
    // 开启半屏页之后，需要关闭浮动工具栏
    textToolbar.destroy();
    await runMaterialDetail();
    drawerRef.current?.show();
  };

  return (
    <div
      ref={chartListContainerRef}
      style={{
        height: openFixedHeightContainer ? "calc(100vh - 300px)" : "auto",
      }}
      className={cx({
        [`${styles.containerGrid}`]: isCollapsed && showChartItemColCount !== 3,
        [`${styles.container1Col}`]:
          !isCollapsed && showChartItemColCount !== 3,
        [`${styles.container3Col}`]: showChartItemColCount === 3,
      })}
    >
      {chartList.map((chart, index) => {
        const { source, resource, element_type, element_id } = chart;
        const {
          resource_title,
          channel_type,
          resource_id,
          resource_org = "",
        } = resource;
        const handleClickMaterialName = () => {
          selectedRelateChartRef.current = chart;
          configDrawer(resource_title, resource_id, channel_type);
        };

        const handleClickChart = (index: number) => {
          imageViewerRef.current?.show();
          imageViewerRef.current?.updateList(
            chartList.map((chart) => {
              return {
                title: chart.name,
                imageSrc: chart.content || "",
                resourceId: chart.resource.resource_id,
                resourceTitle: chart.resource.resource_title,
                elementId: chart.element_id,
              } as ImageViewListItem;
            }),
          );

          imageViewerRef.current?.changeCurrentIndex(index);
        };

        if (element_type === ElementType.Table) {
          const { table } = chart;

          if (!table) {
            return null;
          }

          return (
            <Table
              key={element_id}
              materialName={resource_title}
              organization={resource_org}
              chartSource={source}
              table={table}
              isShowTitle={isShowTitle}
              handleClickMaterialName={handleClickMaterialName}
              handleClickChart={handleClickChart}
              index={index}
              isShowBorder={isShowBorder}
              isShowTitleUnderline={isShowTitleUnderline}
            />
          );
        }
        if (element_type === ElementType.Image) {
          const { figure } = chart;
          if (!figure) {
            return null;
          }
          return (
            <Figure
              key={element_id}
              materialName={resource_title}
              organization={resource_org}
              chartSource={source}
              figure={figure}
              isShowTitle={isShowTitle}
              handleClickMaterialName={handleClickMaterialName}
              handleClickChart={handleClickChart}
              index={index}
              isShowBorder={isShowBorder}
              isShowTitleUnderline={isShowTitleUnderline}
            />
          );
        }
        return null;
      })}
    </div>
  );
}
