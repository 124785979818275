"use client";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { PDFDocumentProxy, PageViewport } from "pdfjs-dist";
import { PDFViewer } from "pdfjs-dist/web/pdf_viewer";

import "@/../public/pdf_viewer.css";
import allEventBus, {
  eventBus as originalEventBus,
  EventMessage as AllEventMessage,
} from "@/utils/event-bus";

import { initHighlightBBox } from "../../../lib/highlightText";

import { createPDFView } from "./pdf";
import { EventMessage, PubMessage } from "../constants";
import styles from "./index.module.css";
import { usePdfReaderStore } from "../store";
import { RelatedChartConstants } from "@/modules/related-charts";
import { EventMap } from "@/modules/related-charts/types";
import { PagesLoadedArgs } from "../type";
import { SearchText } from "../../../lib/searchText";
import clsx from "clsx";

interface PDfViewerProps {
  pdfDocument: PDFDocumentProxy | null;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  pdfViewRef: React.MutableRefObject<PDFViewer | null>;
  setCurrentPage?: Dispatch<SetStateAction<string>>;
}
type EXTRACT_VIEW_ORIGINALData = Parameters<
  Parameters<typeof allEventBus.on<AllEventMessage.EXTRACT_VIEW_ORIGINAL>>[1]
>[0];

type SUMMARY_VIEW_ORIGINALData = Parameters<
  Parameters<typeof allEventBus.on<AllEventMessage.SUMMARY_VIEW_ORIGINAL>>[1]
>[0];

const searchText = new SearchText(
  "ot",
  {
    color: "#2d2d2d",
    backgroundColor: "rgba(251,255,41)",
  },
  false,
);

export function PdfViewer(props: PDfViewerProps) {
  const {
    PubSub,
    throttle,
    eventBus,
    handlePagesLoaded,
    handleTextLayerRendered,
    readerLayout,
    originTextFromPdf,
    updateOriginTextTop,
    handleImgRender,
  } = usePdfReaderStore();
  // const { state, findResultsRef, updateState } = readerLayout;
  const textLayerRef = useRef<HTMLDivElement | null>(null);
  const { pdfDocument, pdfViewRef, setCurrentPage } = props;
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const onPagesLoaded = (args: PagesLoadedArgs) => {
    handlePagesLoaded && handlePagesLoaded(args);
  };

  const onTextLayerRendered: (args: { pageNumber: number }) => void = ({
    pageNumber,
  }) => {
    pdfViewRef.current &&
      handleTextLayerRendered?.(pageNumber, pdfViewRef.current);
    pdfViewRef.current && handleImgRender?.(pageNumber, pdfViewRef.current);
    const viewPort = pdfViewRef.current?.getPageView(pageNumber)
      ?.viewport as PageViewport;
    if (!viewPort) {
      return;
    }
    PubSub.publish(PubMessage.RenderExtractByPage, pageNumber);
    originalEventBus.emit(EventMessage.PageRenderCompleted, {
      pageNumber,
      pageScape: {
        height: viewPort.height,
        width: viewPort.width,
      },
    });
  };

  const handleClickPdfContainer = () => {
    searchText.unloadSearchText();
    pdfViewRef.current?.eventBus.dispatch("find", {
      highlightAll: false,
      query: "",
      caseSensitive: false,
      entireWord: false,
      findPrevious: false,
      matchDiacritics: false,
      type: "",
    });
  };

  const handleSearchInPDFFile = (data: SUMMARY_VIEW_ORIGINALData) => {
    if (!pdfViewRef.current) {
      return;
    }
    if (typeof data === "string") {
      // // 去掉末尾的句号，防止匹配不上
      if (data.length > 0 && data[data.length - 1] === "。") {
        data = data.substring(0, data.length - 1);
      }

      pdfViewRef.current.eventBus.dispatch("find", {
        highlightAll: false,
        query: data,
        caseSensitive: false,
        entireWord: false,
        findPrevious: false,
        matchDiacritics: false,
        type: "",
      });
      return;
    }

    if (!Array.isArray(data)) {
      const { query, textInfo } = data;
      try {
        searchText.unloadSearchText();
        searchText.initMultiText(
          pdfViewRef.current,
          [{ word: query }],
          new Set(textInfo.map((ti) => +ti.page_num + 1)),
          "single",
        );
      } catch (e) {
        console.error(e);
      }

      return;
    }

    if (data.length === 0) {
      return;
    }

    // 取 data 的第一个数据
    const firstData = data[0];
    if (!firstData) {
      return;
    }
    // 如果包含 position_id 的字段，表示使用最小信息单元进行定位
    if ("position_id" in firstData) {
      allEventBus.emit(AllEventMessage.LOCATE_BY_POSITION_ID, {
        mode: "oneWay",
        // @ts-expect-error
        positionIds: data.map((d) => d.position_id),
      });

      return;
    }

    initHighlightBBox(
      data,
      {
        pdfViewer: pdfViewRef.current,
      },
      "",
    );
  };

  useEffect(() => {
    eventBus.on(AllEventMessage.SUMMARY_VIEW_ORIGINAL, handleSearchInPDFFile);

    return () => {
      eventBus.off(
        AllEventMessage.SUMMARY_VIEW_ORIGINAL,
        handleSearchInPDFFile,
      );
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
      timerRef.current = null;
    };
  }, []);

  const handleScrollToPageForRelatedChart = (
    data: EventMap["ScrollToPage"],
  ) => {
    if (!data) {
      return;
    }
    const { elementPos } = data;
    const { page_num } = elementPos;
    try {
      pdfViewRef.current?.scrollPageIntoView({
        pageNumber: page_num + 1,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const checkOriginTexts = () => {
    if (!readerLayout) {
      return;
    }
    const { state, findResultsRef, updateState } = readerLayout;
    state.pdfOriginTexts.forEach((originText) => {
      if (originTextFromPdf.includes(originText.current)) {
        findResultsRef.current.found.push({ id: "", q: originText.current });
      } else {
        findResultsRef.current.notFound.push({ id: "", q: originText.current });
      }
    });
    updateState({
      ...state,
      pdfOriginTextsAfterCheck: JSON.parse(
        JSON.stringify(findResultsRef.current),
      ),
    });

    findResultsRef.current.found.length = 0;
    findResultsRef.current.notFound.length = 0;
  };

  useEffect(() => {
    if (originTextFromPdf === "") {
      return;
    }
    if (readerLayout?.state.pdfOriginTexts.length === 0) {
      return;
    }

    checkOriginTexts();
  }, [readerLayout?.state.pdfOriginTexts, originTextFromPdf]);

  const handleScrollToPage = (data: EXTRACT_VIEW_ORIGINALData) => {
    const { metaInfo = "" } = data;

    let pageNumber = 1;
    try {
      pageNumber = JSON.parse(metaInfo).pageNumber;
    } catch (e) {
      return;
    }
    pdfViewRef.current?.scrollPageIntoView({
      pageNumber,
      allowNegativeOffset: true,
    });
  };

  const renderPdf = () => {
    if (!textLayerRef.current) {
      return;
    }

    if (!pdfDocument) {
      return;
    }

    if (pdfViewRef.current) {
      pdfViewRef.current._resetView();
    }

    const viewer = createPDFView(pdfDocument, textLayerRef.current);
    pdfViewRef.current = viewer;

    pdfViewRef.current.eventBus.on("pagesloaded", onPagesLoaded);
    pdfViewRef.current.eventBus.on("textlayerrendered", onTextLayerRendered);
  };

  useEffect(() => {
    renderPdf();

    eventBus.on(AllEventMessage.EXTRACT_VIEW_ORIGINAL, handleScrollToPage);
    eventBus.on(AllEventMessage.OVERVIEW_VIEW_ORIGINAL, handleScrollToPage);
    eventBus.on(
      RelatedChartConstants.EventMessage.ScrollToPage,
      handleScrollToPageForRelatedChart,
    );

    return () => {
      eventBus.off(AllEventMessage.EXTRACT_VIEW_ORIGINAL, handleScrollToPage);
      eventBus.off(AllEventMessage.OVERVIEW_VIEW_ORIGINAL, handleScrollToPage);
      eventBus.off(
        RelatedChartConstants.EventMessage.ScrollToPage,
        handleScrollToPageForRelatedChart,
      );
      if (pdfViewRef.current) {
        pdfViewRef.current.eventBus.off("pagesloaded", onPagesLoaded);
        pdfViewRef.current.eventBus.off(
          "textlayerrendered",
          onTextLayerRendered,
        );
      }
    };
  }, [pdfDocument]);

  const handleScroll = throttle(() => {
    // 因为监听了滚动事件，去设置 currentPage，所以才保证了这个数据实时同步
    setCurrentPage?.(pdfViewRef.current?.currentPageNumber + "");
  }, 100);

  useEffect(() => {
    if (!textLayerRef.current) {
      return;
    }
    const rect = textLayerRef.current.getBoundingClientRect();
    updateOriginTextTop(Math.floor(rect.height * 0.2));
  }, []);

  return (
    <>
      <div
        className={styles.main}
        id="pdf-view-root"
        onClick={handleClickPdfContainer}
      >
        <div
          ref={textLayerRef}
          id="pdf-view-container"
          className={clsx(styles.pdfViewContainer)}
          onScroll={handleScroll}
        >
          <div className="pdfViewer bg-[#fbfbfb]"></div>
        </div>
      </div>
    </>
  );
}
