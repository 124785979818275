import { useEffect, useRef } from "react";
import buryHatchet from "@/batch";
import { BATCH } from "@/batch/type";
import { EventMessage, eventBus } from "@/utils/event-bus";
import { formatBatchTime } from "@/utils/time";

type Options = {
  mode: string;
  rid?: string;
  url?: string;
  type?: string;
};

// 统计阅读时长
function useReadTime(options: Options) {
  const traceIdRef = useRef<string>('');
  const { mode, rid, type } = options;

  useEffect(() => {
    if (mode === "full") return;
    const start = formatBatchTime();
    const handleGetTraceId = ({traceId}: {traceId: string}) => {
      if (!traceIdRef.current) {
        traceIdRef.current = traceId;
      }
    };
    eventBus.on(EventMessage.READER_TRACE_ID, handleGetTraceId)

    return () => {
      const end = formatBatchTime();
      const uid = localStorage.getItem("Lingo-UID") || "";
      const track = buryHatchet.track<BATCH.TModuleName>("Read");
      const property: BATCH.WebReaderSentry = {
        uid,
        trace_id: traceIdRef.current,
        start_time: start,
        exit_time: end,
        article_id: rid,
        type,
      };
      if (traceIdRef.current) {
        track(BATCH.EventName.Web_Reader, property);
        traceIdRef.current = '';
      }
      eventBus.off(EventMessage.READER_TRACE_ID, handleGetTraceId)
    }
  }, [mode, rid, type]);
}

export default useReadTime;
