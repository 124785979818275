"use client";

import { ReactNode, createContext, useContext } from "react";
import useStore from "./store/useStore";
import { Options, AppLayoutProps, AppLayoutStore } from "./types";
import { Maybe } from "@deeplang/shared";
export const AppLayoutContext =
  createContext<Maybe<AppLayoutStore & Options>>(null);

export const useAppLayout = () => {
  const state = useContext(AppLayoutContext);
  if (!state) {
    throw new Error("useAppLayout must be used within a AppLayoutProvider");
  }
  return state;
};

export function AppLayoutProvider(options: AppLayoutProps): ReactNode {
  const { children } = options;
  const store = useStore({});


  return (
    <AppLayoutContext.Provider value={{ ...options, ...store }}>
      {children}
    </AppLayoutContext.Provider>
  );
}
