import { createContext } from "react";

import { Maybe } from "@deeplang/shared";
import { IFloatToolbar } from "../types";

export interface Options {
  children?: React.ReactNode;
  defaultParams: IFloatToolbar;
}

export const FloatToolbarContext = createContext<Maybe<IFloatToolbar>>(null);

export const FloatToolbarProvider = (options: Options) => {
  const { children, defaultParams } = options;
  // const store = useFloatToolbar(defaultParams);

  return (
    // <FloatToolbarContext.Provider value={{ ...defaultParams, ...store }}>
    <FloatToolbarContext.Provider value={{ ...defaultParams }}>
      {children}
    </FloatToolbarContext.Provider>
  );
};
