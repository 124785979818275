import { PdfSource, WebSource } from "../constants/common";
import { singleton } from ".";
class MaterialAdapter {
    _downloadFileUrl = "";
    initial(downloadFileUrl) {
        this._downloadFileUrl = downloadFileUrl;
    }
    encodeLink(material) {
        if (PdfSource.has(material.channel_type)) {
            return material.id;
        }
        if (WebSource.has(material.channel_type)) {
            return encodeURIComponent(material.url);
        }
        return "";
    }
    fromWebPage(webPage) {
        return {
            fullName: webPage.title === "" ? "无标题" : webPage.title,
            name: this.dropSuffixForFullName(webPage.title),
            resourceType: webPage.channel_type,
            link: webPage.url,
            ...webPage,
        };
    }
    fromPdf(pdfFile) {
        return {
            name: this.dropSuffixForFullName(pdfFile.title === "" ? "无标题.pdf" : pdfFile.title),
            fullName: pdfFile.title === "" ? "无标题.pdf" : pdfFile.title,
            resourceType: pdfFile.channel_type,
            link: this._downloadFileUrl + "?file_id=" + pdfFile.id,
            ...pdfFile,
        };
    }
    from(material) {
        if (PdfSource.has(material.channel_type)) {
            return {
                name: this.dropSuffixForFullName(material.title === "" ? "无标题.pdf" : material.title),
                resourceType: material.channel_type,
                link: this._downloadFileUrl + "?file_id=" + material.id,
                fullName: material.title === "" ? "无标题.pdf" : material.title,
                ...material,
            };
        }
        if (WebSource.has(material.channel_type)) {
            return {
                fullName: material.title === "" ? "无标题" : material.title,
                name: this.dropSuffixForFullName(material.title === "" ? "无标题" : material.title),
                resourceType: material.channel_type,
                link: material.url,
                ...material,
            };
        }
    }
    /**
     *
     * @param fullName 去除文件名的后缀
     * @returns
     */
    dropSuffixForFullName(fullName) {
        const [name] = fullName.split(".");
        // 去掉扩展名后返回文件名
        return name || "";
    }
}
const SingleAdapter = singleton(MaterialAdapter);
export default new SingleAdapter();
