import sensors from "sa-sdk-javascript";
import { BATCH } from "./type";
import Package from "../../package.json";
import { productName } from "../config";

export class Sensors implements BATCH.Strategy {
  private _publicProperties: BATCH.PublicProperties;

  constructor() {
    this._publicProperties = {
      productName,
      productVersion: Package.version || "0.0.0",
      featureVersion: "0.0.0",
      isBuy: false,
      isLogin: false,
      uid: "",
    };

    sensors.quick("isReady", function () {
      const anonymousID = sensors.quick("getAnonymousID");
      sessionStorage.setItem("AnonymousID", anonymousID);
    });

    try {
      sensors.init({
        server_url: process.env.NEXT_PUBLIC_APP_BATCH_URL,
        is_track_single_page: false, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
        use_client_time: true,
        send_type: "beacon",
        // show_log: true,
        heatmap: {
          //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
          clickmap: "not_collect",
          //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
          scroll_notice_map: "not_collect",
        },
      });
      // this.registerPublicProperties(this._publicProperties);
      // sensors.quick('autoTrack');
    } catch (e) {
      console.error(e);
    }
  }

  login(uid: string) {
    sensors.login(uid);
  }

  setProfile(properties: BATCH.ProfileProperties) {
    try {
      if (properties.isBuy !== undefined) {
        sensors.setProfile({
          is_buy: properties.isBuy,
        });
      }

      if (properties.uid !== undefined) {
        sensors.setProfile({
          uid: properties.uid,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  setOnceProfile(properties: BATCH.OnceProfileProperties) {
    try {
      if (properties.firstDownloadchannel !== undefined) {
        sensors.setOnceProfile({
          first_downloadchannel: properties.firstDownloadchannel,
        });
      }

      if (properties.registerTime) {
        sensors.setOnceProfile({
          regist_time: properties.registerTime,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  registerPublicProperties(publicProperties: BATCH.PublicProperties) {
    try {
      const properties = Object.assign(
        this._publicProperties,
        publicProperties,
      );

      sensors.registerPage({
        product_name: properties.productName,
        product_version: properties.productVersion,
        feature_version: properties.featureVersion,
        is_login: properties.isLogin,
        is_buy: properties.isBuy,
        uid: properties.uid,
        invitation_test: properties.invitation_test,
      });
    } catch (e) {
      console.error(e);
    }
  }

  track<K = BATCH.TModuleName>(moduleName: K) {
    return <T extends keyof BATCH.EventMap>(...args: BATCH.Args<T>[]) => {
      try {
        this.batch(args as BATCH.SENSORS.TrackArgs<T>[], moduleName);
      } catch (e) {
        console.error(e);
      }
    };
  }

  batch<T extends keyof BATCH.EventMap, K>(
    [eventName, properties]: BATCH.SENSORS.TrackArgs<T>[],
    moduleName: K,
  ) {
    Object.assign(properties || {}, {
      moduleName,
    });
    sensors.track(eventName as string, properties as object);
  }
}
