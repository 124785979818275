import {
  ChartSource,
  convertEnumToStringForChartSource,
} from "@deeplang/shared";
import styles from "./ChartItem.module.css";
import { Divider } from "@deeplang/icons";

export interface ChartItemProps {
  chartTitle: string;
  materialName: string;
  organization: string;
  chartSource: ChartSource;
  children: React.ReactNode;
  isShowTitle: boolean;
  handleClickChartTitle?: () => void;
  handleClickMaterialName?: () => void;
  handleClickChart: (index: number) => void;
  index: number;
  isShowBorder: boolean;
  isShowTitleUnderline: boolean;
}
export function ChartItem(props: ChartItemProps) {
  const {
    chartTitle,
    materialName,
    organization,
    chartSource,
    children,
    isShowTitle,
    handleClickChartTitle,
    handleClickMaterialName,
    handleClickChart,
    index,
    isShowBorder,
    isShowTitleUnderline,
  } = props;

  return (
    <div
      className={styles.container}
      style={{ border: isShowBorder ? "1px solid #D9D9D9" : "0" }}
    >
      {isShowTitle && (
        <div className={styles.header} onClick={handleClickChartTitle}>
          {chartTitle}
        </div>
      )}
      <div
        className={styles.main}
        onClick={() => {
          handleClickChart(index);
        }}
      >
        <div>{children}</div>
      </div>
      <div className={styles.footer}>
        <div
          onClick={handleClickMaterialName}
          className={styles.materialNameText}
          style={{
            textDecorationLine: isShowTitleUnderline ? "underline" : "",
          }}
        >
          {materialName}
        </div>
        <div className={styles.secondaryInfo}>
          <div className={styles.chartSourceText}>
            来自：{convertEnumToStringForChartSource(chartSource)}
          </div>
          <Divider
            direction="vertical"
            height={12}
            width={2}
            stroke="#D9D9D9"
            strokeWidth={1}
          />
          {organization !== "" && (
            <div className={styles.organizationText}>机构：{organization}</div>
          )}
        </div>
      </div>
    </div>
  );
}
