import { EXTRACT_LIST } from "@/constant/api";
import request from "@/utils/request";
import { IExtractApi } from "@deeplang/shared";

export interface ExtractsItem {
  url: string;
  source_type: number;
  file_id: string;
  file_type: string;
  title: string;
  file_name: string;
  entry_id: string;
  entry_type: number;
  content: string;
  color: number;
  tags: { tag: string; tag_id: string }[];
  meta_info: string;
  update_time: string;
  create_time: string;
  channel_type: number;
}
export interface GetExtractsResponse {
  has_more: boolean;
  list: ExtractsItem[];
}
export function addExtract(data: any) {
  return request({
    url: "/api/plugin/extract/add",
    method: "post",
    data,
  });
}

export function updateExtract(data: any) {
  return request({
    url: "/api/plugin/extract/update",
    method: "post",
    data,
  });
}

export function removeExtract(data: any) {
  return request({
    url: "/api/plugin/extract/del",
    method: "post",
    data,
  });
}

export function getExtracts(data: any) {
  return request({
    url: EXTRACT_LIST,
    method: "post",
    data,
  });
}

export const extractApi: IExtractApi = {
  add(data) {
    return request({
      url: "/api/plugin/extract/add",
      method: "post",
      data,
    });
  },
  list(data) {
    return request({
      url: "/api/plugin/extract/detail",
      method: "post",
      data: data,
    });
  },
  delete(data) {
    return request({
      url: "/api/plugin/extract/del",
      method: "post",
      data,
    });
  },
  update(data) {
    return request({
      url: "/api/plugin/extract/update",
      method: "post",
      data,
    });
  },
  share(data) {
    return request({
      url: "/api/plugin/extract/share",
      method: "post",
      data,
    });
  },
};

export default {
  details(data: any) {
    return request({
      url: "/api/plugin/extract/detail",
      method: "post",
      data,
    });
  },

  update(data: any) {
    return request({
      url: "/api/plugin/extract/update",
      method: "post",
      data,
    });
  },

  delete(data: any) {
    return request({
      url: "/api/plugin/extract/del",
      method: "post",
      data,
    });
  },
};
