export var FileStatus;
(function (FileStatus) {
    FileStatus[FileStatus["Init"] = 0] = "Init";
    FileStatus[FileStatus["Uploading"] = 1] = "Uploading";
    FileStatus[FileStatus["UploadingSuccess"] = 2] = "UploadingSuccess";
    FileStatus[FileStatus["UploadingFailed"] = 3] = "UploadingFailed";
    FileStatus[FileStatus["Validation"] = 4] = "Validation";
    FileStatus[FileStatus["ValidationPass"] = 5] = "ValidationPass";
    FileStatus[FileStatus["ValidationFailed"] = 6] = "ValidationFailed";
    FileStatus[FileStatus["Parsing"] = 7] = "Parsing";
    FileStatus[FileStatus["ParsingSuccess"] = 8] = "ParsingSuccess";
    FileStatus[FileStatus["ParsingFailed"] = 9] = "ParsingFailed";
    FileStatus[FileStatus["Frequent"] = 10] = "Frequent";
    FileStatus[FileStatus["GenerationSuccess"] = 11] = "GenerationSuccess";
    FileStatus[FileStatus["GenerationFailed"] = 12] = "GenerationFailed";
    FileStatus[FileStatus["Corruption"] = 20] = "Corruption";
    FileStatus[FileStatus["Stop"] = 99] = "Stop";
    FileStatus[FileStatus["Deletion"] = 100] = "Deletion";
    FileStatus[FileStatus["Repeat"] = 999999] = "Repeat";
    FileStatus[FileStatus["UnSafe"] = 26000] = "UnSafe";
    FileStatus[FileStatus["NetworkError"] = 500] = "NetworkError";
})(FileStatus || (FileStatus = {}));
export var UrlParseStatus;
(function (UrlParseStatus) {
    UrlParseStatus[UrlParseStatus["Init"] = -1] = "Init";
    UrlParseStatus[UrlParseStatus["Success"] = 0] = "Success";
    UrlParseStatus[UrlParseStatus["Parsing"] = 1] = "Parsing";
    UrlParseStatus[UrlParseStatus["Failed"] = 2] = "Failed";
    UrlParseStatus[UrlParseStatus["Error"] = 3] = "Error";
    UrlParseStatus[UrlParseStatus["UnSafe"] = 26000] = "UnSafe";
})(UrlParseStatus || (UrlParseStatus = {}));
// 阅读的实体类型
// 摘录用这个
export var ReadingMaterial;
(function (ReadingMaterial) {
    // 未知
    ReadingMaterial[ReadingMaterial["Unknown"] = -1] = "Unknown";
    // 找词
    ReadingMaterial[ReadingMaterial["Word"] = 1] = "Word";
    // 找句
    ReadingMaterial[ReadingMaterial["Quote"] = 2] = "Quote";
    // 搭配本身
    ReadingMaterial[ReadingMaterial["Collection"] = 3] = "Collection";
    // 搭配例句
    ReadingMaterial[ReadingMaterial["CollectionExample"] = 4] = "CollectionExample";
    // 概述
    ReadingMaterial[ReadingMaterial["Overview"] = 5] = "Overview";
    // 大纲
    ReadingMaterial[ReadingMaterial["Outline"] = 6] = "Outline";
    // 网页
    ReadingMaterial[ReadingMaterial["WebSite"] = 7] = "WebSite";
    // 摘录
    ReadingMaterial[ReadingMaterial["Extract"] = 8] = "Extract";
    // PDF
    ReadingMaterial[ReadingMaterial["PDF"] = 10] = "PDF";
    // 关键信息
    ReadingMaterial[ReadingMaterial["KeyInfo"] = 11] = "KeyInfo";
    // 多文档
    ReadingMaterial[ReadingMaterial["Multi"] = 12] = "Multi";
})(ReadingMaterial || (ReadingMaterial = {}));
// 阅读器类型
export var ReaderViewType;
(function (ReaderViewType) {
    ReaderViewType[ReaderViewType["Web"] = 0] = "Web";
    ReaderViewType[ReaderViewType["Pdf"] = 1] = "Pdf";
})(ReaderViewType || (ReaderViewType = {}));
// 来源
export var ReadingSource;
(function (ReadingSource) {
    ReadingSource[ReadingSource["All"] = 0] = "All";
    //pdf
    ReadingSource[ReadingSource["PdfPc"] = 10] = "PdfPc";
    ReadingSource[ReadingSource["PdfPlugin"] = 11] = "PdfPlugin";
    ReadingSource[ReadingSource["PdfReader"] = 12] = "PdfReader";
    ReadingSource[ReadingSource["PdfPcDb"] = 13] = "PdfPcDb";
    ReadingSource[ReadingSource["PdfWebReader"] = 14] = "PdfWebReader";
    ReadingSource[ReadingSource["DesktopPdf"] = 61] = "DesktopPdf";
    ReadingSource[ReadingSource["LingoPdf"] = 71] = "LingoPdf";
    //web
    ReadingSource[ReadingSource["UrlPc"] = 20] = "UrlPc";
    ReadingSource[ReadingSource["UrlPlugin"] = 21] = "UrlPlugin";
    ReadingSource[ReadingSource["UrlPluginMenu"] = 22] = "UrlPluginMenu";
    ReadingSource[ReadingSource["UrlPcDb"] = 23] = "UrlPcDb";
    ReadingSource[ReadingSource["UrlReader"] = 24] = "UrlReader";
    ReadingSource[ReadingSource["DesktopUrl"] = 60] = "DesktopUrl";
    ReadingSource[ReadingSource["LingoUrl"] = 70] = "LingoUrl";
    //wechat
    ReadingSource[ReadingSource["WeChatUrl"] = 30] = "WeChatUrl";
    ReadingSource[ReadingSource["WeChatPdf"] = 31] = "WeChatPdf";
    ReadingSource[ReadingSource["MiniProgramUrl"] = 40] = "MiniProgramUrl";
    ReadingSource[ReadingSource["MiniProgramPdf"] = 41] = "MiniProgramPdf";
    ReadingSource[ReadingSource["MiniLingoUrl"] = 42] = "MiniLingoUrl";
    ReadingSource[ReadingSource["MiniLingoPdf"] = 43] = "MiniLingoPdf";
    ReadingSource[ReadingSource["WechatLingoUrl"] = 50] = "WechatLingoUrl";
    ReadingSource[ReadingSource["WechatLingoPdf"] = 51] = "WechatLingoPdf";
    //multi Doc
    ReadingSource[ReadingSource["MultiDoc"] = 72] = "MultiDoc";
})(ReadingSource || (ReadingSource = {}));
// 微信pdf
export const WechatPdf = [
    ReadingSource.WeChatPdf,
    ReadingSource.MiniProgramPdf,
    ReadingSource.MiniLingoPdf,
    ReadingSource.WechatLingoPdf,
];
// 微信web
export const WechatUrl = [
    ReadingSource.WeChatUrl,
    ReadingSource.MiniProgramUrl,
    ReadingSource.MiniLingoUrl,
    ReadingSource.WechatLingoUrl,
];
// 网页端web
export const WebsiteUrl = [
    ReadingSource.UrlPc,
    ReadingSource.UrlPlugin,
    ReadingSource.UrlPluginMenu,
    ReadingSource.WeChatUrl,
    ReadingSource.UrlPcDb,
    ReadingSource.UrlReader,
    ReadingSource.DesktopUrl,
    ReadingSource.LingoUrl,
];
// 网页端pdf
export const WebsitePdf = [
    ReadingSource.PdfPc,
    ReadingSource.PdfReader,
    ReadingSource.PdfPlugin,
    ReadingSource.PdfPcDb,
    ReadingSource.PdfWebReader,
    ReadingSource.DesktopPdf,
    ReadingSource.LingoPdf,
];
export const PdfSources = [...WebsitePdf, ...WechatPdf];
export const MultiDocSources = [ReadingSource.MultiDoc];
// 该数组内的来源，被认为是 pdf, 在不需要区分 weChat 和 网页端 的时候可以使用
export const PdfSource = new Set(PdfSources);
// 该数组内的来源，被认为是 weChat
export const WechatSource = new Set(WechatUrl);
// 该数组内的来源，被认为是 web，在不需要区分 weChat 和 网页端 的时候可以使用
export const WebSource = new Set([...WebsiteUrl, ...WechatUrl]);
// 该数组内的来源，被认为是多文档
export const MultiDocSource = new Set(MultiDocSources);
export var TagStatus;
(function (TagStatus) {
    TagStatus[TagStatus["Normal"] = 0] = "Normal";
    TagStatus[TagStatus["Deleted"] = 1] = "Deleted";
})(TagStatus || (TagStatus = {}));
export var ViewPriority;
(function (ViewPriority) {
    ViewPriority[ViewPriority["ContextMenu"] = 999] = "ContextMenu";
    ViewPriority[ViewPriority["ShowPDFOtherComponents"] = 900] = "ShowPDFOtherComponents";
    ViewPriority[ViewPriority["HidePDFOtherComponents"] = -1] = "HidePDFOtherComponents";
})(ViewPriority || (ViewPriority = {}));
export var InvitationTestStatus;
(function (InvitationTestStatus) {
    InvitationTestStatus[InvitationTestStatus["NoRequest"] = -1] = "NoRequest";
    InvitationTestStatus[InvitationTestStatus["HasRequestButNoCheck"] = 0] = "HasRequestButNoCheck";
    InvitationTestStatus[InvitationTestStatus["HasRequestAndNoApproved"] = 1] = "HasRequestAndNoApproved";
    InvitationTestStatus[InvitationTestStatus["HasRequestAndApproved"] = 2] = "HasRequestAndApproved";
})(InvitationTestStatus || (InvitationTestStatus = {}));
export var ChartSource;
(function (ChartSource) {
    ChartSource[ChartSource["Public"] = 2] = "Public";
    ChartSource[ChartSource["Library"] = 1] = "Library";
})(ChartSource || (ChartSource = {}));
export var FileType;
(function (FileType) {
    FileType[FileType["Web"] = 7] = "Web";
    FileType[FileType["PDF"] = 10] = "PDF";
})(FileType || (FileType = {}));
