export var ElementType;
(function (ElementType) {
    ElementType[ElementType["Image"] = 1] = "Image";
    ElementType[ElementType["Table"] = 4] = "Table";
})(ElementType || (ElementType = {}));
export var HistoryType;
(function (HistoryType) {
    HistoryType[HistoryType["Library"] = 1] = "Library";
    HistoryType[HistoryType["Chart"] = 2] = "Chart";
})(HistoryType || (HistoryType = {}));
