export function customSetInterval(callback, interval) {
    let timerId;
    function repeat() {
        // 执行回调函数
        callback();
        // 再次调用 setTimeout 来模拟 setInterval
        timerId = setTimeout(repeat, interval);
    }
    // 启动初始的 setTimeout
    timerId = setTimeout(repeat, interval);
    // 返回一个对象，包含一个取消定时器的方法
    return {
        cancel: function () {
            clearTimeout(timerId);
        },
    };
}
