export enum PubMessage {
  ContextMenuDelete = 'ContextMenuDelete',
  RefreshHistoryList = 'RefreshHistoryList',
  RenderPDFFile = 'RenderPDFFile',
  GeneratePDFFileContent = 'GeneratePDFFileContent',
  SearchInPDFFile = 'SearchInPDFFile',
  PDFFileViolation = 'PDFFileViolation',
  EmptyFileList = 'EmptyFileList',
  RenderExtractByPage = 'RenderExtractByPage',
  ScrollToPdfPage = 'ScrollToPdfPage',
  LeftBarExpand = 'LeftBarExpand',
  LeftBarRetract = 'LeftBarExpandRetract',
}

export enum EventMessage {
  PageRenderCompleted = 'PageRenderCompleted',
  CurrentPageNumberChange = 'CurrentPageNumberChange',
}

export enum Scale {
  Auto = '自动缩放',
  ActualSize = '实际大小',
  FitPage = '适合页面',
  FitPageWidth = '适合页宽',
  Percentage50 = '50%',
  Percentage75 = '75%',
  Percentage100 = '100%',
  Percentage150 = '150%',
  Percentage200 = '200%',
  Percentage300 = '300%',
  Percentage400 = '400%',
}