import { useEffect, useState } from "react";
import { NoCharts } from "./components/NoCharts";
import { MChartCard } from "./modules/MChartCard";
import { useRelatedCharts } from "./hooks/useRelatedCharts";
import { useRelatedChartsStore } from "../store";
import { useElementList } from "./hooks/useElementList";
import { ElementType } from "@deeplang/shared";
import SkeletonScreen from "@/components/skeleton-screen";

export function Main() {
  const [noChartsToShow, setNoChartsToShow] = useState(false);
  const { allRelatedCharts, requestRelatedCharts } = useRelatedCharts();
  const [isLoading, setIsLoading] = useState(true);
  const { material, setActiveElement } = useRelatedChartsStore();
  const { elementList } = useElementList({
    resource_id: material?.id || "",
    materialDetail: material,
    element_type_list: [ElementType.Image, ElementType.Table],
  });

  const getRelatedCharts = async () => {
    await requestRelatedCharts({
      resource_id: material?.id || "",
      // @ts-ignore
      channel_type: material?.channel_type || -1,
    });

    // allRelatedCharts 拉下来后，则需要停止 loading，因为这里的 loading 态，本身
    // 就是用来等待这个接口返回的。
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    getRelatedCharts();

    return () => {
      // 如果重新更新了 materialDetail，也就是 File 或者 web，那么需要置空 activeElement
      // 如果不置空，这个数据还保留着，那么等 allRelatedCharts 和 elementList 拉下来的时候，
      // 会认为 activeElement 有值，就不会加载新的 allRelatedCharts 和 elementList 中首个 element
      // 这里是针对侧边栏切换的场景
      setActiveElement(null);
    };
  }, [material]);

  useEffect(() => {
    if (allRelatedCharts.length === 0) {
      setNoChartsToShow(true);
    } else {
      setIsLoading(false);
      setNoChartsToShow(false);
    }
  }, [allRelatedCharts]);

  if (isLoading) {
    return <SkeletonScreen />;
  }
  if (noChartsToShow) {
    return <NoCharts />;
  }

  return (
    <MChartCard allRelatedCharts={allRelatedCharts} elementList={elementList} />
  );
}
