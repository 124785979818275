import { useRef, useState } from "react";
import {
  AnalyzingFileItem,
  AnalyzingModalRef,
  AnalyzingUrlItem,
} from "../types";

export function useBatchAnalyzingDocument() {
  const [analyzingList, setAnalyzingList] = useState<
    (AnalyzingFileItem | AnalyzingUrlItem)[]
  >([]);

  const analyzingModalRef = useRef<AnalyzingModalRef | null>(null);

  const updateAnalyzingList = (
    list: (AnalyzingFileItem | AnalyzingUrlItem)[],
  ) => {
    setAnalyzingList(list);
  };

  const updateAnalyzingListByOrder = (
    item: AnalyzingFileItem | AnalyzingUrlItem,
    order: number,
  ) => {
    setAnalyzingList((prev) => {
      return prev.map((oldItem) => {
        if (oldItem.basicInfo.order === order) {
          return item;
        }

        return oldItem;
      });
    });
  };

  const removeAnalyzingItemByOrder = (order: number) => {
    const newList = analyzingList.filter((_, index) => index !== order);
    setAnalyzingList(newList);
  };

  return {
    analyzingList,
    analyzingModalRef,
    updateAnalyzingList,
    updateAnalyzingListByOrder,
    removeAnalyzingItemByOrder,
    setAnalyzingList,
  };
}
