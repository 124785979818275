'use client';

import { useRef } from "react";
import Image from "next/image";

import styles from "./NoCharts.module.css";
import { getLoginStatus } from "@/packages/passport";
import { eventBus, EventMessage } from "@/utils/event-bus";

export function NoCharts() {
  return (
    <div
      className={`flex flex-col justify-center items-center ${styles.container}`}
    >
      <div className={styles.imageContainer}>
        <Image
          width={477}
          height={241}
          src="https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/lingoreader/pics/chart-example.png"
          alt="no charts"
        />
      </div>
      <div className="text-third text-base text-neutral-500 font-normal leading-relaxed flex flex-col text-center">
        <span>啊哦，没有找到相关图表...</span>
        <span>试试上传一篇芯片或新能源领域的研报吧，更多主题加紧建设中</span>
      </div>
      {/* <SideUploadBtn
        ref={sideUploadBtnRef}
        channelType={{
          pdf: ReadingSource.PdfReader,
          website: ReadingSource.UrlReader,
        }}
        uploadType="pdfUpload"
      >
        <Button
          type={ButtonType.Primary}
          className="text-base"
          onClick={handleClick}
        >
          立即上传
        </Button>
      </SideUploadBtn> */}
    </div>
  );
}
