import { useState } from "react";

export interface UserSetting {
  document_num: number;
}

export function useUserSetting() {
  const [userSetting, setUserSetting] = useState<UserSetting>({
    document_num: 50,
  });

  const updateUserSetting = (setting: UserSetting) => {
    setUserSetting(setting);
  };

  return {
    userSetting,
    updateUserSetting,
  };
}
