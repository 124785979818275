declare global {
  interface Window {
    __bl: {
      error: (error: Error) => void;
      custom: (data: ArmsInfo) => void;
    };
  }
}

interface ArmsInfo {
  key: string;
  value: string;
}

export function useArms() {
  const captureException = (error: Error) => {
    window.__bl.error(error);
  };

  const reportCustom = (info: ArmsInfo) => {
    window.__bl.custom(info);
  };

  return { captureException, reportCustom };
}
