"use client";

import { useState } from "react";

import api from "@/api/tag";
import { to } from "@/utils/await-to";
import { toast } from "@deeplang/dui";

import { IListOptions } from "./useList";
import { TOAST_MESSAGE } from "../constants";
import { TagItem } from "@deeplang/shared";

const initParams = {
  tag: "",
};

function useTag(options: IListOptions = {}) {
  if (!options.api) options.api = api;

  const [list, setList] = useState<Partial<TagItem>[]>([]);
  const [form, serForm] = useState<Partial<TagItem>>({ ...initParams });

  const updateForm = (val?: Partial<TagItem>) => {
    if (!val) {
      serForm({ ...initParams });
    } else {
      serForm({ ...form, ...val });
    }
  };

  const getTagList = async (param?: Record<string, any>) => {
    const data = { ...param };
    const [err, result]: any = await to(api.list(data));
    if (err) throw err;
    setList(result?.list);
    return result;
  };

  const onCreateTag = async (tag?: string) => {
    const tagStr = tag?.toString() || "";
    const data: any = { tag: tagStr };
    if (tagStr?.length > 30) {
      return toast.error(TOAST_MESSAGE.TAG_NAME_LENGTH_LIMIT);
    }
    const [err, result] = await to(api.create(data));
    if (err) throw err;
    return result;
  };

  const onSubmit = async (params: Partial<TagItem> = {}) => {
    const data = { ...form, ...params };
    let err;
    if (data?.id) {
      [err] = await to(api.update(data));
      if (err) toast.error(TOAST_MESSAGE.TAG_UPDATE_FAIL);
    } else {
      [err] = await to(api.create(data));
      if (err) toast.error(TOAST_MESSAGE.TAG_CREATE_FAIL);
    }
    if (err) throw err;
    await getTagList();
    toast.success(TOAST_MESSAGE.TAG_CREATE_SUCCESS);
    serForm({});
  };

  const onDeleteTags = async (ids: string[] = []) => {
    const data = { tag_ids: ids };
    const [err] = await to(api.delete(data));
    if (err) {
      toast.error(TOAST_MESSAGE.TAG_DELETE_FAIL);
      throw err;
    }
    await getTagList();
    toast.success(TOAST_MESSAGE.TAG_DELETE_SUCCESS);
  };

  return {
    list,
    form,
    updateForm,
    getTagList,
    onCreateTag,
    onSubmit,
    onDeleteTags,
  };
}

export default useTag;
