import React, {
  forwardRef,
  use,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Input, Button, Modal, InputRef, toast } from "@deeplang/dui";
import { ArrowsOutSimpleLined, ArrowsInSimpleLined } from "@deeplang/icons";

import "./index.css";
import { debounce } from "lodash-es";
import { ConfigProvider } from "@deeplang/dui";

const { TextArea } = Input;

interface IProps {
  value?: string;
  defaultValue?: string;
  hasFooter?: boolean;
  onSave: (value: string, callback: () => void) => void;
  onCancel: () => void;
  onChange?: (value: string) => void;
  autoSave?: (value: string) => void;
}

export interface INoteInputRef {
  focus: () => void;
  getValue: () => string;
}

const NoteInput = forwardRef<INoteInputRef, IProps>((props, ref) => {
  const {
    hasFooter = true,
    defaultValue = "",
    value,
    onSave,
    onCancel,
    onChange,
    autoSave,
  } = props;

  const inputRef = useRef<InputRef>(null);

  const [open, setOpen] = useState(false);
  const [innerValue, setInnerValue] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false);
  const isComposing = useRef(false);
  const timeoutId = useRef<any>(null);

  useEffect(() => {
    if (hasFooter) {
      focus();
    }
  }, []);

  useEffect(() => {
    setInnerValue(value || "");
  }, [value]);

  const autoSaveHandler = (fn: () => void, delay: number) => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(fn, delay);
  };

  const onChangeHandler = (e: any) => {
    const value = e.target.value;
    if (value.length > 5000) {
      toast.error("啊哦，字数超限了");
      return;
    }
    setInnerValue(value);
    onChange?.(value);

    if (!isComposing.current) {
      autoSaveHandler(() => {
        autoSave?.(value);
      }, 500);
    }
  };

  const handleCompositionStart = () => {
    isComposing.current = true;
  };

  const handleCompositionEnd = (e: any) => {
    isComposing.current = false;
    const value = e.target.value;
    autoSaveHandler(() => {
      autoSave?.(value);
    }, 500);
  };

  const onSaveHandler = () => {
    onSave(innerValue, () => {
      setOpen(false);
    });
  };

  const onShow = () => {
    setOpen(true);
    focus();
  };

  const onHide = () => {
    setOpen(false);
  };

  const focus = debounce(() => {
    inputRef.current?.focus({ cursor: "end" });
  }, 100);

  useImperativeHandle(ref, () => ({
    focus,
    getValue: () => innerValue,
  }));

  const content = (
    <div className="note-input w-full h-full bg-white rounded-[4px]">
      <div className="flex justify-between items-center h-[54px] px-[24px]">
        <span className="font-medium">我的批注</span>
        {open ? (
          <ArrowsInSimpleLined className="cursor-pointer" onClick={onHide} />
        ) : (
          <ArrowsOutSimpleLined className="cursor-pointer" onClick={onShow} />
        )}
      </div>
      <div
        className="px-[24px] relative"
        style={{
          height: open ? "70vh" : isFocused ? "110px" : "148px",
        }}
      >
        <TextArea
          // autoFocus
          ref={inputRef}
          value={innerValue}
          onChange={onChangeHandler}
          placeholder="我现在的想法是..."
          autoSize={true}
          className="min-h-full"
          onFocus={() => setIsFocused(true)}
          // onInput={debounce(handleInput, 1000)}
          // onBlur={() => onSaveHandler()}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          style={{
            minHeight: "100%",
            maxHeight: "100%",
            border: "none",
            boxShadow: "none",
            padding: "0",
            borderRadius: "0",
            // @ts-expect-error
            "&:focus": {
              border: "none",
              boxShadow: "none",
            },
          }}
        />
        {open && (
          <div className="absolute right-[24px] bottom-0 text-[#B7B8B9]">
            {innerValue.length}/5000
          </div>
        )}
      </div>
      {isFocused && (
        <div className="h-[52px] px-[24px] pt-[12px] text-right text-gray-500">
          <>
            <Button
              className="mr-2"
              onClick={() => {
                onHide();
                onCancel();
              }}
            >
              取消
            </Button>
            <Button type="primary" onClick={onSaveHandler}>
              保存
            </Button>
          </>
        </div>
      )}
    </div>
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#2d2d2d",
        },
        components: {
          Input: {
            activeBorderColor: "#2d2d2d",
            activeShadow: "rgba(0, 0, 0, 0.08)",
            hoverBorderColor: "#2d2d2d",
            paddingBlock: 5,
            paddingInline: 8,
          },
          Button: {
            defaultShadow: "none",
            primaryShadow: "none",
          },
        },
      }}
    >
      {!open && (
        <div className="note-input-card w-[360px] h-[222px] shadow-card rounded-lg">
          {content}
        </div>
      )}
      <Modal
        centered
        // maskClosable={false}
        zIndex={Number.MAX_SAFE_INTEGER}
        closable={false}
        footer={null}
        open={open}
        width={"70%"}
        onCancel={onHide}
        className="note-input-modal"
      >
        {content}
      </Modal>
    </ConfigProvider>
  );
});

NoteInput.displayName = "NoteInput";

export default NoteInput;
