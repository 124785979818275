import html2canvas from "html2canvas";
export class ChromeCore {
    downloadByLink(link) { }
    copy(content) {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(content).catch((error) => {
                console.error("No copy permission", error);
            });
            return;
        }
        this.copyTextByExecCommand(content);
    }
    copyTextByExecCommand(content) {
        if (typeof document.execCommand === "function" &&
            typeof document.createElement === "function") {
            const textArea = document.createElement("textarea");
            textArea.style.position = "absolute";
            textArea.style.left = "-9999px";
            textArea.value = content;
            document.body.appendChild(textArea);
            textArea.select();
            let result;
            try {
                result = document.execCommand("copy");
            }
            catch (err) {
                console.error("Unable to copy to clipboard", err);
            }
            document.body.removeChild(textArea);
            if (result) {
                return;
            }
        }
    }
    copyTextByClipboard(content) {
        if (navigator && navigator.clipboard) {
            const type = "text/plain";
            const blob = new Blob([content], { type });
            return blob;
        }
    }
    async copyHtmlByClipboard(content) {
        if (navigator && navigator.clipboard) {
            const type = "text/html";
            const blob = new Blob([content], { type });
            const clipboardItem = new ClipboardItem({
                [type]: blob,
            });
            await navigator.clipboard.write([clipboardItem]);
        }
    }
    copyHtmlByExecCommand(element, handleError, handleSuccess) {
        try {
            if (document.createRange && window.getSelection) {
                const range = document.createRange();
                const sel = window.getSelection();
                sel?.removeAllRanges();
                try {
                    range.selectNodeContents(element);
                    sel?.addRange(range);
                }
                catch (e) {
                    range.selectNode(element);
                    sel?.addRange(range);
                }
                document.execCommand("Copy");
                handleSuccess();
            }
            else {
                handleError();
            }
        }
        catch (e) {
            console.log(e);
            handleError();
        }
    }
    /**
     * 传入任一元素，转化为图片，放到剪切板中
     * @param element 元素
     */
    async copyElementByImage(element, handleError, handleSuccess) {
        if (navigator && navigator.clipboard) {
            const canvas = await html2canvas(element, {
                onclone: (document, element) => {
                    const ths = element.querySelectorAll("th");
                    ths.forEach((th) => {
                        th.style.paddingTop = "0";
                        th.style.paddingBottom = "1.5em";
                        th.classList.remove("___html2canvas___pseudoelement_before");
                        th.classList.remove("ant-table-cell");
                    });
                    element.style.tableLayout = "auto";
                    const pseudoelements = element.querySelectorAll("html2canvaspseudoelement");
                    pseudoelements.forEach((pseudoelement) => {
                        pseudoelement.setAttribute("style", `position: absolute;
            top: 50%;
            inset-inline-end: 0;
            width: 1px;
            height: 1.6em;
            background-color: #f0f0f0;
            transform: translateY(-50%);`);
                    });
                },
            });
            canvas.toBlob(async (blob) => {
                try {
                    if (!blob) {
                        handleError();
                        return;
                    }
                    const clipboardItem = new ClipboardItem({
                        ["image/png"]: blob,
                    });
                    await navigator.clipboard.write([clipboardItem]);
                    handleSuccess();
                }
                catch (e) {
                    console.warn(e);
                    handleError();
                }
            });
        }
    }
    async copyTextAndImage(content, imageSrc) {
        try {
            const imgRes = await this.copyImgByClipboard(imageSrc);
            if (!imgRes) {
                return;
            }
            const textRes = this.copyTextByClipboard(content);
            if (!textRes) {
                return;
            }
            const clipboardItem = new ClipboardItem({
                [textRes.type]: textRes,
                ["image/png"]: imgRes,
            });
            await navigator.clipboard.write([clipboardItem]);
        }
        catch (e) {
            console.error(e);
        }
    }
    async copyImage(src) {
        const imgRes = await this.copyImgByClipboard(src);
        if (!imgRes) {
            return;
        }
        setTimeout(() => {
            navigator.clipboard.write([
                new ClipboardItem({
                    "image/png": new Promise(async (resolve) => {
                        resolve(new Blob([imgRes], { type: "image/png" }));
                    }),
                }),
            ]);
        }, 0);
    }
    copyImgByClipboard(imageSrc) {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement("canvas");
            canvas.style.display = "none";
            const ctx = canvas.getContext("2d");
            const img = document.createElement("img");
            img.crossOrigin = "anonymous";
            img.src = imageSrc + "?no-cache";
            img.style.position = "fixed";
            img.style.zIndex = "-9999";
            document.body.appendChild(img);
            img.onload = () => {
                if (!ctx) {
                    return;
                }
                const { width, height } = img.getBoundingClientRect();
                canvas.width = width;
                canvas.height = height;
                ctx.scale(1, 1);
                ctx.drawImage(img, 0, 0);
                canvas.toBlob(async (blob) => {
                    if (!blob) {
                        return;
                    }
                    document.body.removeChild(img);
                    resolve(blob);
                });
            };
            img.onerror = () => {
                reject(new Error("img loading error!"));
            };
        });
    }
}
