import { nanoid } from "nanoid";

export const colorDescription = (color: number) => {
  if (color === 1) {
    return "黄色";
  }
  if (color === 2) {
    return "红色";
  }
  if (color === 3) {
    return "绿色";
  }

  return "黄色";
};

export function getSelectionTextLength() {
  const selection = window.getSelection();
  return selection?.toString().length ?? 0;
}

export function loadImage(
  url: string,
): Promise<{ data: string; size: number }> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(image, 0, 0);
      const imageData = canvas.toDataURL();
      const response = await fetch(url, {
        headers: {
          "Trace-Id": nanoid(),
        }
      });
      const fileSize = Number(response.headers.get("Content-Length"));
      resolve({ data: imageData, size: fileSize });
    };
    image.onerror = () => {
      reject(new Error("load image failed"));
    };
    image.src = url;
  });
}

export function getUrl() {
  return window.location.href;
}

export function getAuthor() {
  if (window.location.host === "mp.weixin.qq.com") {
    return document.querySelector("#js_name")?.textContent?.trim() || "";
  }
  return "";
}

/**
 * @param { Promise } promise
 * @param { Object= } errorExt
 * @return { Promise }
 */
export function to<T, U = Error>(
  promise: Promise<T>,
  errorExt?: object,
): Promise<[U, undefined] | [null, T]> {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[U, undefined]>((err: U) => {
      if (errorExt) {
        const parsedError = Object.assign({}, err, errorExt);
        return [parsedError, undefined];
      }

      return [err, undefined];
    });
}

export function createNoteIcon(metaInfo: any) {
  const { id, pageNumber } = metaInfo;
  const highlightDOMList = document.querySelectorAll(
    `[data-highlight-id="${id}"]`,
  );
  const highlightDOM = highlightDOMList?.[
    highlightDOMList.length - 1
  ] as HTMLElement;

  const noteDomList = document.querySelectorAll(`[data-note-id="${id}"]`);
  if (noteDomList) {
    noteDomList.forEach((item) => {
      item.parentNode?.removeChild(item);
    });
  }

  if (highlightDOM) {
    highlightDOM.style.position = "relative";
    // 创建Icon元素
    const noteDom = document.createElement("highlight-note-marker");

    noteDom.setAttribute("class", "highlight-note-marker");
    noteDom.setAttribute("data-note-id", id);

    highlightDOM.appendChild(noteDom);

    const parentContainer = document.querySelector(
      `div[data-page-number="${pageNumber}"]`,
    ) as HTMLDivElement;

    const childRect = noteDom.getBoundingClientRect() as DOMRect;
    const parentRect = parentContainer?.getBoundingClientRect() as DOMRect;
    const top = childRect.top - parentRect.top;
    const left = childRect.left - parentRect.left;

    if (top == 0 || left == 0) return;

    const notePdfDom = document.createElement("deeplang-note-icon-pdf");
    notePdfDom.setAttribute("class", "highlight-note-icon-pdf");
    notePdfDom.setAttribute("data-note-id", id);
    notePdfDom.style.setProperty("top", `${top - 20}px`);
    notePdfDom.style.setProperty("left", `${left - 16}px`);

    highlightDOM.removeChild(noteDom);
    parentContainer.appendChild(notePdfDom);
  }
}

export function removePdfNoteIcon(id: string) {
  const noteDomList = document.querySelectorAll(`[data-note-id="${id}"]`);
  if (noteDomList) {
    noteDomList.forEach((item) => {
      item.parentNode?.removeChild(item);
    });
  }
}
