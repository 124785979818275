export namespace BATCH {
  export type Name = "sensors";

  export interface Strategy {
    track: <K>(
      moduleName: K,
    ) => <T extends keyof EventMap>(...args: Args<T>[]) => void;
    batch: <T extends keyof EventMap, K>(args: T[], moduleName: K) => void;
    // 注册公共属性
    registerPublicProperties: (publicProperties: PublicProperties) => void;
    // 设置用户属性
    setProfile: (properties: BATCH.ProfileProperties) => void;
    // 设置一次用户属性
    setOnceProfile: (properties: BATCH.OnceProfileProperties) => void;
    // 设置用户关联
    login: (uid: string) => void;
  }

  export type Args<T extends keyof EventMap> = T | EventMap[T];

  export interface PublicProperties {
    productName?: string;
    productVersion?: string;
    featureVersion?: string;
    isLogin?: boolean;
    isBuy?: boolean;
    uid?: string;
    invitation_test?: number;
  }

  export interface ProfileProperties {
    isBuy?: boolean;
    uid: string;
  }

  export interface OnceProfileProperties {
    firstDownloadchannel?: string;
    registerTime?: string;
  }

  export enum EventName {
    ChangeName = "LG_ChangeName", // 点击修改昵称的按钮埋点上报
    ChangeNameConfirm = "LG_ChangeNameConfirm", // 修改用户昵称埋点上报
    WebRegisterResults = "LG_WebRegisterResults", // 注册事件埋点上报
    WebLoginResults = "LG_WebLoginResults", // 登陆事件埋点上报
    WebClickDatabase = "LG_WebClickDatabase", // 点击个人数据库
    WebDatabaseSearch = "LG_WebDatabaseSearch", // 个人数据库搜索
    WebClickExractDetail = "LG_WebClickExractDetail", // 点击摘录详情
    WebDatabaseByPage = "LG_WebDatabaseByPage", // 个人数据库-按网页聚合
    WebDatabaseByPiece = "LG_WebDatabaseByPiece", // 个人数据库-按单条摘录
    GenerateOutline = "LG_GenerateOutline", // 生成概述大纲
    SummaryOriginalNotFound = "LG_SummaryOriginalNotFound", // 未定位到原文
    LikeUnvote_outline = "LG_LikeUnvote_outline", // 点赞点踩——概述大纲
    ComplainClick = "LG_ComplainClick", // 内容反馈（投诉举报）
    ApplyQuotaHint = "LG_ApplyQuotaHint", // 申请额度弹窗展示
    ApplyQuota = "LG_ApplyQuota", //申请额度
    ClickUploadPdf = "LG_ClickUploadPdf", // 点击pdf上传按钮
    UploadFile = "LG_UploadFile",
    Extract = "LG_Extract", // 摘录
    CloseToolbar = "LG_CloseToolbar", // 关闭浮动工具栏
    CloseFixedToolbar = "LG_CloseFixedToolbar", // 关闭固定工具栏
    CreateLabel = "LG_CreateLabel", //创建标签
    Web_ViewHomePage = "LG_Web_ViewHomePage", // 灵狗Web端-首页浏览
    Web_ViewRegOrLoginPage = "LG_Web_ViewRegOrLoginPage", //灵狗Web端-登陆/注册界面浏览
    Web_CopyURL = "LG_Web_CopyURL", // 灵狗Web端-粘贴URL
    Web_ClickUploadPdf = "LG_Web_ClickUploadPdf", //灵狗Web端-点击pdf上传按钮
    Web_ClickDatabase = "LG_Web_ClickDatabase", // 灵狗Web端-点击个人数据库
    Web_OpenDatabase = "LG_Web_OpenDatabase", // 灵狗Web端-打开个人数据库
    Web_DatabaseSearch = "LG_Web_DatabaseSearch", // 灵狗Web端-个人数据库搜索
    Web_ClickSeeAll = "LG_Web_ClickSeeAll", // 灵狗Web端-点击【查看全部】
    Web_ClickExtractDetail = "LG_Web_ClickExtractDetail", // 灵狗Web端-点击摘录详情
    Web_DatabaseByPage = "LG_Web_DatabaseByPage", // 灵狗Web端-个人数据库按网页聚合
    Web_DatabaseByPiece = "LG_Web_DatabaseByPiece", // 灵狗Web端-个人数据库按单条摘录
    Web_ClickArticle = "LG_Web_ClickArticle", // 灵狗Web端-点击文章1,2,3
    Web_ClickArticleReturn = "LG_Web_ClickArticleReturn", // 灵狗Web端-点击返回1,2,3
    Web_UploadFileFailure = "LG_Web_UploadFileFailure",
    Web_KeywordApply = "LG_Web_KeywordApply", // 焦点词应用
    Web_KeyInfoExposure = "LG_Web_KeyInfoExposure", // 关键信息曝光
    Web_KeyInfoReadall = "LG_Web_KeyInfoReadall", // 关键信息点击
    Web_Reader = "LG_Web_Reader", // 阅读器 - 时长
    LG_MultiFile_Position = "LG_MultiFile_Position", // 多文档原文定位
    LG_MultiFile_Summary_Cost = "LG_MultiFile_Summary_Cost", // 多文档总结耗时
    LG_Upload_MultiFile = "LG_Upload_MultiFile", // 多文档上传
    LG_MultiFile_Export = "LG_MultiFile_Export", // 多文档导出
    LG_MultiFile_Summary_Finish = "LG_MultiFile_Summary_Finish", // 多文档总结完成
  }

  export type TModuleName =
    | "HomePage"
    | "Login"
    | "Personal"
    | "Library"
    | "Read"
    | "PDFReader"
    | "Extract"
    | "Toolbar"
    | "Tag"
    | "Keyword"
    | "Analysis"
    | "Chat"
    | "Multi";

  export namespace SENSORS {
    export type Track = <T extends keyof BATCH.EventMap>(
      args: TrackArgs<T>[],
    ) => void;
    export type TrackArgs<T extends keyof EventMap> = T | EventMap[T];
  }

  export namespace NORMAL {
    export type Track = <T extends keyof BATCH.EventMap>(args_0: T) => void;
    export type TrackArgs<T extends keyof EventMap> = T;
  }

  export interface EventMap {
    [EventName.ChangeNameConfirm]: ChangeNameConfirmProperties;
    [EventName.ChangeName]: Record<string, never>;
    [EventName.WebRegisterResults]: WebRegisterResultsProperties;
    [EventName.WebLoginResults]: WebLoginResultsProperties;
    [EventName.WebClickDatabase]: object;
    [EventName.WebDatabaseSearch]: WebDatabaseSearchProperties;
    [EventName.WebClickExractDetail]: object;
    [EventName.WebDatabaseByPage]: object;
    [EventName.WebDatabaseByPiece]: object;
    // 阅读辅助相关
    [EventName.GenerateOutline]: GenerateOutlineProperties;
    [EventName.SummaryOriginalNotFound]: object;
    [EventName.LikeUnvote_outline]: LikeUnvote_outlineProperties;
    [EventName.ComplainClick]: object;
    [EventName.ApplyQuotaHint]: ApplyQuotaHintProperties;
    [EventName.ApplyQuota]: ApplyQuotaProperties;
    // PDF上传相关
    [EventName.ClickUploadPdf]: object;
    [EventName.UploadFile]: UploadFileProperties;
    [EventName.Extract]: ExtractProperties;
    [EventName.CloseFixedToolbar]: object;
    [EventName.CloseToolbar]: ToolbarClose;
    [EventName.CreateLabel]: CreateLabelProperties;
    [EventName.Web_ClickArticle]: Article;
    [EventName.Web_ClickArticleReturn]: Article;
    [EventName.Web_ViewHomePage]: object;
    [EventName.Web_ClickDatabase]: object;
    [EventName.Web_OpenDatabase]: object;
    [EventName.Web_CopyURL]: object;
    [EventName.Web_ClickUploadPdf]: object;
    [EventName.Web_ViewRegOrLoginPage]: ViewRegOrLoginPage;
    [EventName.Web_DatabaseSearch]: object;
    [EventName.Web_ClickSeeAll]: object;
    [EventName.Web_ClickExtractDetail]: object;
    [EventName.Web_DatabaseByPage]: object;
    [EventName.Web_DatabaseByPiece]: object;
    [EventName.Web_UploadFileFailure]: UploadFileFailure;
    [EventName.Web_KeywordApply]: KeywordApply;
    [EventName.Web_KeyInfoExposure]: object;
    [EventName.Web_KeyInfoReadall]: object;
    [EventName.Web_Reader]: WebReaderSentry;
    // 多文档相关
    [EventName.LG_MultiFile_Position]: {
      channel: "灵狗-插件" | "灵狗-web";
      entry_id: string;
      position_type: "segment" | "article"; // 段落定位 | 文章定位
    };
    [EventName.LG_MultiFile_Summary_Cost]: {
      channel: "灵狗-插件" | "灵狗-web";
      trace_id: string;
      start_time: string;
      merge_finish_time: string;
    };
    [EventName.LG_Upload_MultiFile]: {
      channel: "灵狗-插件" | "灵狗-web";
      file_type: string;
      file_num: number;
      file_ids: string;
    };
    [EventName.LG_MultiFile_Export]: {
      channel: "灵狗-插件" | "灵狗-web";
      entry_id: string;
      export_type: "word" | "xmind";
    };
    [EventName.LG_MultiFile_Summary_Finish]: {
      channel: "灵狗-插件" | "灵狗-web";
      entry_id: string;
    };
  }

  export interface WebReaderSentry {
    uid: string;
    trace_id: string;
    start_time: string;
    exit_time: string;
    article_id?: string;
    type?: string;
  }

  export interface UploadFileFailure {
    failure_reason: string;
  }

  export interface ViewRegOrLoginPage {
    source: string;
  }

  export interface Article {
    article_id: string;
  }

  export interface CreateLabelProperties {
    label_content: string;
    label_source: "摘录" | "个人数据库";
    label_content_type: "摘录" | "词" | "句" | "段落" | "大纲概述";
    is_success: boolean;
  }

  export interface GenerateOutlineProperties extends ApplyQuotaHintProperties {
    page_url: string;
    entry_type: string;
    is_limit: boolean;
    is_summary_block: boolean;
    failure_reason: string;
    is_frequent: boolean;
    is_outline_main_block: number;
    is_outline_branch_block: number;
    is_outline_child_block: number;
    is_outline_content_block: number;
    start_time: string;
    request_post_time: string;
    stop_time: string;
  }

  export interface ApplyQuotaHintProperties {
    button_name: string;
    button_position: string;
    content_source: string;
  }
  export interface ToolbarClose {
    is_permanent: boolean;
  }
  export interface ChangeNameConfirmProperties {
    failure_reason?: string;
  }
  export interface WebRegisterResultsProperties {
    is_success: boolean;
    failure_reason?: string;
    register_method?: string;
  }
  export interface WebLoginResultsProperties {
    is_success: boolean;
    failure_reason?: string;
    signin_method?: string;
  }

  export interface WebDatabaseSearchProperties {
    query: string;
    search_method: string;
    is_success: boolean;
    recommend_type?: string;
  }
  export interface ExtractProperties {
    words_count: number; // 摘录字数
    data_type: "文字" | "图片" | "图文";
    color: "黄色" | "红色" | "绿色"; // 摘录颜色
    failure_reason: string; // 失败原因
    is_success: boolean; // 是否成功
  }

  export type DownloadChannel =
    | "插件官网"
    | "谷歌商店"
    | "Edge商店"
    | "360商店";

  export interface LikeUnvote_outlineProperties {
    page_url: string;
    action_name: "点赞" | "点踩" | "取消点赞" | "取消点踩";
    button_position: string;
  }

  export interface ApplyQuotaProperties {
    button_position: "阅读界面" | "个人中心";
  }

  export interface UploadFileProperties {
    file_name: string;
    file_type: string;
    is_success: boolean;
    failure_reason?: string;
  }

  export interface KeywordApply {
    keywords: string;
    entry_id: string;
  }

  export interface KeyInfo {
    channel: number;
    entry_id: string;
    article_id: string;
  }
}
