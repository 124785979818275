import { useEffect, useRef, useState } from "react";

import { EventMessage as AllEventMessage } from "@/utils/event-bus";
import { ChartCard } from "../components/ChartCard";
import { useRelatedChartsStore } from "../../store";
import { MSearchModal, MSearchModalRef } from "./MSearchModal";
import {
  ChartElement,
  GetRelatedChartsResponseByAdapt,
  RecommendElement,
} from "@deeplang/shared";
import { MHalfScreen } from "./MHalfScreen";
import { MImageViewer } from "./image-viewer/MImageViewer";
import { EventMessage } from "../../constants";

interface MChartCardProps {
  allRelatedCharts: GetRelatedChartsResponseByAdapt[];
  elementList: (RecommendElement & ChartElement)[];
}
export function MChartCard(props: MChartCardProps) {
  const { allRelatedCharts, elementList } = props;

  const {
    eventBus,
    isCollapsed,
    activeElement,
    setActiveElement,
    chartListContainerRef,
    defaultElementListRef,
  } = useRelatedChartsStore();

  // 如果没有打开「相关图表」Tab，点击某一个链接 btn 时，需要携带初始化数据
  const [elementListToActive, setElementListToActive] = useState<
    (RecommendElement & ChartElement)[]
  >(defaultElementListRef.current || []);

  const searchModelRef = useRef<MSearchModalRef | null>(null);

  const handleClickSearchBtn = () => {
    searchModelRef.current?.show();
  };

  // 如果进入阅读面板，直接点击「相关图表」的 tab，则默认展示第一个
  useEffect(() => {
    if (elementList.length === 0 || allRelatedCharts.length === 0) {
      return;
    }

    // 先查找一下，所有的推荐列表中，第一个存在 element_id，且存在推荐图表的 item
    const relatedChartToElement = allRelatedCharts.find(
      (item) => item.element_id !== "" && item.recommend_elements.length !== 0,
    );

    if (!relatedChartToElement) {
      return;
    }
    const defaultElement = elementList.find(
      (element) => relatedChartToElement.element_id === element.element_id,
    );
    if (!defaultElement) {
      return;
    }

    // 但因为接口返回推荐列表需要较长时间，所以点击 pdf 里面的推荐按钮
    // 修改了 activeElement 之后，会跟此处抢占，所以这里如果发现已经存在了
    // activeElement 就不用重新 set

    if (activeElement) {
      return;
    }
    setActiveElement(defaultElement);
  }, [elementList, allRelatedCharts]);

  useEffect(() => {
    chartListContainerRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    for (let i = 0; i < allRelatedCharts.length; i++) {
      const allRelatedChart = allRelatedCharts[i];
      if (!allRelatedChart) {
        continue;
      }
      if (allRelatedChart.element_id === activeElement?.element_id) {
        setElementListToActive(allRelatedChart.recommend_elements);
        break;
      }
    }
  }, [activeElement, allRelatedCharts]);

  const handleSearch = ({ value = "" }) => {
    searchModelRef.current?.updateQuery(value);
    searchModelRef.current?.search(value);
  };

  const handleClickSourceChart = () => {
    eventBus.emit(EventMessage.ScrollToPage, {
      elementPos: activeElement?.element_pos,
    });
  };

  useEffect(() => {
    eventBus.on(AllEventMessage.SUG_SEARCH, handleSearch);

    return () => {
      eventBus.off(AllEventMessage.SUG_SEARCH, handleSearch);
    };
  }, []);

  return (
    <>
      <ChartCard
        relatedChartCount={elementListToActive.length}
        sourceChartName={activeElement?.name || ""}
        relatedCharts={elementListToActive}
        handleClickSearchBtn={handleClickSearchBtn}
        collapsed={isCollapsed}
        handleClickSourceChart={handleClickSourceChart}
      />
      <MSearchModal ref={searchModelRef} />
      <MHalfScreen />
      <MImageViewer />
    </>
  );
}
