import {
  ChartElement,
  RecommendElement,
  SearchRelatedElementsRequest,
} from "@deeplang/shared";
import { useRef, useState } from "react";
import { useApi } from "./useApi";
import { chartsAdapter } from "../../sdk";

export function useSearchElementList() {
  const [searchElementList, setSearchElementList] = useState<
    (RecommendElement & ChartElement)[]
  >([]);
  const searchElementListRef = useRef<(RecommendElement & ChartElement)[]>([]);
  const { searchRelatedElements } = useApi();
  const [isLoading, setIsLoading] = useState(false);

  const getSearchElementList = async (data: SearchRelatedElementsRequest) => {
    setIsLoading(true);
    try {
      const res = await searchRelatedElements(data);

      if (!res) {
        searchElementListRef.current = [];
        setSearchElementList([]);
        return;
      }

      const resByAdapt = res.map((item) => {
        return chartsAdapter.from(item);
      });
      searchElementListRef.current = resByAdapt;
      setSearchElementList(resByAdapt);
      setIsLoading(false);
    } catch {
      setSearchElementList([]);
      searchElementListRef.current = [];
      setIsLoading(false);
    }
  };

  return {
    searchElementList,
    getSearchElementList,
    searchElementListRef,
    setSearchElementList,
    isLoading,
  };
}
