import { scrollIntoView } from "./scroll-element-into-view";

export class SearchText {
  _multiKeywords = [];
  startTime = new Date().getTime();
  specificId = "";
  PDFViewerApplication = {
    pdfViewer: {},
  };
  pageNumbers = new Set();
  // 是否启用定位能力
  disabledLocate = true;

  /**
   *
   * @param {*} pdfViewer
   * @param {*} specificId 唯一 id，用于区分不同场景
   */
  constructor(
    specificId = "s",
    style = {
      color: "#3667E1",
      backgroundColor: "#DEEAFC",
    },
    disabledLocate = true,
  ) {
    this.specificId = specificId;
    // 改样式
    this.searchStyleRefine(style);
    this.disabledLocate = disabledLocate;
  }

  /**
   * @function searchStyleRefine
   * @description 优化搜索样式，调整搜索结果的视觉样式。
   */
  searchStyleRefine(style) {
    const { color, backgroundColor } = style;
    // 创建一个新的 <style> 元素
    var styleElement = document.createElement("style");
    document.head.appendChild(styleElement);

    // 获取样式表对象
    var styleSheet = styleElement.sheet;

    // 添加新的 CSS 规则2
    let textLayerCss = ".textLayer { z-index:100;opacity: 1 !important; }";
    // styleSheet.insertRule(textLayerCss, 0);

    let css0 = `
	.textLayer :not(.highlight) :not(.highlight-wrap) ::selection  {
		background:rgba(0, 0, 255, 0.3) ;
		color: white;
	}
	`;
    let css1 = `
	.textLayer ::selection{
		background:rgba(0, 0, 255, 0.3) !important;
		// color:transparent;
	}
	`;
    let css2 = `
	.textLayer .highlight {
		color: #3667E1 !important;
		font-weight:500;
		background-color:#DEEAFC !important;
	}
	`;
    let css3 = `
	.textLayer .highlight.selected {
		color: #3667E1 !important;
		background-color:#DEEAFC !important;
	}
	
	
		// styleSheet.insertRule(css0, 0)
		// styleSheet.insertRule(css1, 0)
		// styleSheet.insertRule(css2, 0)
		// styleSheet.insertRule(css3, 0)
	
	
	`;
    let css4 = `
	.${this.specificId}-highlight {
		color: ${color};
		// font-weight:500;
		background-color: ${backgroundColor};
			position:relative !important;
	}
	`;
    let css5 = `
	.${this.specificId}-highlight-textLayer {
		z-index:150;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		color:transparent;
		opacity: 1;
		line-height: 1.0;
		pointer-events: none;
		word-break: keep-all;
	
	}
	`;
    let css6 = `
	.${this.specificId}-highlight-textLayer span:not(.${this.specificId}-highlight){
		color: transparent;
		position: absolute;
		white-space: pre;
		-webkit-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
	}
	`;
    styleSheet.insertRule(css4, 0);
    styleSheet.insertRule(css5, 0);
    styleSheet.insertRule(css6, 0);

    // 修改语鲸样式
    let css10 = `
	.highlight-wrap {
			opacity: 0.3;
	}
	`;
    // styleSheet.insertRule(css10, 0)
  }

  /**
   * @function initMultiText
   * @description 初始化多关键字搜索，设置多个关键字并执行搜索并高亮处理。
   * @param {Array} keywords - 包含要搜索的关键字及其高亮样式的对象数组。
   *                            每个对象包含以下属性：
   *                              - word: 要搜索的关键字。
   *                              - color: 文本颜色。
   *                              - bgColor: 背景颜色。
   * 								keywords:[{word: '示例', color: '#FFFFFF', bgColor: '#000000'}]
   */
  initMultiText(pdfView, keywords, pageNumbers = new Set(), counter = "all") {
    this.PDFViewerApplication.pdfViewer = pdfView;
    this._multiKeywords = keywords;
    this.pageNumbers = pageNumbers;
    this.clearHighlightSearchText();
    if (pdfView && this._multiKeywords.length > 0) {
      this.searchKeywords(this._multiKeywords, counter);
    }
  }

  /**
   * @function refreshSearch
   * @description 刷新搜索结果，重新执行搜索关键字并进行高亮处理。
   */
  refreshSearch() {
    // clearHighlightSearchText()
    if (this._multiKeywords && this._multiKeywords.length) {
      this.searchKeywords(this._multiKeywords);
    }
  }

  /**
   * @function searchKeywords
   * @description 在 PDF 文档中搜索指定的关键字，并对匹配的关键字进行高亮处理
   * @param {Array} keywords - 包含要搜索的关键字及其高亮样式的对象数组。
   *                            每个对象包含以下属性：
   *                              - word: 要搜索的关键字。
   *                              - color: 文本颜色。
   *                              - bgColor: 背景颜色。
   * 								keywords:[{word: '示例', color: '#FFFFFF', bgColor: '#000000'}]
   * @param {counter} counter - 确定需要展示的数量
   */
  searchKeywords(keywords, counter) {
    // console.log('===============searchKeywords============')
    this.startTime = new Date().getTime();
    for (
      var i = 0;
      i < this.PDFViewerApplication.pdfViewer._pages.length;
      i++
    ) {
      const page = this.PDFViewerApplication.pdfViewer._pages[i];
      var textLayer = page.textLayer;
      if (!this.pageNumbers.has(page.id) && this.pageNumbers.size !== 0) {
        continue;
      }
      const viewerContainer = document.getElementById("pdf-view-container");

      let pageDiv = viewerContainer.querySelector(
        '.page[data-page-number="' + page.id + '"]',
      );
      if (!pageDiv) {
        continue;
      }
      // if(!pageDiv){
      // 	console.log('pageDiv page',i+1)
      // }

      let isRendered =
        pageDiv.getAttribute("highlight-keywords-rendered") === "true";
      let pageTextLayer = pageDiv.querySelector(".textLayer");
      if (isRendered) {
        continue;
      }

      let highlightDiv = pageDiv.querySelector(
        `.${this.specificId}-highlight-textLayer`,
      );
      if (!highlightDiv) {
        highlightDiv = document.createElement("div");
      }
      highlightDiv.setAttribute(
        "class",
        `${this.specificId}-highlight-textLayer`,
      );
      highlightDiv.setAttribute("style", pageDiv.getAttribute("style"));
      pageDiv.appendChild(highlightDiv);
      // 检查 textLayer 是否存在以及是否具有 textContentItemsStr 属性
      if (textLayer && textLayer.textContentItemsStr && pageTextLayer) {
        let textContentItemsStr = textLayer.textContentItemsStr;
        let fullTextContent = textContentItemsStr.join("");
        let textDivs = textLayer.textDivs;

        // console.log('page', (i + 1), 'has textContentItems fullTextContent length', fullTextContent.length)
        if (fullTextContent.length) {
          // 存储关键字及其位置信息的对象数组
          var keywordPositionList = [];

          // 打印出每个关键字的存在索引范围和关键字本身
          // console.log('Page ' + (i + 1) + ':');
          keywords.forEach(function (keywordObj) {
            var keyword = keywordObj.word;
            var keywordLowerCase = keyword.toLowerCase();
            var keywordLength = keyword.length;
            var index = fullTextContent.toLowerCase().indexOf(keywordLowerCase);

            // 找到所有关键字的位置
            while (index !== -1) {
              keywordPositionList.push({
                keyword,
                color: keywordObj.color,
                bgColor: keywordObj.bgColor,
                start: index,
                end: index + keywordLength - 1,
              });
              index = fullTextContent
                .toLowerCase()
                .indexOf(keywordLowerCase, index + keywordLength);
            }
          });

          // 打印关键字的存在索引范围和关键字本身
          if (keywordPositionList.length > 0) {
            keywordPositionList = keywordPositionList.sort((a, b) => {
              return a.start - b.start;
            });

            // 如果不是查找所有，就只保留第一个
            keywordPositionList =
              counter === "all"
                ? keywordPositionList
                : [keywordPositionList[0]];
            // 处理高亮
            this.handleHighlight(
              textContentItemsStr,
              textDivs,
              keywordPositionList,
              highlightDiv,
            );
            // console.log('page', (i + 1), 'searchKeywords', new Date().getTime() - startTime, 'ms')
          } else {
            // console.log('No keywords found.');
          }

          pageDiv.setAttribute("highlight-keywords-rendered", "true");
        } else {
          pageDiv.setAttribute("highlight-keywords-rendered", "false");
        }
      } else {
        pageDiv.setAttribute("highlight-keywords-rendered", "false");
      }
    }
  }

  /**
   * @function handleHighlight
   * @description 在文本内容中对指定的关键字进行高亮处理。
   * @param {string[]} textContentItemsStr - 文本内容项的数组。
   * @param {HTMLElement[]} textDivs - 表示文本区域的 HTML 元素数组。
   * @param {Object[]} keywordPositionList - 包含关键字位置和样式信息的对象数组。
   */
  handleHighlight(
    textContentItemsStr,
    textDivs,
    keywordPositionList,
    highlightDiv,
  ) {
    let currentStart = 0;
    let keywordIndex = 0;
    let drawLength = 0;
    let postfix = "</span>";
    for (
      var i = 0;
      i < textContentItemsStr.length &&
      keywordIndex < keywordPositionList.length;
      i++
    ) {
      let length = textContentItemsStr[i].length;
      let { bgColor, color } = keywordPositionList[keywordIndex];

      if (
        this.hasOverlap(
          currentStart,
          currentStart + length - 1,
          keywordPositionList[keywordIndex].start,
          keywordPositionList[keywordIndex].end,
        )
      ) {
        let overlapList = this.filterOverlapPositions(
          currentStart,
          currentStart + length - 1,
          keywordPositionList,
        );
        // console.log(currentStart,"overlapList",overlapList)
        for (var j = 0; j < overlapList.length; j++) {
          let tempDiv = textDivs[i].cloneNode(true);
          tempDiv.innerHTML = this.modifyString(
            currentStart,
            overlapList[j],
            textContentItemsStr[i],
          );
          // console.log(currentStart,j,'innerHTML',tempDiv.innerHTML )
          highlightDiv.appendChild(tempDiv);
        }
      }

      currentStart += length;
      if (
        keywordPositionList[keywordIndex] &&
        currentStart > keywordPositionList[keywordIndex].end
      ) {
        keywordIndex = this.getNextIndex(
          keywordIndex,
          currentStart,
          keywordPositionList,
        );
      }
    }

    if (!this.disabledLocate) {
      const { firstChild } = highlightDiv;
      scrollIntoView(firstChild, { top: -100 });
    }
  }

  /**
   * 查找列表中下一个符合条件的索引位置
   * @param {number} currentIndex - 当前索引位置
   * @param {number} currentStart - 当前起始位置
   * @param {Array} list - 包含对象的数组
   * @returns {number} - 下一个符合条件的索引位置，如果没有找到则返回 -1
   */
  getNextIndex(currentIndex, currentStart, list) {
    // 遍历列表中的元素
    for (let i = currentIndex + 1; i < list.length; i++) {
      // 如果列表中下一个元素的 start 属性大于当前起始位置，则返回该元素的索引位置
      if (list[i].start >= currentStart || list[i].end >= currentStart) {
        return i;
      }
    }
    // 如果没有找到符合条件的索引，则返回 当前索引+1
    return currentIndex + 1;
  }

  /**
   * 根据给定的起始和结束位置，过滤列表中与之重叠的位置项
   * @param {number} start - 给定的起始位置
   * @param {number} end - 给定的结束位置
   * @param {Array} list - 包含位置项对象的数组
   * @returns {Array} - 过滤后的与给定位置重叠的位置项数组
   */
  filterOverlapPositions(start, end, list) {
    return list.filter((e) => {
      return this.hasOverlap(start, end, e.start, e.end);
    });
  }

  /**
   * 根据给定的关键词位置信息修改字符串，并返回修改后的字符串和剩余字符串部分以及新的起始位置
   * @param {number} currentStart - 当前起始位置
   * @param {Object} keywordPositionItem - 包含关键词位置信息的对象
   * @param {number} keywordPositionItem.start - 关键词起始位置
   * @param {number} keywordPositionItem.end - 关键词结束位置
   * @param {string} keywordPositionItem.color - 关键词颜色
   * @param {string} keywordPositionItem.bgColor - 关键词背景颜色
   * @param {string} str - 原始字符串
   * @returns {Array} - 修改后的字符串、剩余字符串部分以及新的起始位置的数组
   */
  modifyString(currentStart, keywordPositionItem, str) {
    const { start, end, color, bgColor } = keywordPositionItem;
    // 若 end 小于 currentStart 或 currentStart + str.length - 1 小于 start，则返回原始字符串和空的剩余字符串
    if (end < currentStart || currentStart + str.length - 1 < start) {
      return [str, "", currentStart + str.length - 1];
    }

    // 计算最终的start和end
    const newStart = Math.max(currentStart, start) - currentStart; // 确保start不小于currentStart
    const newEnd = Math.min(currentStart + str.length - 1, end) - currentStart; // 确保end不超过currentStart+str.length-1

    // 构造前缀和后缀
    const prefix = `<span class="${this.specificId}-highlight" style="background-color:${bgColor};color:${color}">`;
    const postfix = "</span>";

    // 处理前缀和后缀添加
    const modifiedStr =
      str.substring(0, newStart) +
      prefix +
      str.substring(newStart, newEnd + 1) +
      postfix;
    const remainingStr = str.substring(newEnd + 1);

    return modifiedStr + remainingStr;
  }

  /**
   * @function hasOverlap
   * @description 判断两个区间是否有交叉。
   */
  hasOverlap(start1, end1, start2, end2) {
    return Math.max(start1, start2) <= Math.min(end1, end2);
  }

  /**
   * 将给定的前缀和后缀添加到字符串的指定部分，并返回修改后的字符串以及剩余的字符串部分。
   * @param {number} currentStart - 当前起始位置。
   * @param {{keyword: string, start: number, end: number, color: string, bgColor: string}} keywordPositionItem - 包含关键字位置信息的对象。
   * @param {string} str - 原始字符串。
   * @returns {[string, string]} - 修改后的字符串以及剩余的字符串部分。
   */
  addPrefixAndPostfix(currentStart, keywordPositionItem, str) {
    const { start, end, color, bgColor } = keywordPositionItem;
    // 若 end 小于 currentStart 或 currentStart + str.length - 1 小于 start，则返回原始字符串和空的剩余字符串
    if (end < currentStart || currentStart + str.length - 1 < start) {
      return [str, "", currentStart + str.length - 1];
    }

    // 计算最终的start和end
    const newStart = Math.max(currentStart, start) - currentStart; // 确保start不小于currentStart
    const newEnd = Math.min(currentStart + str.length - 1, end) - currentStart; // 确保end不超过currentStart+str.length-1

    // 构造前缀和后缀
    const prefix = `<span class="${this.specificId}-highlight" style="background-color:${bgColor};color:${color}">`;
    const postfix = "</span>";

    // 处理前缀和后缀添加
    const modifiedStr =
      str.substring(0, newStart) +
      prefix +
      str.substring(newStart, newEnd + 1) +
      postfix;
    const remainingStr = str.substring(newEnd + 1);
    // console.log('addPrefixAndPostfix',newStart,str[newStart], newEnd,str[newEnd])
    // console.log("modifiedStr", modifiedStr)
    // console.log("remainingStr", remainingStr)

    return [modifiedStr, remainingStr, newEnd];
  }

  /**
   * @function clearHighlightSearchText
   * @description 清除搜索高亮效果，将页面中所有搜索高亮元素移除，并重置页面的高亮渲染状态。
   */
  clearHighlightSearchText() {
    // console.log('clearHighlightSearchText')
    let viewer = document.getElementById("pdf-view-container");
    if (viewer) {
      // let highlights = document.querySelectorAll('.s-highlight');
      // highlights.forEach(function(element) {
      // 	var parent = element.parentNode;
      // 	if (parent && element.firstChild instanceof Node) {
      // 		parent.replaceChild(element.firstChild, element);
      // 		parent.normalize(); // Merge adjacent text nodes
      // 	}
      // });
      let pageDivs = viewer.querySelectorAll(".page");
      for (var i = 0; i < pageDivs.length; i++) {
        pageDivs[i].setAttribute("highlight-keywords-rendered", false);
      }
      // 获取所有具有 .highlight-textLayer 类的元素
      const highlightElements = document.querySelectorAll(
        `.${this.specificId}-highlight-textLayer`,
      );

      // 遍历所有匹配的元素并删除它们
      highlightElements.forEach((element) => {
        element.parentNode.removeChild(element);
      });
    }
  }

  /**
   * @function unloadSearchText
   * @description 卸载搜索文本，清除搜索高亮效果并清空保存的多关键字搜索数组。
   */
  unloadSearchText() {
    this._multiKeywords = [];
    this.clearHighlightSearchText();
  }

  /**
   * @function searchText
   * @description 在 PDF 文档中执行原声pdf.js搜索操作，查找指定的搜索词并定位到匹配的位置。
   * @param {string} searchTerm - 要搜索的词语。
   */
  searchText(searchTerm) {
    // 获取 PDF.js 的渲染器实例
    var pdfViewer = this.PDFViewerApplication.pdfViewer;
    // console.log('pdfViewer.findController', pdfViewer.findController)
    // 获取搜索控制器实例
    var findController = pdfViewer.findController;
    if (findController) {
      // 执行搜索
      findController.executeCommand("find", {
        query: searchTerm,
      });
    }
  }

  /**
   * @constant {number} _s
   * @description 用于周期性检查 PDF 文档加载状态的定时器。
   */
  _s = window.setInterval(() => {
    const pdfViewerApplication = this.PDFViewerApplication;
    const pdfViewer = this.PDFViewerApplication?.pdfViewer;
    if (pdfViewer && pdfViewer.eventBus) {
      window.clearInterval(this._s);
      //通过pdfViewer.eventBus来监听各种事件;
      pdfViewer.eventBus.on("pagechanging", (e) => {
        // console.log('pagechanging', e);
        let _pageChangingT = setTimeout(() => {
          clearTimeout(_pageChangingT);
          this.refreshSearch();
        }, 50);
      });
      pdfViewer.eventBus.on("scalechanging", (e) => {
        // console.log('scalechanging AAAA', e);
        this.clearHighlightSearchText();
      });
      pdfViewer.eventBus.on("textlayerrendered", (e) => {
        // console.log('textlayerrendered AAAA', e);
        this.refreshSearch();
      });

      //直接获取总页数
      // console.log(pdfViewerApplication.pagesCount);
      //直接获取当前页数
      // console.log(pdfViewerApplication.page);
    }
  }, 200);
}
