import { SummaryType } from '../constants';
import { getAuthor } from '../util';

import { Highlight } from '../core';

// 从页面中提取内容
function extractPageContent(type: SummaryType = SummaryType.Article, length: number = 50) {
  const { title = '' } = document;
  const h1 = document.querySelector('h1');
  const { href: url } = window.location;
  const originalContent = document.body.innerText.slice(0, length);
  const highlight = new Highlight();
  if (type === SummaryType.Article) {
    return {
      title: h1?.textContent?.trim() || title.trim(),
      url,
      content: document.documentElement.outerHTML,
      author: getAuthor(),
      originalContent,
      metaInfo: JSON.stringify({}),
    };
  }
  const selection = window.getSelection();
  if (selection?.isCollapsed === false) {
    const text = selection?.toString();
    const range = selection?.getRangeAt(0);
    return {
      title,
      url,
      content: text,
      author: getAuthor(),
      originalContent,
      metaInfo: JSON.stringify(highlight.getSerializeInfo(range)),
    };
  }
  return {
    title,
    url,
    content: '',
    author: getAuthor(),
    originalContent,
    metaInfo: JSON.stringify({}),
  };
}

export default extractPageContent;
