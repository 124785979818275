import { PDFDocumentProxy } from "pdfjs-dist";
import { PDFViewer } from "pdfjs-dist/web/pdf_viewer";

import { usePdfReaderStore } from "../store";
import { NoRender } from "./no-render";
import { PdfViewer } from "./pdf-viewer";
import styles from "./reader.module.css";
import { FileViolation } from "./file-violation";
import { PDFRenderLoading } from "./loading";
import { ViewPriority } from "@deeplang/shared";

interface ReaderProps {
  loadMaterialFail: boolean;
  isViolation: boolean;
  isLoading: boolean;
  pdfDocument: PDFDocumentProxy | null;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  pdfViewRef: React.MutableRefObject<PDFViewer | null>;
}

export function Reader(props: ReaderProps) {
  const { options, setCurrentPage } = usePdfReaderStore();
  const { toolbar: isShowToolbar } = options;
  const {
    loadMaterialFail,
    isLoading,
    isViolation,
    pdfDocument,
    pdfViewRef,
    containerRef,
  } = props;

  const handleClick = () => {
    try {
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  let PDFComponent: JSX.Element;

  if (loadMaterialFail) {
    PDFComponent = <NoRender handleClick={handleClick}></NoRender>;
  } else if (isViolation) {
    PDFComponent = <FileViolation></FileViolation>;
  } else {
    PDFComponent = <></>;
  }

  return (
    <div className="w-full h-full">
      {isLoading && <PDFRenderLoading />}

      <div
        className={`${styles.otherComponentContainer} bg-[#fbfbfb]`}
        style={{
          top: isShowToolbar ? "60px" : "0",
          zIndex:
            loadMaterialFail || isViolation || isLoading
              ? ViewPriority.ShowPDFOtherComponents
              : ViewPriority.HidePDFOtherComponents,
        }}
      >
        {PDFComponent}
      </div>

      <PdfViewer
        pdfDocument={pdfDocument}
        containerRef={containerRef}
        pdfViewRef={pdfViewRef}
        setCurrentPage={setCurrentPage}
      ></PdfViewer>
    </div>
  );
}
