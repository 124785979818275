import { camel } from "./util";
import EventEmitter from "./event.emitter";
import HighlightSource from "./source";
import { ERROR } from "./types";

export const ID_DIVISION = ";";
export const DATASET_IDENTIFIER = "highlight-id";
export const CAMEL_DATASET_IDENTIFIER = camel(DATASET_IDENTIFIER);
export const DATASET_IDENTIFIER_EXTRA = "highlight-id-extra";
export const CAMEL_DATASET_IDENTIFIER_EXTRA = camel(DATASET_IDENTIFIER_EXTRA);
export const DATASET_SPLIT_TYPE = "highlight-split-type";
export const CAMEL_DATASET_SPLIT_TYPE = camel(DATASET_SPLIT_TYPE);

export const ROOT_IDX = -2;
export const UNKNOWN_IDX = -1;
export const INTERNAL_ERROR_EVENT = "error";

export const getDefaultOptions = () => ({
  root: document,
  excludeSelectors: [],
  wrapTag: "span",
  verbose: false,
  style: {
    className: "highlight-wrap",
  },
  bgColors: ["#FEE78C"],
});

export const getStylesheet = (colors: string[]) => {
  console.log(colors);
  const style = `
  .highlight-wrap {
    cursor: pointer;
  }.highlight-wrap.highlight-color-1 {
    background: #E3B55C;
  }
  .highlight-wrap.highlight-color-2 {
    background: #EF716F;
  }
  .highlight-wrap.highlight-color-3 {
    background: #3AD37B;
  }
  `;

  return style;
};

export const STYLESHEET_ID = "highlight-style";

interface EventHandlerMap {
  [key: string]: (...args: any[]) => void;
  error: (data: {
    type: ERROR;
    detail?: HighlightSource;
    error?: unknown;
  }) => void;
}

export const eventEmitter = new EventEmitter<EventHandlerMap>();
