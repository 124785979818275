import {
  getHighlightsByRoot,
  isHighlightWrapNode,
  removeAllClass,
  hasClass,
  addClass,
  each,
} from "./dom";
import HighlightRange from "./range";
import HighlightSource from "./source";
import {
  PainterOptions,
  SelectedNode,
  SelectedNodeType,
  SplitType,
  DOMNode,
  HookMap,
  ERROR,
} from "./types";
import {
  CAMEL_DATASET_IDENTIFIER,
  CAMEL_DATASET_IDENTIFIER_EXTRA,
  DATASET_IDENTIFIER,
  DATASET_IDENTIFIER_EXTRA,
  DATASET_SPLIT_TYPE,
  ID_DIVISION,
  STYLESHEET_ID,
  INTERNAL_ERROR_EVENT,
  getStylesheet,
  eventEmitter,
} from "./const";

const initDefaultStylesheet = (colors: string[]) => {
  const styleId = STYLESHEET_ID;

  let style: HTMLStyleElement = document.getElementById(
    styleId,
  ) as HTMLStyleElement;

  if (!style) {
    const cssNode = document.createTextNode(getStylesheet(colors));

    style = document.createElement("style");
    style.id = styleId;
    style.appendChild(cssNode);
    document.head.appendChild(style);
  }

  return style;
};

const isNodeEmpty = (el: Node | null) => {
  return !el || !el.textContent;
};

const isMatchSelector = (node: HTMLElement, selector: string): boolean => {
  if (!node) {
    return false;
  }

  if (/^\./.test(selector)) {
    const className = selector.replace(/^\./, "");

    return node && hasClass(node, className);
  } else if (/^#/.test(selector)) {
    const id = selector.replace(/^#/, "");

    return node?.id === id;
  }

  const tagName = selector.toUpperCase();

  return node?.tagName === tagName;
};

const getNodesIfSameStartEnd = (
  startNode: Text,
  startOffset: number,
  endOffset: number,
  excludeSelectors?: string[],
) => {
  let el = startNode as Node;

  const isExcluded = (el: HTMLElement) => {
    return excludeSelectors?.some((selector) => isMatchSelector(el, selector));
  };

  while (el) {
    if (el.nodeType === Node.ELEMENT_NODE && isExcluded(el as HTMLElement)) {
      return [];
    }

    el = el.parentNode as Node;
  }

  startNode.splitText(startOffset);
  const passedNode = startNode.nextSibling as Text;
  passedNode.splitText(endOffset - startOffset);
  return [
    {
      node: passedNode,
      type: SelectedNodeType.text,
      splitType: SplitType.both,
    },
  ];
};

const getSelectedNodes = (
  root: Document | HTMLElement,
  start: DOMNode,
  end: DOMNode,
  excludeSelectors?: string[],
): SelectedNode[] => {
  const startNode = start.node;
  const endNode = end.node;
  const startOffset = start.offset;
  const endOffset = end.offset;

  if (startNode === endNode && startNode instanceof Text) {
    return getNodesIfSameStartEnd(
      startNode,
      startOffset,
      endOffset,
      excludeSelectors,
    );
  }

  const nodeStack: (ChildNode | Document | HTMLElement | Text)[] = [root];
  const selectedNodes: SelectedNode[] = [];
  const isExcluded = (el: HTMLElement) => {
    return excludeSelectors?.some((selector) => isMatchSelector(el, selector));
  };

  let withinSelectedRange = false;
  let curNode: Node | null = null;
  while ((curNode = nodeStack.pop() || null)) {
    if (
      curNode.nodeType === Node.ELEMENT_NODE &&
      isExcluded(curNode as HTMLElement)
    ) {
      continue;
    }

    const children = curNode.childNodes;
    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i];
      if (!child) {
        continue;
      }
      nodeStack.push(child);
    }

    if (curNode === startNode) {
      if (curNode.nodeType === Node.TEXT_NODE) {
        (curNode as Text).splitText(startOffset);
        const node = curNode.nextSibling as Text;
        selectedNodes.push({
          node,
          type: SelectedNodeType.text,
          splitType: SplitType.head,
        });
      }
      withinSelectedRange = true;
    } else if (curNode === endNode) {
      if (curNode.nodeType === Node.TEXT_NODE) {
        const node = curNode as Text;
        node.splitText(endOffset);
        selectedNodes.push({
          node,
          type: SelectedNodeType.text,
          splitType: SplitType.tail,
        });
      }
      break;
    } else if (withinSelectedRange && curNode.nodeType === Node.TEXT_NODE) {
      selectedNodes.push({
        node: curNode as Text,
        type: SelectedNodeType.text,
        splitType: SplitType.none,
      });
    }
  }

  return selectedNodes;
};

const wrapNewNode = (
  selected: SelectedNode,
  range: HighlightRange,
  className: string[] | string,
  wrapTag: string,
): HTMLElement => {
  const wrap = document.createElement(wrapTag);
  addClass(wrap, className);
  wrap.appendChild(selected.node.cloneNode(false));
  selected.node.parentNode?.replaceChild(wrap, selected.node);

  wrap.setAttribute(`data-${DATASET_IDENTIFIER}`, `${range.id}`);
  wrap.setAttribute(`data-${DATASET_IDENTIFIER_EXTRA}`, "");
  wrap.setAttribute(`data-${DATASET_SPLIT_TYPE}`, selected.splitType);
  return wrap;
};

const wrapPartialNode = (
  selected: SelectedNode,
  range: HighlightRange,
  className: string | string[],
  wrapTag: string,
) => {
  const wrap = document.createElement(wrapTag);
  const parent = selected.node.parentNode as HTMLElement;
  const previous = selected.node.previousSibling;
  const next = selected.node.nextSibling;
  const fragment = document.createDocumentFragment();
  const parentId = parent.dataset[DATASET_IDENTIFIER];
  const parentExtra = parent.dataset[DATASET_IDENTIFIER_EXTRA] || "";
  const parentExtraInfo = parentExtra
    ? parentId + ID_DIVISION + parentExtra
    : parentId;

  wrap.setAttribute(`data-${DATASET_IDENTIFIER}`, `${range.id}`);
  wrap.setAttribute(`data-${DATASET_IDENTIFIER_EXTRA}`, parentExtraInfo ?? "");
  wrap.appendChild(selected.node.cloneNode(false));

  let headSplit = false;
  let tailSplit = false;
  let splitType: SplitType = SplitType.none;

  if (previous) {
    const span = parent.cloneNode(false) as HTMLElement;
    span.textContent = previous.textContent;
    fragment.appendChild(span);
    headSplit = true;
  }

  const classNameList: string[] = [];
  if (Array.isArray(className)) {
    classNameList.push(...className);
  } else {
    classNameList.push(className);
  }
  addClass(wrap, [...new Set(classNameList)]);
  fragment.appendChild(wrap);

  if (next) {
    const span = parent.cloneNode(false) as HTMLElement;
    span.textContent = next.textContent;
    fragment.appendChild(span);
    tailSplit = true;
  }

  if (headSplit && tailSplit) {
    splitType = SplitType.both;
  } else if (headSplit) {
    splitType = SplitType.head;
  } else if (tailSplit) {
    splitType = SplitType.tail;
  } else {
    splitType = SplitType.none;
  }

  wrap.setAttribute(`data-${DATASET_SPLIT_TYPE}`, splitType);
  parent.parentNode?.replaceChild(fragment, parent);

  return wrap;
};

const wrapOverlapNode = (
  selected: SelectedNode,
  range: HighlightRange,
  className: string | string[],
) => {
  const wrap = selected.node.parentNode as HTMLElement;
  removeAllClass(wrap);
  addClass(wrap, className);

  const dataset = wrap.dataset;
  const formerId = dataset[DATASET_IDENTIFIER];
  dataset[CAMEL_DATASET_IDENTIFIER] = range.id;
  dataset[CAMEL_DATASET_IDENTIFIER_EXTRA] = dataset[
    CAMEL_DATASET_IDENTIFIER_EXTRA
  ]
    ? formerId + ID_DIVISION + dataset[CAMEL_DATASET_IDENTIFIER_EXTRA]
    : formerId;

  return wrap;
};

const wrapHighlight = (
  selected: SelectedNode,
  range: HighlightRange,
  className: string[] | string,
  wrapTag: string,
) => {
  const parent = selected.node.parentNode as HTMLElement;
  const previous = selected.node.previousSibling;
  const next = selected.node.nextSibling;
  let wrap: HTMLElement;

  if (!isHighlightWrapNode(parent)) {
    wrap = wrapNewNode(selected, range, className, wrapTag);
  } else if (
    isHighlightWrapNode(parent) &&
    (!isNodeEmpty(previous) || !isNodeEmpty(next))
  ) {
    wrap = wrapPartialNode(selected, range, className, wrapTag);
  } else {
    wrap = wrapOverlapNode(selected, range, className);
  }

  return wrap;
};

const normalizeSiblingText = (node: Node | null, isNext = true) => {
  if (!node || node.nodeType !== Node.TEXT_NODE || !node.nodeValue) {
    return;
  }

  const sibling = isNext ? node.nextSibling : node.previousSibling;
  if (!sibling || sibling.nodeType !== Node.TEXT_NODE || !sibling.nodeValue) {
    return;
  }

  const text = sibling.nodeValue;
  node.nodeValue = isNext ? node.nodeValue + text : text + node.nodeValue;
  sibling.parentNode?.removeChild(sibling);
};

class Painter {
  options: PainterOptions;
  hooks: HookMap;
  constructor(options: PainterOptions, hooks: HookMap) {
    this.options = Object.assign({}, options);

    this.hooks = hooks;
    initDefaultStylesheet(this.options.bgColors || []);
  }

  highlightRange(range: HighlightRange): HTMLElement[] {
    if (!range.frozen) {
      throw new Error("Cannot highlight unfrozen range");
    }

    const { root, className, excludeSelectors } = this.options;
    const selectedNodes = getSelectedNodes(
      root,
      range.startNode,
      range.endNode,
      excludeSelectors,
    );

    return selectedNodes.map((selectedNode) => {
      const node = wrapHighlight(
        selectedNode,
        range,
        className,
        this.options.wrapTag,
      );
      return node;
    });
  }

  highlightSource(
    sources: HighlightSource | HighlightSource[],
    pageNumber: number,
  ): HighlightSource[] {
    if (!Array.isArray(sources)) {
      sources = [sources];
    }

    const renderedSources: HighlightSource[] = [];

    sources.forEach((source) => {
      if (!(source instanceof HighlightSource)) {
        eventEmitter.emit(INTERNAL_ERROR_EVENT, {
          type: ERROR.SOURCE_TYPE_ERROR,
        });
        return;
      }

      const root =
        document.querySelector<HTMLDivElement>(
          `div[data-page-number="${pageNumber}"]`,
        ) || this.options.root;
      const range = source.deserialize(root as Element);
      const nodes = this.highlightRange(range);
      if (nodes.length) {
        renderedSources.push(source);
      } else {
        eventEmitter.emit(INTERNAL_ERROR_EVENT, {
          type: ERROR.HIGHLIGHT_SOURCE_NONE_RENDER,
          detail: source,
        });
      }
    });

    return renderedSources;
  }

  removeHighlight(id: string): boolean {
    const regExp = new RegExp(
      `(${id}\\${ID_DIVISION}|\\${ID_DIVISION}?${id}$)`,
    );
    const { wrapTag } = this.options;
    const spans = document.querySelectorAll<HTMLElement>(
      `${wrapTag}[data-${DATASET_IDENTIFIER}]`,
    );

    // nodes to remove
    const toRemoved: HTMLElement[] = [];
    const toUpdateId: HTMLElement[] = [];
    const toUpdateExtra: HTMLElement[] = [];
    spans.forEach((span) => {
      const dataset = span.dataset;
      const hid = dataset[CAMEL_DATASET_IDENTIFIER];
      let extraId = dataset[CAMEL_DATASET_IDENTIFIER_EXTRA];
      if (extraId?.includes("undefined")) extraId = "";

      if (hid === id && !extraId) {
        toRemoved.push(span);
      } else if (hid === id) {
        toUpdateId.push(span);
      } else if (hid !== id && regExp.test(extraId as string)) {
        toUpdateExtra.push(span);
      }
    });
    toRemoved.forEach((span) => {
      const parent = span.parentNode as HTMLElement;
      const fragment = document.createDocumentFragment();
      each(span.childNodes, (node) => {
        fragment.appendChild(node.cloneNode(false));
      });
      const previous = span.previousSibling;
      const next = span.nextSibling;
      parent.replaceChild(fragment, span);
      normalizeSiblingText(previous, true);
      normalizeSiblingText(next, false);

      // call hooks Remove.UpdateNodes
    });
    toUpdateId.forEach((span) => {
      const dataset = span.dataset;
      const ids =
        dataset[CAMEL_DATASET_IDENTIFIER_EXTRA]?.split(ID_DIVISION).filter(
          (i) => i,
        ) || [];
      const newId = ids.shift();

      const overlapSpan = document.querySelector<HTMLElement>(
        `${wrapTag}[data-${DATASET_IDENTIFIER}="${newId}"]`,
      );
      if (overlapSpan) {
        removeAllClass(span);
        addClass(span, [...Array.from(overlapSpan.classList)]);
      }

      dataset[CAMEL_DATASET_IDENTIFIER] = newId;
      dataset[CAMEL_DATASET_IDENTIFIER_EXTRA] = ids.join(ID_DIVISION);

      // hooks Remove.UpdateNodes (id, span, 'id-update')
    });
    toUpdateExtra.forEach((span) => {
      const extraId = span.dataset[CAMEL_DATASET_IDENTIFIER_EXTRA];
      span.dataset[CAMEL_DATASET_IDENTIFIER_EXTRA] =
        extraId?.replace(regExp, "") || "";
      // hooks Remove.UpdateNodes (id, span, 'extra-update')
    });

    return toRemoved.length + toUpdateId.length + toUpdateExtra.length !== 0;
  }

  removeAllHighlight(): void {
    const { wrapTag, root } = this.options;

    const spans = getHighlightsByRoot(root, wrapTag);
    spans.forEach((span) => {
      const parent = span.parentNode as HTMLElement;
      const fragment = document.createDocumentFragment();
      each(span.childNodes, (node) => {
        fragment.appendChild(node.cloneNode(false));
      });
      parent.replaceChild(fragment, span);
    });
  }
}

export default Painter;
