import { ReactNode, createContext } from "react";

import { Maybe } from "@deeplang/shared";
import { IPdfReader } from "../type";
import useStore from "./all-store";

export interface Options {
  children?: ReactNode;
  defaultParams: IPdfReader;
}

export const PdfReaderContext =
  createContext<Maybe<IPdfReader & ReturnType<typeof useStore>>>(null);

export const PdfReaderProvider = (options: Options) => {
  const { children, defaultParams } = options;
  const store = useStore(defaultParams);

  return (
    <PdfReaderContext.Provider value={{ ...defaultParams, ...store }}>
      {children}
    </PdfReaderContext.Provider>
  );
};
