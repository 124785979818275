import { AxiosResponse } from "axios";

import { DownloadPdfFileRequest, IFileRenderData } from "../type";

type TExecutor = (
  params: DownloadPdfFileRequest,
) => Promise<AxiosResponse<BinaryData>>;

export const FileDownloadExecutor = {
  latestFileId: "",
  executorMap: new Map<string, TExecutor>(),
  setLatestFileId: (value: string) => {
    FileDownloadExecutor.latestFileId = value;
  },
  addExecutor: (
    fileId: string,
    fn: TExecutor,
    fileRender: (fileContent: BinaryData, data: IFileRenderData) => void,
    data: IFileRenderData,
    handleError: (e: Error) => void,
  ) => {
    const executor = FileDownloadExecutor.executorMap.get(fileId);

    if (executor) {
      return;
    }

    FileDownloadExecutor.executorMap.set(fileId, fn);

    fn({ fileId })
      .then((res) => {
        if (fileId !== FileDownloadExecutor.latestFileId) {
          FileDownloadExecutor.removeExecutor(fileId);
          return;
        }
        fileRender(res.data, data);
        FileDownloadExecutor.removeExecutor(fileId);
      })
      .catch((e) => {
        handleError(e);
      });

    // if (fileId !== FileDownloadExecutor.latestFileId) {
    //   FileDownloadExecutor.removeExecutor(fileId);
    //   return;
    // }

    // fileRender('' as unknown as BinaryData, data);
    // FileDownloadExecutor.removeExecutor(fileId);
  },
  removeExecutor: (fileId: string) => {
    FileDownloadExecutor.executorMap.delete(fileId);
  },
  clearExecutor: () => {
    FileDownloadExecutor.executorMap.clear();
  },
};
