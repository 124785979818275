// eventBus.ts

import EventEmitter from "events";
import {
  ExtractInfo,
  IPositionForPdf,
  IPositionForWeb,
  Merge,
  MultiDocumentPositions,
  Position,
  ReadingSource,
} from "@deeplang/shared";
import { MetaInfo } from "@/components/toolbar/TextToolbar";
import { PdfReaderConstants } from "@/modules/pdf-reader";
import { checkAllEventMessages } from ".";
import { Original } from "@deeplang/mark";
import { TextInfo } from "@/packages/summary";
import { IKeywordItem } from "@/modules/toolbar/components/keyword-setting/type";
import { ImgViewerInfo } from "@deeplang/dui";

export const EXTRACT_CHANGE = "EXTRACT_CHANGE";
export const EXTRACT_ADD = "EXTRACT_ADD";
export const EXTRACT_REMOVE = "EXTRACT_REMOVE";
export const EXTRACT_UPDATE = "EXTRACT_UPDATE";
export const EXTRACT_VIEW_ORIGINAL = "EXTRACT_VIEW_ORIGINAL";
export const SUMMARY_VIEW_ORIGINAL = "SUMMARY_VIEW_ORIGINAL";
export const OVERVIEW_VIEW_ORIGINAL = "OVERVIEW_VIEW_ORIGINAL";

export enum EventMessage {
  EXTRACT_CHANGE = "EXTRACT_CHANGE",
  EXTRACT_ADD = "EXTRACT_ADD",
  EXTRACT_REMOVE = "EXTRACT_REMOVE",
  EXTRACT_UPDATE = "EXTRACT_UPDATE",
  EXTRACT_VIEW_ORIGINAL = "EXTRACT_VIEW_ORIGINAL",
  SUMMARY_VIEW_ORIGINAL = "SUMMARY_VIEW_ORIGINAL",
  OVERVIEW_VIEW_ORIGINAL = "OVERVIEW_VIEW_ORIGINAL",
  GenerateContent = "GenerateContent",
  ToggleLeftSideBar = "ToggleLeftSideBar",
  TOOLBAR_SHOW = "TOOLBAR_SHOW",
  IMAGE_TOOLBAR_SHOW = "IMAGE_TOOLBAR_SHOW",
  IFRAME_EXTRACT_ADD = "IFRAME_EXTRACT_ADD",
  IMAGE_EXTRACT_ADD = "IMAGE_EXTRACT_ADD",
  TEXT_EXTRACT_ADD = "TEXT_EXTRACT_ADD",
  HIGHLIGHT_CREATE = "HIGHLIGHT_CREATE",
  HIGHLIGHT_UPDATE = "HIGHLIGHT_UPDATE",
  HIGHLIGHT_REMOVE = "HIGHLIGHT_REMOVE",
  HIGHLIGHT_CLICK = "HIGHLIGHT_CLICK",
  IFRAME_HIGHLIGHT_CREATE = "IFRAME_HIGHLIGHT_CREATE",
  IFRAME_HIGHLIGHT_REMOVE = "IFRAME_HIGHLIGHT_REMOVE",
  IFRAME_HIGHLIGHT_UPDATE = "IFRAME_HIGHLIGHT_UPDATE",
  IFRAME_IMAGE_CREATE = "IFRAME_IMAGE_CREATE",
  IFRAME_IMAGE_REMOVE = "IFRAME_IMAGE_REMOVE",
  IFRAME_IMAGE_UPDATE = "IFRAME_IMAGE_UPDATE",
  IFRAME_GET_MARK_INFO = "IFRAME_GET_MARK_INFO",
  IFRAME_SEND_MARK_INFO = "IFRAME_SEND_MARK_INFO",
  IFRAME_RECEIVE_MARK_INFO = "IFRAME_RECEIVE_MARK_INFO",
  GET_MARK_INFO = "GET_MARK_INFO",
  SUMMARY_GENERATE = "SUMMARY_GENERATE",
  // 上传、解析事件
  FILE_NOT_LOGIN = "FILE_NOT_LOGIN",
  CLOSE_UPLOAD_PARSE_MODAL = "CLOSE_UPLOAD_PARSE_MODAL",
  URL_PARSING_SUCCESS = "URL_PARSING_SUCCESS",
  FILE_REPEAT = "FILE_REPEAT",
  FILE_START_UPLOAD = "FILE_START_UPLOAD",
  FILE_SUCCESS_YUSIFILE = "FILE_SUCCESS_YUSIFILE",
  FILE_UPLOAD_ERR = "FILE_UPLOAD_ERR",
  FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS",
  FILE_PARSING_ERR = "FILE_PARSING_ERR",
  FILE_PARSING_SUCCESS = "FILE_PARSING_SUCCESS",
  FILE_TO_SAME = "FILE_TO_SAME",
  FILE_RIGHT_NOT_APPROVED = "FILE_RIGHT_NOT_APPROVED",
  // 个人库searchSug弹窗
  CHANG_SEARCH_SUG_OPEN = "CHANG_SEARCH_SUG_OPEN",
  SUG_SEARCH = "SUG_SEARCH",
  // resize layout
  RESIZE_READER_LAYOUT = "RESIZE_READER_LAYOUT",
  READER_FONTSIZE = "READER_FONTSIZE",
  // web original text position
  ORIGINAL_POSITION_EXTRACT_WEB = "ORIGINAL_POSITION_EXTRACT_WEB",
  ORIGINAL_POSITION_OUTLINE_WEB = "ORIGINAL_POSITION_OUTLINE_WEB", // 大纲
  ORIGINAL_POSITION_OVERVIEW_WEB = "ORIGINAL_POSITION_OVERVIEW_WEB", // 概述
  SUMMARY_OUTLINE_ORIGINAL_CLICK = "SUMMARY_OUTLINE_ORIGINAL_CLICK", // 大纲
  // user permission modal show
  SHOW_NO_PERMISSION = "SHOW_NO_PERMISSION",
  CHECK_USER = "CHECK_USER",
  EXTRACT_CHANGE_NO_LOGIN = "EXTRACT_CHANGE_NO_LOGIN",
  GENERATE_FINISH = "GENERATE_FINISH",
  NO_LOGIN_CALLBACK = "NO_LOGIN_CALLBACK",
  IFRAME_WIDTH_CHANGE = "IFRAME_WIDTH_CHANGE",
  IFRAME_ONLOAD = "IFRAME_ONLOAD",
  IFRAME_SCROLL_TO_MARK = "IFRAME_SCROLL_TO_MARK",
  CLOSE_UPLOAD_LIST_MODAL = "CLOSE_UPLOAD_LIST_MODAL",
  // keywords event
  IFRAME_KEYWORD_APPLY = "IFRAME_KEYWORD_APPLY",
  // iframe meta ready
  IFRAME_META_READY = "IFRAME_META_READY",
  // guide tour finish
  GUIDE_FINISH = "GUIDE_FINISH",
  REFRESH_EXTRACT = "REFRESH_EXTRACT",
  NOTE_ICON_CLICK = "NOTE_ICON_CLICK",
  TOOLBAR_NOTE_OPEN_STATUS = "TOOLBAR_NOTE_OPEN_STATUS",
  // PDF ready to position target extract
  PDF_CAN_POSITION_TARGET_EXTRACT = "PDF_CAN_POSITION_TARGET_EXTRACT",
  REMOVE_NOTE_ICON = "REMOVE_NOTE_ICON",
  PANNEL_REMOVE_EXTRACT = "PANNEL_REMOVE_EXTRACT",
  UPDATE_READER_EXTRACT = "UPDATE_READER_EXTRACT",
  // Show or Hide full screen
  SHOW_FULL_SCREEN = "SHOW_FULL_SCREEN",
  HIDE_FULL_SCREEN = "HIDE_FULL_SCREEN",
  // Expand SideBar
  EXPAND_SIDEBAR = "EXPAND_SIDEBAR",
  REFRESH_SIDEBAR_HISTORY = "REFRESH_SIDEBAR_HISTORY",
  SET_DEFAULT_QA_INPUT = "SET_DEFAULT_QA_INPUT",
  // 摘录展开/收起
  EXTRACT_TOGGLE = "EXTRACT_TOGGLE",
  QA_SWITCH = "QA_SWITCH",
  GET_QA_DIV_HEIGHT = "GET_QA_DIV_HEIGHT",
  // reader reading time batch trace id
  READER_TRACE_ID = "READER_TRACE_ID",
  // 通过 positionId 原文定位
  LOCATE_BY_POSITION_ID = "LOCATE_BY_POSITION_ID",
  // 多文档模式下获取多文档解析状态，以便展示问答窗口
  GET_MULTI_DOCUMENT_ANALYSIS_STATUS = "GET_MULTI_DOCUMENT_ANALYSIS_STATUS",
  // 多文档通过  positionId 原文定位
  MULTI_LOCATE_BY_POSITION_ID = "MULTI_LOCATE_BY_POSITION_ID",
  WEB_IMG_CLICK = "WEB_IMG_CLICK",
  PDF_IMG_CLICK = "PDF_IMG_CLICK",
}
checkAllEventMessages(EventMessage, PdfReaderConstants.EventMessage);

export interface ViewOriginal {
  metaInfo?: string;
  original?: string;
  id?: string;
}
export interface SelectionInfo {
  show: boolean;
  text: string;
  meta_info: MetaInfo;
  clientX: number;
  clientY: number;
  tagName?: string;
  from?: string;
}

interface EventMap {
  [EventMessage.GenerateContent]: {
    url: string;
    name: string;
    fileId?: string;
    isAutoGenerate?: boolean;
    channelType: ReadingSource;
  };
  [EventMessage.ToggleLeftSideBar]: {
    collapsed: boolean;
  };
  [EventMessage.EXTRACT_CHANGE]: Object;
  [EventMessage.EXTRACT_ADD]: ExtractInfo;
  [EventMessage.EXTRACT_UPDATE]: Object;
  [EventMessage.EXTRACT_REMOVE]: Object;
  [EventMessage.EXTRACT_VIEW_ORIGINAL]: ViewOriginal;
  [EventMessage.SUMMARY_VIEW_ORIGINAL]:
    | TextInfo[]
    | string
    | { query: string; textInfo: TextInfo[] };
  [EventMessage.OVERVIEW_VIEW_ORIGINAL]: ViewOriginal;

  [EventMessage.ORIGINAL_POSITION_EXTRACT_WEB]: ViewOriginal;
  [EventMessage.ORIGINAL_POSITION_OUTLINE_WEB]: ViewOriginal;
  [EventMessage.ORIGINAL_POSITION_OVERVIEW_WEB]: ViewOriginal;

  [EventMessage.TOOLBAR_SHOW]: SelectionInfo;
  [EventMessage.IMAGE_TOOLBAR_SHOW]: Object;
  [EventMessage.IFRAME_EXTRACT_ADD]: Object;
  [EventMessage.IMAGE_EXTRACT_ADD]: Object;
  [EventMessage.HIGHLIGHT_CREATE]: Object;
  [EventMessage.HIGHLIGHT_UPDATE]: Object;
  [EventMessage.HIGHLIGHT_REMOVE]: Object;
  [EventMessage.HIGHLIGHT_CLICK]: Object;
  [EventMessage.IFRAME_HIGHLIGHT_CREATE]: {};
  [EventMessage.IFRAME_IMAGE_CREATE]: {};
  [EventMessage.IFRAME_IMAGE_REMOVE]: {};
  [EventMessage.IFRAME_IMAGE_UPDATE]: {};
  [EventMessage.IFRAME_GET_MARK_INFO]: {};
  [EventMessage.IFRAME_SEND_MARK_INFO]: Object;
  [EventMessage.IFRAME_RECEIVE_MARK_INFO]: Object;
  [EventMessage.GET_MARK_INFO]: Object;
  [EventMessage.TEXT_EXTRACT_ADD]: {};
  [EventMessage.IFRAME_HIGHLIGHT_REMOVE]: {
    id: string;
  };
  [EventMessage.IFRAME_HIGHLIGHT_UPDATE]: {
    id: string;
    color?: number;
  };
  [EventMessage.SUMMARY_GENERATE]: {
    query: string;
    metaInfo: string;
  };
  [EventMessage.URL_PARSING_SUCCESS]: Object;
  [EventMessage.FILE_NOT_LOGIN]: Object;
  [EventMessage.CLOSE_UPLOAD_PARSE_MODAL]: Object;
  [EventMessage.FILE_REPEAT]: Object;
  [EventMessage.FILE_START_UPLOAD]: Object;
  [EventMessage.FILE_SUCCESS_YUSIFILE]: Object;
  [EventMessage.FILE_UPLOAD_ERR]: Object;
  [EventMessage.FILE_UPLOAD_SUCCESS]: Object;
  [EventMessage.FILE_PARSING_ERR]: Object;
  [EventMessage.FILE_PARSING_SUCCESS]: Object;
  [EventMessage.FILE_TO_SAME]: {
    id: string;
  };
  [EventMessage.FILE_RIGHT_NOT_APPROVED]: Object;
  [EventMessage.CHANG_SEARCH_SUG_OPEN]: {
    open: boolean;
  };
  [EventMessage.SUG_SEARCH]: {
    value: string;
  };
  [EventMessage.RESIZE_READER_LAYOUT]: {
    percent: number;
  };
  [EventMessage.READER_FONTSIZE]: {
    size: string;
  };
  [EventMessage.SHOW_NO_PERMISSION]: Object;
  [EventMessage.CHECK_USER]: {
    callback: () => void;
  };
  [EventMessage.EXTRACT_CHANGE_NO_LOGIN]: Object;
  [EventMessage.GENERATE_FINISH]: Object;
  [EventMessage.NO_LOGIN_CALLBACK]: any;
  [EventMessage.IFRAME_WIDTH_CHANGE]: Object;
  [EventMessage.IFRAME_ONLOAD]: Object;
  [EventMessage.SUMMARY_OUTLINE_ORIGINAL_CLICK]: Original;
  [EventMessage.IFRAME_SCROLL_TO_MARK]: {
    top: number;
    behavior: ScrollBehavior;
  };
  [EventMessage.CLOSE_UPLOAD_LIST_MODAL]: Object;
  [EventMessage.IFRAME_KEYWORD_APPLY]: IKeywordItem[];
  [EventMessage.IFRAME_META_READY]: Object;
  [EventMessage.GUIDE_FINISH]: Object;
  [EventMessage.REFRESH_EXTRACT]: Object;
  [EventMessage.NOTE_ICON_CLICK]: Object;
  [EventMessage.TOOLBAR_NOTE_OPEN_STATUS]: Object;
  [EventMessage.PDF_CAN_POSITION_TARGET_EXTRACT]: Object;
  [EventMessage.REMOVE_NOTE_ICON]: Object;
  [EventMessage.PANNEL_REMOVE_EXTRACT]: Object;
  [EventMessage.UPDATE_READER_EXTRACT]: Object;
  [EventMessage.SHOW_FULL_SCREEN]: {
    id: string;
    channelType: ReadingSource;
    page?: string;
  };
  [EventMessage.HIDE_FULL_SCREEN]: { page?: string };
  [EventMessage.EXPAND_SIDEBAR]: { open: boolean };
  [EventMessage.REFRESH_SIDEBAR_HISTORY]: Object;
  [EventMessage.EXTRACT_TOGGLE]: { open: boolean };
  [EventMessage.QA_SWITCH]: {
    open: boolean;
    qaInputValue?: string;
    streamStatus?: boolean;
  };
  [EventMessage.SET_DEFAULT_QA_INPUT]: { defaultValue?: string };
  [EventMessage.GET_QA_DIV_HEIGHT]: { height: string; collapsed: boolean };
  [EventMessage.READER_TRACE_ID]: { traceId: string };
  [EventMessage.LOCATE_BY_POSITION_ID]: {
    // tail 参数是 web 端独有的
    positionIds: (IPositionForPdf | IPositionForWeb)[];
    needScroll?: boolean;
    mode: "oneWay" | "twoWay";
  };
  [EventMessage.GET_MULTI_DOCUMENT_ANALYSIS_STATUS]: {
    multiDocAnalysisStatus: number;
  };
  [EventMessage.MULTI_LOCATE_BY_POSITION_ID]: {
    positions: MultiDocumentPositions;
  };
  [EventMessage.WEB_IMG_CLICK]: {
    imgList: ImgViewerInfo[];
    index: number;
  };
  [EventMessage.PDF_IMG_CLICK]: {
    index: number;
  };
}

export const eventBus = new EventEmitter();

type EventFn = <T extends keyof EventMap = keyof EventMap>(
  eventName: T,
  listener: (data: EventMap[T], ...args: any[]) => void,
) => ComplexEventEmitter;

export type ComplexEventEmitter = Merge<
  EventEmitter,
  {
    emit: <
      T extends keyof EventMap = keyof EventMap,
      K extends EventMap[T] = EventMap[T],
    >(
      eventName: T,
      data?: K,
      ...args: any[]
    ) => boolean;
    on: EventFn;
    off: EventFn;
    removeListener: EventFn;
    addListener: EventFn;
  }
>;

export type AnyEventData<T extends keyof EventMap = EventMessage.CHECK_USER> =
  Parameters<Parameters<typeof complexEventBus.on<T>>[1]>[0];

const complexEventBus = eventBus as ComplexEventEmitter;

export default complexEventBus;
