import dayjs from "@/third-party/dayjs";
import { ReadingMaterial, materialAdapter } from "@deeplang/shared";
import { downloadFileUrl } from "../config";
import { getLoginStatus } from "@/packages/passport";
import { EventMessage, eventBus } from "./event-bus";

materialAdapter.initial(downloadFileUrl);
// 单例模式
export function singleton<T extends new (...argArray: any[]) => any>(
  className: T,
) {
  let ins: InstanceType<T>;

  return new Proxy(className, {
    construct(target: T, argArray) {
      if (!ins) {
        ins = new target(...argArray);
        return ins;
      }

      return ins;
    },
  });
}

/**
 * 格式化文件大小
 * @param fileSizeBytes 文件大小
 * @returns
 */
export function formatFileSize(fileSizeBytes: number, blank: string = " ") {
  const megaByte = 1024 * 1024;
  const kiloByte = 1024;

  if (fileSizeBytes >= megaByte) {
    // 大于等于1M
    return Math.round((fileSizeBytes / megaByte) * 10) / 10 + blank + "M";
  } else if (fileSizeBytes >= kiloByte) {
    // 大于等于1KB，小于1M
    return Math.round((fileSizeBytes / kiloByte) * 10) / 10 + blank + "KB";
  } else {
    return fileSizeBytes + blank + "B";
  }
}

// 格式化摘录展示时间
export const formatTime = (time: string, formatStr?: string) => {
  // console.log(time);
  const now = dayjs(dayjs.utc().toDate());
  const utcTime = dayjs(dayjs.utc(time).toDate());
  const dayDiff = now.diff(utcTime, "day", true);
  let str = "";
  // 如果超出本年
  if (dayDiff > 1 || utcTime.isYesterday()) {
    str = utcTime.format(formatStr || "YYYY/MM/DD");
  } else {
    str = utcTime.format("HH:mm");
  }
  return str;
};

/**
 * 给定 url 和 query param，转换为 'url?a=1&b=2'
 * @param baseUrl
 * @param queryParams 该参数的 value 除非本身就是 string，否则都将被转换为 JSON 字符串，使用时，需要使用 json.parse 转换
 * @returns
 */
export function getURLStrWithQueryParams<T extends object>(
  baseUrl: string,
  queryParams: T,
) {
  let url = "";
  url += baseUrl;

  const urlSearchParams = new URLSearchParams();

  for (const key in queryParams) {
    const value = queryParams[key];

    if (typeof value === "string") {
      urlSearchParams.append(key, value);
      continue;
    }
    urlSearchParams.append(key, JSON.stringify(value));
  }

  url += `?${urlSearchParams.toString()}`;
  return url;
}

export function formatNumber(num: number) {
  try {
    const result = num / 10000;
    const resultStr = result.toString();
    const resultSplitStrs = resultStr.split(".");

    if (num >= 10000) {
      if (Number.isInteger(result)) {
        return result + "W";
      }
      return (
        resultSplitStrs[0] + "." + resultSplitStrs[1]?.substring(0, 1) + "W"
      );
    } else if (num >= 1000) {
      return (
        resultSplitStrs[0] + "." + resultSplitStrs[1]?.substring(0, 1) + "W"
      );
    } else {
      return num.toString();
    }
  } catch (e) {
    console.error(e);
  }

  return "0";
}

export function secondsToHM(savedSeconds: number) {
  const seconds = Math.floor(savedSeconds);
  if (seconds >= 3600) {
    // 如果秒数足够表示一个小时
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    return `${hours} 小时 ${Math.floor(remainingSeconds / 60)} 分钟`;
  } else if (seconds >= 60) {
    // 如果秒数足够表示一分钟
    return `${Math.floor(seconds / 60)} 分钟`;
  } else {
    return `${seconds} 秒`;
  }
}

// 计算字符串长度
export const getStrLength = (str: string) => {
  let tempStr = str;
  if (tempStr == null) return 0;
  if (typeof str != "string") {
    tempStr += "";
  }
  return str.replace(/[^\x00-\xff]/g, "01").length;
};

// 校验字符语言
export const regUseName = (str: string) => {
  const reg = /[A-z0-9a-z\u4e00-\u9fa5_-]+/;
  const newStr = str.replace(reg, "");
  return !newStr;
};

// 对手机号码进行加密
export const phoneNumberFormat = (str: string) => {
  const pho = /(\d{3})\d*(\d{4})/;
  return str.replace(pho, "$1****$2");
};

export function checkAllEventMessages(...args: { [key in string]: string }[]) {
  let allValues: string[] = [];
  for (let i = 0; i < args.length; i++) {
    const eventMessages = args[i];
    if (!eventMessages) {
      continue;
    }
    allValues = allValues.concat(Object.values(eventMessages));
  }

  const allValuesLength = allValues.length;
  const allValuesSet = new Set(allValues);

  if (allValuesLength !== allValuesSet.size) {
    const findDuplicates = (strArray: string[]) => {
      // 步骤1: 创建一个空对象来存储字符串的出现次数
      const countObj: { [key in string]: number } = {};

      // 步骤2: 遍历数组，更新每个字符串的出现次数
      strArray.forEach((str) => {
        countObj[str] = (countObj[str] || 0) + 1;
      });

      // 步骤3: 找出所有计数大于1的字符串
      const duplicates = Object.keys(countObj).filter((key) => {
        const value = countObj[key];
        if (value === undefined) {
          return false;
        }
        return value > 1;
      });

      // 步骤4: 返回一个包含所有重复字符串的新数组
      return duplicates;
    };
    Promise.reject(
      new Error(
        `EventMessage 存在冲突，冲突的 Message 有 ${findDuplicates(allValues).join(", ")}`,
      ),
    );
  }
}

// 判断电脑操作系统
export function getOperatingSystem() {
  const platform = navigator.platform;

  if (platform.includes("Win")) {
    return "Windows";
  } else if (platform.includes("Mac")) {
    return "Mac OS";
  } else if (platform.includes("Linux")) {
    return "Linux";
  } else if (
    platform.includes("iPhone") ||
    platform.includes("iPad") ||
    platform.includes("iPod")
  ) {
    return "iOS";
  } else if (platform.includes("Android")) {
    return "Android";
  } else {
    return "Unknown";
  }
}

export const ossUrl =
  "https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com";

export const emitLogin = async () => {
  const status = await getLoginStatus();
  if (!status) {
    eventBus.emit(EventMessage.NO_LOGIN_CALLBACK);
    return true;
  }
  return false;
};

// 根据路由path判断文档类型
export const isWebReader = (path: string) => {
  return path.includes("/reader/web");
};

// 根据路由path判断文档类型
export const getMaterialType = (path: string) => {
  switch (path) {
    case "/reader/web":
      return ReadingMaterial.WebSite;
    case "/reader/pdf":
      return ReadingMaterial.PDF;
    default:
      return ReadingMaterial.WebSite;
  }
};

export function transformBboxToRect(
  bbox: number[] = [0, 0, 0, 0],
  pageScape: { width: number; height: number } = { width: 0, height: 0 },
) {
  const x1 = bbox[0] || 0;
  const y1 = bbox[1] || 0;
  const x2 = bbox[2] || 0;
  const y2 = bbox[3] || 0;

  return {
    x1: Math.floor(x1 * pageScape.width),
    y1: Math.floor(y1 * pageScape.height),
    width: Math.round(Math.abs(x2 - x1) * pageScape.width),
    height: Math.round(Math.abs(y2 - y1) * pageScape.height),
  };
}
