import cx from "classnames";

import styles from "./TagList.module.css";

import { Action } from "../tag-input/TagInput";
import { ITag } from "@deeplang/shared";
import { Selected } from "@deeplang/icons";

export type TagListProps = {
  tags: ITag[];
  selectedTags: ITag[];
  onTagSelect: (tag: ITag) => void;
  onAction: (action: Action) => void;
  maxTagCount?: number;
  value?: string;
};

const isSelected = (tag: ITag, selectedTags: ITag[]) => {
  for (const t of selectedTags) {
    if (t.id === tag.id) {
      return true;
    }
  }
  return false;
};

const checkDisabled = (
  selectedTags: ITag[],
  tag: ITag,
  maxTagCount: number,
) => {
  const selected = selectedTags.find((t) => t.id === tag.id);

  return selectedTags.length === maxTagCount && !selected;
};
const TagList = (props: TagListProps) => {
  const {
    tags,
    selectedTags,
    onTagSelect,
    onAction,
    maxTagCount = 10,
    value = "",
  } = props;

  const handleAddTag = () => {
    if (value) {
      onAction({ action: "add", value });
    }
  };
  return (
    <div className={styles.list}>
      {tags.length > 0 ? (
        tags.map((tag) => {
          const selected = isSelected(tag, selectedTags);
          return (
            <div
              className={cx(styles.item, {
                [styles.disabled || ""]: checkDisabled(
                  selectedTags,
                  tag,
                  maxTagCount,
                ),
              })}
              style={{ backgroundColor: selected ? "#F5F5F5" : "white" }}
              key={tag.id}
              onClick={(e) => {
                e.stopPropagation();
                if (checkDisabled(selectedTags, tag, maxTagCount)) {
                  return;
                }
                onTagSelect(tag);
              }}
            >
              <p># {tag.tag}</p>
              {selected ? <Selected fill="#2D2D2D" /> : null}
            </div>
          );
        })
      ) : (
        <p className={styles.create} onClick={handleAddTag}>
          创建 # {value}
        </p>
      )}
    </div>
  );
};

export default TagList;
