import { useState, useEffect } from "react";
import { ExtractDetailResponse } from "../types";
import { ReadingSource } from "@deeplang/shared";
import { useApi } from "./useApi";

function useUrlExtract(type = 0, fileId: string, channel_type: ReadingSource) {
  const [highlights, setHighlights] = useState<ExtractDetailResponse[]>([]);
  const { detail } = useApi();
  useEffect(() => {
    getHighlightList();
  }, [type]);

  async function getHighlightList() {
    if (!fileId) return;
    try {
      const res = await detail({
        type: 1, // 按 URL 查询
        keyword: fileId,
        channel_type,
        isFile: true,
      });
      if (res) {
        const details = res;
        const filtered = details.filter((item) => item.type === type);
        setHighlights(filtered);
      }
    } catch (err: any) {
      console.error(err.msg);
    }
  }

  return {
    highlights,
    setHighlights,
    getHighlights: getHighlightList,
  };
}

export default useUrlExtract;
