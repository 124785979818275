import { DDrawer, toast } from "@deeplang/dui";

import { PdfReader } from "@/modules/pdf-reader";
import { useAnyHooksStore } from "@/provider/any-hooks-layout";

import { useRelatedChartsStore } from "../../store";
import { useMaterialById } from "../hooks/useMaterialById";
import { MOperationBar } from "./MOperationBar";
import { useEffect, useRef } from "react";
import { useCheckCanAddToLibrary } from "../hooks/useCheckCanAddToLibrary";
import { useApi } from "../hooks/useApi";
import { IPdfReaderRef, PagesLoadedArgs } from "@/modules/pdf-reader/type";
import {
  GetMaterialDetailResponse,
  MaterialDetail,
  RemoveUndefined,
} from "@deeplang/shared";
import { textToolbar } from "@/modules/float-toolbar";

export function MHalfScreen() {
  const pdfReaderRef = useRef<IPdfReaderRef>(null);
  const {
    PubSub,
    throttle,
    debounce,
    eventBus,
    drawerRef,
    externalMaterial,
    selectedRelateChartRef,
  } = useRelatedChartsStore();
  const { request, fileRequest } = useAnyHooksStore();
  const { isAutoGeneratedRef } = useMaterialById();
  const { runCheckCanAddToLibrary, canAdd, canAddRef } =
    useCheckCanAddToLibrary();
  const { addMaterialToLibrary } = useApi();
  const goBackOriginalMaterialIdRef = useRef<string>("");

  useEffect(() => {
    if (!externalMaterial) {
      return;
    }

    pdfReaderRef.current?.updateMaterial(
      externalMaterial as RemoveUndefined<
        GetMaterialDetailResponse["file_info"] & MaterialDetail
      >,
    );
    runCheckCanAddToLibrary({
      file_id: externalMaterial?.id || "",
    });
  }, [externalMaterial]);

  const handleClickClose = () => {
    // 关闭半屏页，开启浮动工具栏
    drawerRef.current?.hide();
    textToolbar.renderText();
  };

  const handleClickBackOriginal = () => {
    window.open(
      `${window.location.origin}/reader/pdf/${canAddRef.current ? externalMaterial?.id || "" : goBackOriginalMaterialIdRef.current}?elementId=${selectedRelateChartRef.current?.element_id || ""}`,
    );
  };
  const handleClickSaveLibrary = async () => {
    try {
      const res = await addMaterialToLibrary({
        file_id: externalMaterial?.id || "",
      });

      if (!res) {
        return;
      }

      goBackOriginalMaterialIdRef.current = res.file_id;

      toast.success("保存成功!");
      runCheckCanAddToLibrary({
        file_id: externalMaterial?.id || "",
      });
    } catch {
      toast.warn("保存失败");
    }
  };

  const handlePagesLoaded = (args: PagesLoadedArgs) => {
    const pageViewer = args.source;
    if (!pageViewer) {
      return;
    }

    pageViewer.scrollPageIntoView({
      pageNumber:
        (selectedRelateChartRef.current?.element_pos.page_num || 0) + 1,
    });
  };

  return (
    <DDrawer
      ref={drawerRef}
      destroyOnClose
      extra={
        <MOperationBar
          canAdd={canAdd}
          handleClickSaveLibrary={handleClickSaveLibrary}
          handleClickBackOriginal={handleClickBackOriginal}
          handleClickClose={handleClickClose}
        />
      }
    >
      <PdfReader
        ref={pdfReaderRef}
        thirdParty={{
          PubSub,
          request,
          throttle,
          fileRequest,
          eventBus,
          debounce,
          handlePagesLoaded,
        }}
        isAutoGeneratedRef={isAutoGeneratedRef}
        options={{
          toolbar: false,
          disabledAutoGenerated: true,
        }}
      />
    </DDrawer>
  );
}
