type EventHandler<T> = (...data: T[]) => void;

export type EventMap<T> = Record<string, EventHandler<T>>;

type Handlers<T> = {
  [K in keyof T]: T[K][];
};

class EventEmitter<T extends EventMap<unknown>> {
  private handlers: Handlers<T> = Object.create(null);

  on<K extends keyof T>(eventType: K, eventHandler: T[K]) {
    if (!this.handlers[eventType]) {
      this.handlers[eventType] = [];
    }

    this.handlers[eventType].push(eventHandler);

    return this;
  }

  off<K extends keyof T>(eventType: K, eventHandler: T[K]) {
    if (this.handlers[eventType]) {
      this.handlers[eventType].splice(this.handlers[eventType].indexOf(eventHandler) >>> 0, 1);
    }
    return this;
  }

  emit<K extends keyof T>(eventType: K, ...data: Parameters<T[K]>) {
    if (this.handlers[eventType]) {
      this.handlers[eventType].slice().forEach((handler) => {
        handler(...data);
      });
    }

    return this;
  }
}

export default EventEmitter;
