import { nanoid } from "nanoid";

import EventEmitter from "./event.emitter";
import HighlightSource from "./source";
import HighlightRange from "./range";
import Painter from "./painter";
import Hook from "./hook";
import Cache from "./cache";

import {
  isHighlightWrapNode,
  addEventListener,
  getInteraction,
  removeEventListener,
  addClass,
  removeClass,
  getHighlightId,
  getExtraHighlightIds,
  getHighlightsById,
  getHighlightsByRoot,
} from "./dom";
import {
  EventType,
  CreateFrom,
  HookMap,
  HighlightOptions,
  RootElement,
  ERROR,
  DOMNode,
  DOMMeta,
} from "./types";
import { eventEmitter, getDefaultOptions, INTERNAL_ERROR_EVENT } from "./const";

export * from "./types";
interface EventHandlerMap {
  [key: string]: (...args: any[]) => void;
  [EventType.CLICK]: (
    data: { id: string },
    highlight: Highlight,
    e: MouseEvent,
  ) => void;
  [EventType.CREATE]: (
    data: { sources: HighlightSource[]; type: CreateFrom },
    highlight: Highlight,
  ) => void;
  [EventType.REMOVE]: (data: { ids: string[] }, highlight: Highlight) => void;
  [EventType.HOVER]: (
    data: { id: string },
    highlight: Highlight,
    e: MouseEvent,
  ) => void;
  [EventType.HOVER_OUT]: (
    data: { id: string },
    highlight: Highlight,
    e: MouseEvent,
  ) => void;
}

class Highlight extends EventEmitter<EventHandlerMap> {
  private options: HighlightOptions;
  private readonly event = getInteraction();
  private hoverId = "";

  hooks: HookMap;
  cache: Cache;
  painter!: Painter;

  static event = EventType;
  static isHighlightWrapNode = isHighlightWrapNode;

  constructor(options?: HighlightOptions) {
    super();
    this.options = getDefaultOptions();
    this.hooks = this.getHooks();
    this.setOption(options);
    this.cache = new Cache();

    const rootElement = this.options.root as RootElement;
    addEventListener(
      rootElement,
      this.event.PointerOver,
      this.handleHighlightHover as EventListener,
    );
    addEventListener(
      rootElement,
      this.event.PointerTap,
      this.handleHighlightClick as EventListener,
    );
    eventEmitter.on(INTERNAL_ERROR_EVENT, this.handleError);
  }

  dispose = () => {
    const rootElement = this.options.root as RootElement;
    removeEventListener(
      rootElement,
      this.event.PointerOver,
      this.handleHighlightHover as EventListener,
    );
    removeEventListener(
      rootElement,
      this.event.PointerTap,
      this.handleHighlightClick as EventListener,
    );
    removeEventListener(
      rootElement,
      this.event.PointerEnd,
      this.handleSelection,
    );
    this.removeAll();
  };

  run = () => {
    addEventListener(
      this.options.root as RootElement,
      this.event.PointerEnd,
      this.handleSelection,
    );
  };

  stop = () => {
    removeEventListener(
      this.options.root as RootElement,
      this.event.PointerEnd,
      this.handleSelection,
    );
  };

  setOption = (options?: HighlightOptions) => {
    this.options = {
      ...this.options,
      ...options,
    };

    this.painter = new Painter(
      {
        root: this.options.root as HTMLElement,
        wrapTag: this.options.wrapTag as string,
        className: this.options.style?.className as string[],
        excludeSelectors: this.options.excludeSelectors as string[],
        bgColors: this.options.bgColors as string[],
      },
      this.hooks,
    );
  };

  addClass = (className: string, id?: string) => {
    this.getDoms(id).forEach((dom) => {
      addClass(dom, className);
    });
  };

  removeClass = (className: string, id?: string) => {
    this.getDoms(id).forEach((dom) => {
      removeClass(dom, className);
    });
  };

  getIdByDom = (node: HTMLElement) => {
    getHighlightId(node, this.options.root as HTMLElement);
  };

  getExtraIdByDom = (node: HTMLElement) => {
    getExtraHighlightIds(node, this.options.root as HTMLElement);
  };

  // get serialize data from range
  getSerializeInfo = (range: Range) => {
    const startNode: DOMNode = {
      node: range.startContainer,
      offset: range.startOffset,
    };
    const endNode: DOMNode = {
      node: range.endContainer,
      offset: range.endOffset,
    };

    const text = range.toString();
    let id = this.hooks.Render.UUID.call(startNode, endNode, text);
    id = id ?? nanoid();
    const highlightRange = new HighlightRange(
      startNode,
      endNode,
      text,
      id as string,
    );
    if (!highlightRange) {
      eventEmitter.emit(INTERNAL_ERROR_EVENT, {
        type: ERROR.RANGE_INVALID,
      });
      return null;
    }

    const highlightSource = highlightRange.serialize(
      this.options.root as HTMLElement,
      this.hooks,
    );
    return {
      start: highlightSource.start,
      end: highlightSource.end,
      text: highlightSource.text,
      id: highlightSource.id,
    };
  };

  removeSelection = () => {
    HighlightRange.removeDOMRange();
  };

  getSourceFromRange = (
    range: Range,
    pageNumber: number,
  ): HighlightSource | null => {
    const startNode: DOMNode = {
      node: range.startContainer,
      offset: range.startOffset,
    };
    const endNode: DOMNode = {
      node: range.endContainer,
      offset: range.endOffset,
    };

    const text = range.toString();
    let id = this.hooks.Render.UUID.call(startNode, endNode, text);
    id = id ?? nanoid();
    const highlightRange = new HighlightRange(
      startNode,
      endNode,
      text,
      id as string,
    );

    return this.highlightRangeSource(highlightRange, pageNumber);
  };

  fromRange = (range: Range, pageNumber: number): HighlightSource | null => {
    const startNode: DOMNode = {
      node: range.startContainer,
      offset: range.startOffset,
    };
    const endNode: DOMNode = {
      node: range.endContainer,
      offset: range.endOffset,
    };

    const text = range.toString();
    let id = this.hooks.Render.UUID.call(startNode, endNode, text);
    id = id ?? nanoid();
    const highlightRange = new HighlightRange(
      startNode,
      endNode,
      text,
      id as string,
    );
    if (!highlightRange) {
      eventEmitter.emit(INTERNAL_ERROR_EVENT, {
        type: ERROR.RANGE_INVALID,
      });
      return null;
    }

    return this.highlightFromHighlightRange(highlightRange, pageNumber);
  };

  fromStore = (
    start: DOMMeta,
    end: DOMMeta,
    text: string,
    id: string,
    pageNumber: number,
    extra?: unknown,
  ): HighlightSource => {
    const highlightSource = new HighlightSource(start, end, text, id, extra);
    try {
      this.highlightFromHighlightSource(highlightSource, pageNumber);
      return highlightSource;
    } catch (e) {
      eventEmitter.emit(INTERNAL_ERROR_EVENT, {
        type: ERROR.HIGHLIGHT_SOURCE_RECREATE,
        error: e,
        detail: highlightSource,
      });
      throw e;
    }
  };

  remove(id: string) {
    if (!id) {
      return;
    }
    const exists = this.painter.removeHighlight(id);
    this.cache.remove(id);
    if (exists) {
      this.emit(EventType.REMOVE, { ids: [id] }, this);
    }
  }

  removeAll = () => {
    this.painter.removeAllHighlight();
    const removedHighlightIds = this.cache.removeAll();
    this.emit(EventType.REMOVE, { ids: removedHighlightIds }, this);
  };

  // check if the selection includes an img element
  hasSelectedImage = (range: Range): boolean => {
    return this.getImagesFromRange(range).length > 0;
  };

  highlightImage = (range: Range): void => {
    const images = this.getImagesFromRange(range);
    // add outline to the image bottom
    images.forEach((image) => {
      addClass(image, "highlight-image");
    });
  };

  getImagesFromRange = (range: Range): HTMLImageElement[] => {
    const allImages = document.getElementsByTagName("img");
    const images: HTMLImageElement[] = [];

    for (let i = 0; i < allImages.length; i++) {
      const image = allImages.item(i);
      if (!image) {
        continue;
      }
      if (range.intersectsNode(image)) {
        images.push(image);
      }
    }

    return images;
  };

  highlightImageBySrc(src: string, colorIndex: number) {
    try {
      const images = document.getElementsByTagName("img");
      const url = new URL(src);
      url.search = "";
      const formattedSrc = url.toString();
      const image = Array.from(images).find((img) => {
        const { src, dataset } = img;
        return (
          src.indexOf(formattedSrc) > -1 ||
          (dataset && dataset.src && dataset.src.indexOf(formattedSrc) > -1)
        );
      });
      if (image) {
        addClass(image, ["highlight-wrap", `highlight-color-${colorIndex}`]);
      }
    } catch (err) {
      console.info(`image url: ${src} is invalid`);
    }
  }

  // remove border from the image bottom
  removeHighlightImageBySrc(src: string, colorIndex: number) {
    try {
      const images = document.getElementsByTagName("img");
      const url = new URL(src);
      url.search = "";
      const formattedSrc = url.toString();
      const image = Array.from(images).find((img) => {
        const { src, dataset } = img;
        return (
          src.indexOf(formattedSrc) > -1 ||
          (dataset && dataset.src && dataset.src.indexOf(formattedSrc) > -1)
        );
      });
      if (image) {
        removeClass(image, "highlight-wrap");
        removeClass(image, `highlight-color-${colorIndex}`);
      }
    } catch (err) {
      console.info(`image url: ${src} is invalid`);
    }
  }

  updateHighlightImageBySrc(src: string, color: number, oldColor: number) {
    this.removeHighlightImageBySrc(src, oldColor);
    this.highlightImageBySrc(src, color);
  }

  getRange(
    start: DOMMeta,
    end: DOMMeta,
    text: string,
    id: string,
    root: HTMLElement,
    extra?: unknown,
  ) {
    const highlightSource = new HighlightSource(start, end, text, id, extra);
    return highlightSource.deserialize(root);
  }

  private getDoms(id?: string) {
    if (id) {
      return getHighlightsById(
        this.options.root as HTMLElement,
        id,
        this.options.wrapTag as string,
      );
    }

    return getHighlightsByRoot(
      this.options.root as HTMLElement,
      this.options.wrapTag as string,
    );
  }

  private readonly getHooks = (): HookMap => {
    return {
      Render: {
        UUID: new Hook("Render.UUID"),
        SelectedNodes: new Hook("Render.SelectedNodes"),
        WrapNode: new Hook("Render.WrapNode"),
      },
      Serialize: {
        Restore: new Hook("Serialize.Restore"),
        RecordInfo: new Hook("Serialize.RecordInfo"),
      },
      Remove: {
        UpdateNodes: new Hook("Remove.UpdateNodes"),
      },
    };
  };

  private readonly handleHighlightHover = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!isHighlightWrapNode(target)) {
      this.hoverId &&
        this.emit(EventType.HOVER_OUT, { id: this.hoverId }, this, e);
      this.hoverId = "";
      return;
    }

    const id = getHighlightId(target, this.options.root as HTMLElement);
    if (id === this.hoverId) {
      return;
    }

    if (this.hoverId) {
      this.emit(EventType.HOVER_OUT, { id: this.hoverId }, this, e);
    }

    this.hoverId = id;
    this.emit(EventType.HOVER, { id }, this, e);
  };

  private handleHighlightClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (isHighlightWrapNode(target)) {
      const id = getHighlightId(target, this.options.root as HTMLElement);
      this.emit(EventType.CLICK, { id }, this, e);
    }
  };

  private readonly handleSelection = () => {
    const range = HighlightRange.fromSelection(this.hooks.Render.UUID);
    if (range) {
      // @ts-expect-error
      this.highlightFromHighlightRange(range);
      HighlightRange.removeDOMRange();
    }
  };

  private handleError = (type: {
    type: ERROR;
    detail?: HighlightSource;
    error?: unknown;
  }) => {
    if (this.options.verbose) {
      console.warn(type);
    }
  };

  private highlightRangeSource = (
    range: HighlightRange,
    pageNumber: number,
  ): HighlightSource | null => {
    const root =
      document.querySelector<HTMLDivElement>(
        `div[data-page-number="${pageNumber}"]`,
      ) || document.body;
    const source = range.serialize(root, this.hooks);
    return source;
  };

  private highlightFromHighlightRange = (
    range: HighlightRange,
    pageNumber: number,
  ): HighlightSource | null => {
    const root =
      document.querySelector<HTMLDivElement>(
        `div[data-page-number="${pageNumber}"]`,
      ) || document.body;
    const source = range.serialize(root, this.hooks);
    const wraps = this.painter.highlightRange(range);
    if (wraps.length === 0) {
      eventEmitter.emit(INTERNAL_ERROR_EVENT, {
        type: ERROR.DOM_SELECTION_EMPTY,
      });
      return null;
    }

    this.cache.save(source);
    this.emit(
      EventType.CREATE,
      { sources: [source], type: CreateFrom.INPUT },
      this,
    );
    return source;
  };

  private highlightFromHighlightSource = (
    source: HighlightSource | HighlightSource[],
    pageNumber: number,
  ): void => {
    const highlightedSources = this.painter.highlightSource(source, pageNumber);
    this.emit(
      EventType.CREATE,
      { sources: highlightedSources, type: CreateFrom.STORE },
      this,
    );
    this.cache.save(highlightedSources);
  };
}

export default Highlight;
