import { PdfReaderConstants } from "@/modules/pdf-reader";
import {
  AnyEventFn,
  ComplexEventEmitter,
  EventFn,
  EventMap,
  TPos,
} from "./types";
import { EventEmitter } from "events";
import {
  ChartElement,
  ElementType,
  GetRelatedChartsResponseByAdapt,
  RecommendElement,
  singleton,
} from "@deeplang/shared";
import { EventMessage } from "./constants";
import { tour } from "./src/components/CTour";
import { transformBboxToRect } from "@/utils";

type CachePage = EventMap["PageRenderCompleted"];
class RelatedChartsSDK {
  private _elementList: (RecommendElement & ChartElement)[] = [];
  private _allRelatedCharts: GetRelatedChartsResponseByAdapt[] = [];
  private _eventBus: ComplexEventEmitter | null = null;
  private _activeElement: (RecommendElement & ChartElement) | null = null;
  public NAME = "相关图表";
  public name = "relatedCharts";
  private _firstElement: (RecommendElement & ChartElement) | null = null;

  private _cacheForPageHasRendered = new Map<number, CachePage>();

  private recommendedButtons = new Map<string, HTMLDivElement>();

  get activeElement() {
    return this._activeElement;
  }

  getFirstHasRelatedChartsElement(
    elementList: (RecommendElement & ChartElement)[],
    allRelatedCharts: GetRelatedChartsResponseByAdapt[],
  ) {
    for (let i = 0; i < elementList.length; i++) {
      const element = elementList[i];
      if (!element) {
        continue;
      }
      for (let j = 0; j < allRelatedCharts.length; j++) {
        const relatedCharts = allRelatedCharts[j];
        if (!relatedCharts) {
          continue;
        }

        // 查找到当前最前面的 pdf 的推荐入口中存在所有的推荐图表中
        // 且该推荐入口含有推荐图表
        if (
          relatedCharts.element_id === element.element_id &&
          relatedCharts.recommend_elements.length !== 0
        ) {
          this._firstElement = element;
          return element;
        }
      }
    }

    return null;
  }

  getFirstElementBtn(firstElement: RecommendElement & ChartElement) {
    return this.recommendedButtons.get(firstElement.element_id) || null;
  }

  // 这里存在一个时机问题，是页面先渲染完，还是推荐的数据先拉下来。目前来看，推荐的数据要靠后一些，
  // 所以在推荐的数据更新之后，需要对现有的 button，进行重新设置
  updateAllRelatedCharts(allRelatedCharts: GetRelatedChartsResponseByAdapt[]) {
    this._allRelatedCharts = allRelatedCharts;
    this.recommendedButtons.forEach((value, elementId) => {
      const relateChartsCount =
        this._allRelatedCharts.find((item) => item.element_id === elementId)
          ?.recommend_elements.length || 0;
      const span = value.childNodes[1];
      if (!span) {
        return;
      }

      (span as HTMLSpanElement).innerText = `${relateChartsCount}个相关图表`;
    });
  }

  updateActiveElement(element: RecommendElement & ChartElement) {
    this._activeElement = element;
  }

  updateElementList(elementList: (RecommendElement & ChartElement)[]) {
    this._elementList = elementList;
    if (
      this._elementList.length !== 0 &&
      this._cacheForPageHasRendered.size !== 0
    ) {
      this._cacheForPageHasRendered.forEach((item) => {
        const { pageNumber, pageScape } = item;
        this.renderBtnByPageRendered(pageNumber, pageScape);
      });
      this._cacheForPageHasRendered.clear();
    }
  }

  private findElementsInCurrentPageNumber(pageNumber: number) {
    const foundElements: (RecommendElement & ChartElement)[] = [];
    this._elementList.forEach((element) => {
      const { element_pos } = element;
      const { page_num } = element_pos;
      if (pageNumber === page_num + 1) {
        foundElements.push(element);
      }
    });

    return foundElements;
  }

  private renderBtnByPageRendered(
    pageNumber: number,
    pageScape: CachePage["pageScape"],
  ) {
    const pageContainer = document.querySelector<HTMLDivElement>(
      `div[data-page-number="${pageNumber}"]`,
    );

    if (!pageContainer) {
      Promise.reject(new Error(`${pageNumber} PageContainer not found`));
      return;
    }

    // 页面在渲染结束之后，可能还没有当前文件的链接数据 elementList，就不会创建 button。
    // 所以这里 foundElements 可能为空数组
    const foundElements = this.findElementsInCurrentPageNumber(pageNumber);
    foundElements.forEach((foundElement) => {
      const { element_pos, element_id } = foundElement;
      const { bbox } = element_pos;

      const rect = transformBboxToRect(bbox, pageScape);
      const pos = {
        x: rect.x1 + rect.width,
        y: rect.y1,
      };

      const relateCharts =
        this._allRelatedCharts.find(
          (item) => item.element_id === foundElement.element_id,
        )?.recommend_elements || [];
      const relateChartsCount = relateCharts.length;
      if (relateChartsCount === 0) {
        return;
      }
      const button = this.createRecommendedButton(
        pos,
        relateChartsCount,
        foundElement,
        relateCharts,
      );
      // pageContainer.appendChild(button);
      // this.recommendedButtons.set(element_id, button);
    });
  }

  private eventsMap: {
    [key in keyof EventMap]?: Parameters<AnyEventFn<key>>[1];
  } = {
    [PdfReaderConstants.EventMessage.PageRenderCompleted]: (data) => {
      const { pageNumber, pageScape } = data;

      // 某一页面渲染之后
      if (this._elementList.length === 0) {
        this._cacheForPageHasRendered.set(pageNumber, data);
      }

      this.renderBtnByPageRendered(pageNumber, pageScape);

      if (tour.config.hasCreated) {
        return;
      }
      const button = this.recommendedButtons.get(
        this._firstElement?.element_id || "",
      );
      if (!button) {
        return;
      }
      tour.config.mountNode = button;
      tour.createTour();
      tour.config.hasCreated = true;
    },
  };

  destroy() {
    this._firstElement = null;
    this._activeElement = null;
    this._cacheForPageHasRendered.clear();
    this.recommendedButtons.forEach((value) => {
      value.parentNode?.removeChild(value);
    });
    this.recommendedButtons.clear();
    this._eventBus?.removeAllListeners();
    this._eventBus = null;
  }

  private createRecommendedButton(
    pos: TPos,
    relateChartsCount: number,
    activeElement: RecommendElement & ChartElement,
    relateCharts: (RecommendElement & ChartElement)[],
    scale = 1,
  ) {
    const { x, y } = pos;
    const button = document.createElement("div");
    const image = document.createElement("img");
    const span = document.createElement("span");
    image.src =
      "https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/lingoreader/icons/chart-icon.svg";
    image.height = 12;
    image.width = 12;
    image.alt = "related";

    button.onclick = () => {
      this._activeElement = activeElement;
      this._eventBus?.emit(EventMessage.ActiveElementChanged, {
        activeElement,
        elementList: relateCharts,
      });
    };

    button.style.position = "absolute";
    button.style.left = x + "px";
    button.style.top = y + "px";
    button.style.width = "auto";
    button.style.zIndex = "5";
    button.style.minWidth = "20px";
    button.style.minHeight = "20px";
    button.style.height = "20px";
    button.style.padding = "3px";
    button.style.display = "inline-flex";
    button.style.alignItems = "center";
    button.style.gap = "4px";
    button.style.borderRadius = "6px";
    button.style.border = "1px solid #929292";
    button.style.background =
      "linear-gradient(136deg, #464646 10%, #101010 91.27%)";
    button.style.boxShadow = "2px 2px 4px -4px rgba(0, 0, 0, 0.35)";
    button.style.cursor = "pointer";
    button.style.color = "white";
    button.style.fontSize = "14px";
    button.style.fontWeight = "400";
    button.style.fontStyle = "normal";
    button.style.boxSizing = "border-box";
    // button.style.whiteSpace = 'nowrap';

    span.style.display = "none";
    button.appendChild(image);
    button.appendChild(span);

    button.onmouseenter = () => {
      span.innerText = `${relateChartsCount}个相关图表`;
      span.style.display = "-webkit-box";
      span.style["webkitLineClamp"] = "1";
      span.style.textOverflow = "ellipsis";
      span.style["webkitBoxOrient"] = "vertical";
      span.style.overflow = "hidden";
      button.style.minWidth = "6rem";
    };
    button.onmouseleave = () => {
      span.innerText = "";
      span.style.display = "none";
      button.style.minWidth = "20px";
    };

    button.onmousedown = () => {
      button.style.background =
        "linear-gradient(136deg, #101010 10%, #464646 91.27%)";
    };
    button.onmouseup = () => {
      button.style.background =
        "linear-gradient(136deg, #464646 10%, #101010 91.27%)";
    };

    return button;
  }
  private _registerEventListeners(eventBus: ComplexEventEmitter) {
    for (const key in this.eventsMap) {
      const eventName = key as keyof EventMap;
      const handler = this.eventsMap[eventName] as Parameters<EventFn>[1];
      eventBus.on(eventName, handler);
    }
  }
  initial(thirdParty: { eventBus: EventEmitter }) {
    const eventBus = thirdParty.eventBus as ComplexEventEmitter;
    this._eventBus = eventBus;
    this._registerEventListeners(eventBus);
  }
}

const SingleRelatedChartsSDK = singleton(RelatedChartsSDK);
const relatedCharts = new SingleRelatedChartsSDK();

export default relatedCharts;

class ChartsAdapter {
  from<T extends RecommendElement>(element: T): T & ChartElement {
    if (element.element_type === ElementType.Image) {
      return {
        ...element,
        name: element.figure?.figure_caption || "",
        content: element.figure?.figure_url || "",
      };
    }

    if (element.element_type === ElementType.Table) {
      return {
        ...element,
        name: element.table?.table_caption || "",
        content: element.table?.table_url || "",
      };
    }

    Promise.reject(new Error(`Invalid element type ${element.element_type}`));

    return {
      ...element,
      name: "",
      content: "",
    };
  }
}

const SingleChartsAdapter = singleton(ChartsAdapter);
export const chartsAdapter = new SingleChartsAdapter();
