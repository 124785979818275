import { ITable } from "@deeplang/shared";
import { ChartItem, ChartItemProps } from "./ChartItem";

type TableProps = Omit<ChartItemProps, "children" | "chartTitle"> & {
  table: ITable;
};
export function Table(props: TableProps) {
  const { table } = props;

  const Main = (
    <img src={table.table_url} style={{ width: "100%" }} alt="table picture" />
  );
  return (
    <ChartItem {...props} chartTitle={table.table_caption}>
      {Main}
    </ChartItem>
  );
}
