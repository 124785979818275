import { useEffect, useRef, useState } from "react";

interface UseMoveToolbarByScrollContainerProps {
  isToolbarVisibleRef: React.MutableRefObject<boolean>;
  toolbarRef: React.RefObject<HTMLDivElement | null>;
}
export function useMoveToolbarByScrollContainer(
  props: UseMoveToolbarByScrollContainerProps,
) {
  const { toolbarRef, isToolbarVisibleRef } = props;
  const pdfViewContainerRef = useRef<HTMLDivElement | null>(null);
  const currentToolbarScrollTopRef = useRef(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = (e: Event) => {
    setIsScrolled(true);

    if (!(e.target instanceof HTMLDivElement)) {
      return;
    }

    if (e.target !== pdfViewContainerRef.current) {
      return;
    }

    if (!isToolbarVisibleRef.current) {
      return;
    }

    if (!toolbarRef.current) {
      return;
    }

    const distance =
      currentToolbarScrollTopRef.current -
      pdfViewContainerRef.current.scrollTop;
    const { top } = toolbarRef.current.getBoundingClientRect();

    // 这里的 104 = pdf toolbar 的高度 + 网页 header 的高度
    if (top > 104) {
      toolbarRef.current.style.visibility = "visible";
    } else {
      toolbarRef.current.style.visibility = "hidden";
    }

    toolbarRef.current.style.transform = `translateY(${distance}px)`;
  };

  useEffect(() => {
    pdfViewContainerRef.current = document.getElementById(
      "pdf-view-container",
    ) as HTMLDivElement | null;
    pdfViewContainerRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      pdfViewContainerRef.current?.removeEventListener("scroll", handleScroll);
      pdfViewContainerRef.current = null;
    };
  }, []);

  return {
    isScrolled,
    setIsScrolled,
    pdfViewContainerRef,
    currentToolbarScrollTopRef,
  };
}
