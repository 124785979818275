import { useState, useEffect } from "react";
import { Popover, Dropdown, MenuProps, message, Tooltip } from "@deeplang/dui";
import cn from "classnames";

import { BATCH } from "@/batch/type";
import buryHatchet from "@/batch/index";
import { SummaryType } from "./constants";
import { KEYWORD_SEARCH } from "./constants";
import { MESSAGE_TIP } from "./constants";

import TagPanel from "./components/tag-panel";
import CloseModal from "./components/close-modal";

import { colorDescription, loadImage } from "./util";

import styles from "./Toolbar.module.css";
import usePage from "./hooks/usePage";
import { ColorType, ExtractType, FirstChar, ToolbarProps } from "./types";
import useCommunication from "./hooks/useCommunication";
import { getLoginStatus } from "@/packages/passport";
import { useApi } from "./hooks/useApi";
import { useFloatToolbarStore } from "./store";
import { ITag, ReadingSource } from "@deeplang/shared";
import {
  CloseCircle,
  Divider,
  PencilColor,
  Query,
  SolidCircle,
  SolidTag,
  Tag,
  Trash,
  ShareFat,
  NoteIcon,
  NoteLined,
  FatShareLined,
} from "@deeplang/icons";
import { EventMessage } from "@/utils/event-bus";
import {
  EXTRACT_COLOR_1,
  EXTRACT_COLOR_2,
  EXTRACT_COLOR_3,
} from "@/constant/color";
import NoteInput from "@/components/NoteInput";
import useExtract, { EXTRACT_MESSAGE } from "@/client/hooks/useExtract";
import { to } from "@deeplang/shared";
import { useParams } from "next/navigation";

const COLORS: ColorType[] = [
  {
    key: "1",
    color: EXTRACT_COLOR_1,
    name: "黄色",
  },
  {
    key: "2",
    color: EXTRACT_COLOR_2,
    name: "红色",
  },
  {
    key: "3",
    color: EXTRACT_COLOR_3,
    name: "绿色",
  },
];

// 图片摘录最大10M
const MAX_IMAGE_SIZE = 1024 * 1024 * 10;

export default function WithToolbar(props: ToolbarProps) {
  const {
    onClose,
    setToolbarVisible,
    syncPluginSettings,
    options = { hideCloseButton: false },
  } = props;
  const { hideCloseButton } = options;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showCloseModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const CloseModalComponent = (
    <>
      {isModalOpen && (
        <CloseModal
          toolbarType="toolbar"
          title="关闭浮动工具栏"
          open={isModalOpen}
          onOk={(type: string) => {
            setIsModalOpen(false);
            onClose?.();

            // save status to server
            if (type === "forever") {
              setToolbarVisible(false);
              syncPluginSettings && syncPluginSettings(1, 0);
            }
          }}
          onCancel={() => {
            setIsModalOpen(false);
            // onClose?.();
          }}
        />
      )}
    </>
  );
  const CloseComponent = (
    <div className={styles.close} onClick={showCloseModal}>
      <CloseCircle />
    </div>
  );
  return (
    <>
      {hideCloseButton ? null : { CloseModalComponent, CloseComponent }}
      <Toolbar {...props} />
    </>
  );
}

const items: MenuProps["items"] = COLORS.map(({ key, name, color }) => ({
  key,
  label: (
    <div className={styles["color-item"]}>
      <SolidCircle fill={color} stroke={color} />{" "}
      <span style={{ whiteSpace: "nowrap" }}>{name}</span>
    </div>
  ),
}));

function Toolbar(props: ToolbarProps) {
  const {
    id: extractItemId,
    type,
    url,
    extract,
    prevColor = 1,
    includeImg = false,
    onAdd,
    onRemove,
    onUpdate,
    communication,
    thirdParty,
    options = {
      isDisableSearch: false,
      hideCloseButton: false,
    },
    fileId = "",
    channelType = ReadingSource.LingoPdf,
    toolbar,
    noteOpenChange,
    onShare,
    onCancelNote,
    updateExtractData,
    addStatus,
  } = props;

  const params = useParams();

  const [isShowPopover, setIsShowPopover] = useState(false);
  const [color, setColor] = useState(COLORS[prevColor - 1]);
  const pageInfo = usePage(SummaryType.Paragraph);
  const { openRead, openLibrary } = useCommunication(communication);
  const { PubSub, debounce, eventBus } = useFloatToolbarStore();
  const { isDisableSearch } = options;
  const { addPdfExtract, updateExtract, removeExtract } = useApi();
  const [openNote, setOpenNote] = useState(false);

  useEffect(() => {
    noteOpenChange?.(openNote);
  }, [openNote]);

  useEffect(() => {
    if (addStatus) {
      setOpenNote(true);
    } else {
      setOpenNote(false);
    }
  }, [addStatus]);

  useEffect(() => {
    if (extract?.color) {
      const findColor = COLORS.find(
        (item) => item.key === extract.color.toString(),
      );
      setColor(findColor!);
    }
  }, [extract]);

  const handleRemoveExtract = async (id: string) => {
    try {
      await removeExtract(id);
      onRemove?.();
      eventBus.emit(EventMessage.EXTRACT_REMOVE, extract);
      message.success("删除摘录成功");
    } catch (err: any) {
      console.error(err);
      message.error(err.msg);
    }
  };

  const handleAddExtract = async (options: {
    type: ExtractType;
    color: ColorType;
    note?: string;
    isNote?: boolean;
    isShare?: boolean;
  }) => {
    const { type, color, note = "", isNote = false, isShare = false } = options;
    const data = {
      ...pageInfo,
      color: parseInt(color.key, 10),
      type: 0,
      tags: [],
      note,
      isNote,
      isShare,
    };

    // TODO: 登录态获取方式
    const status = await getLoginStatus();
    if (!status) {
      eventBus.emit(EventMessage.EXTRACT_CHANGE_NO_LOGIN, {
        params: JSON.parse(JSON.stringify(data)),
        cb: addPdfExtract,
      });
      return;
    }

    if (type === "text") {
      await onAdd?.(data);
      if (isNote) {
        setOpenNote(true);
      }
      return;
    }
  };

  const handleUpdateExtract = async (
    id: string,
    options: { color?: number; tags?: ITag[]; note?: string },
  ) => {
    try {
      await updateExtract(id, options);
      setOpenNote(false);
      if (options.note) {
        message.success(EXTRACT_MESSAGE.NOTE_UPDATE_SUCCESS);
      } else {
        message.success(EXTRACT_MESSAGE.UPDATE_SUCCESS);
      }
      updateExtractData?.({ ...extract, ...options });
      eventBus.emit(EventMessage.EXTRACT_UPDATE, {
        ...extract,
        ...options,
      });
    } catch (err: any) {
      message.error(err.msg);
    }
  };

  const handleQuerySelection = () => {
    const selection = window.getSelection();
    if (!selection) {
      return;
    }
    const query = selection.toString();
    openLibrary({
      query,
    });
    PubSub.publish(KEYWORD_SEARCH, query);
  };

  const onClick: MenuProps["onClick"] = async ({ key }) => {
    const status = await getLoginStatus();
    if (!status) {
      eventBus.emit(EventMessage.NO_LOGIN_CALLBACK);
      return;
    }

    const colorKey = key[0] as FirstChar<typeof key>;
    const findColor = COLORS.find((item) => item.key === colorKey) as ColorType;
    setColor(findColor);

    const extractId = extract?.extract_id || extract?.extractId;
    if (!extractId) {
      await handleAddExtract({ type, color: findColor });
      return;
    }
    handleUpdateExtract(extractId, {
      color: parseInt(findColor!.key, 10),
    });
    onUpdate?.({
      color: parseInt(findColor!.key, 10),
    });
  };

  // 概述
  const handleRead = debounce(() => {
    const selection = window.getSelection();
    if (!selection) {
      return;
    }
    const query = selection.toString();
    if (query.trim().length >= 200) {
      openRead({
        summaryType: SummaryType.Paragraph,
      });

      setTimeout(() => {
        eventBus.emit(EventMessage.SUMMARY_GENERATE, {
          query,
          metaInfo: JSON.stringify({
            pageNumber: toolbar?.config.currentPageNumber || 1,
          }),
        });
      }, 500);
    } else {
      message.error("选中文本长度过短，无法生成概述");
    }
  }, 500);

  const onShareHandler = (options?: any) => {};

  const onNoteClick = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const status = await getLoginStatus();
    if (!status) {
      eventBus.emit(EventMessage.NO_LOGIN_CALLBACK);
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    setOpenNote(true);
  };

  const extractId = extract?.extractId || extract?.extract_id || extractItemId;
  if (extractId) {
    return (
      <div className={styles["tool-bar"]}>
        <div
          className={cn(styles["item"], styles["pencil"])}
          onClick={async () => {
            const status = await getLoginStatus();
            if (!status) {
              eventBus.emit(EventMessage.NO_LOGIN_CALLBACK);
              return;
            }
            handleRemoveExtract(extractId);
          }}
        >
          <Trash fill="#2D2D2D" />
        </div>
        <Divider
          direction={"vertical"}
          height={22}
          width={1}
          fill={"#D9D9D9"}
        />
        <Dropdown
          menu={{ items, onClick }}
          placement="bottomRight"
          align={{ offset: [45, 8] }}
          getPopupContainer={(node: ParentNode) => node as HTMLElement}
        >
          <div className={cn(styles["item"], "px-[2px]")}>
            <SolidCircle fill={color?.color} stroke={color?.color} />
          </div>
        </Dropdown>
        <Divider
          direction={"vertical"}
          height={22}
          width={1}
          fill={"#D9D9D9"}
        />
        <Popover
          align={{ offset: [0, 8] }}
          arrow={false}
          overlayInnerStyle={{ padding: 0 }}
          trigger={"click"}
          autoAdjustOverflow
          open={openNote}
          placement="bottomRight"
          color="white"
          content={
            <NoteInput
              value={extract?.note}
              onCancel={() => {
                onCancelNote();
                setOpenNote(false);
              }}
              onSave={(value) => {
                handleUpdateExtract(extractId, {
                  note: value,
                  tags: extract?.tags,
                  color: extract?.color,
                });
              }}
            />
          }
          getPopupContainer={(node) => node as HTMLElement}
        >
          <NoteLined
            className="cursor-pointer text-lg px-[4px]"
            onClick={onNoteClick}
          />
        </Popover>
        <Divider
          direction={"vertical"}
          height={22}
          width={1}
          fill={"#D9D9D9"}
        />
        <div className={cn(styles["item"])}>
          <FatShareLined
            className="text-lg"
            onClick={() => onShare?.(extract)}
          />
        </div>
        <Divider
          direction={"vertical"}
          height={22}
          width={1}
          fill={"#D9D9D9"}
        />
        <Popover
          content={
            <TagPanel
              close={() => {
                setIsShowPopover(false);
              }}
              id={extractId}
              channelType={channelType}
              extract={extract}
              thirdParty={thirdParty}
              updateExtract={(tags: ITag[]) => {
                handleUpdateExtract(extractId, {
                  tags,
                });
                onUpdate?.({
                  tags,
                });
              }}
            />
          }
          align={{ offset: [135, 8] }}
          overlayInnerStyle={{ padding: 0 }}
          arrow={false}
          open={isShowPopover}
          trigger="click"
          placement="bottomRight"
          color="white"
          getPopupContainer={(node: ParentNode) => node as HTMLElement}
        >
          <div
            className={styles["item"]}
            onClick={async () => {
              const status = await getLoginStatus();
              if (!status) {
                eventBus.emit(EventMessage.NO_LOGIN_CALLBACK);
                return;
              }
              setIsShowPopover(true);
            }}
          >
            {extract?.tags.length ? (
              <SolidTag />
            ) : (
              <Tag color="#2D2D2D" size={18} />
            )}
            {/* <span>标签</span> */}
          </div>
        </Popover>
      </div>
    );
  }

  const queryComponent = (
    <div className={styles["item"]} onClick={handleQuerySelection}>
      <Query />
    </div>
  );
  if (type === "text") {
    return (
      <div className={styles["tool-bar"]}>
        {includeImg ? (
          <div
            className={cn(styles["item"], {
              [styles.disabled || ""]: includeImg,
            })}
          >
            <PencilColor fill={color?.color} pencilFill="#2D2D2D" />
            <span>摘录</span>
          </div>
        ) : (
          <Dropdown
            menu={{ items, onClick }}
            placement="bottom"
            mouseEnterDelay={0.8}
            align={{ offset: [5, 8] }}
            getPopupContainer={(node: ParentNode) => node as HTMLElement}
          >
            <div
              className={cn(styles["item"])}
              onClick={(e) => {
                e.stopPropagation();
                color && handleAddExtract({ type: "text", color });
              }}
            >
              <PencilColor fill={color?.color} pencilFill="#2D2D2D" />
              {/* <span>摘录</span> */}
            </div>
          </Dropdown>
        )}
        <Divider
          direction={"vertical"}
          height={22}
          width={1}
          fill={"#D9D9D9"}
        />
        <div
          className={cn(styles["item"], styles["pencil"])}
          onClick={(e) => {
            e.stopPropagation();
            color && handleAddExtract({ type: "text", color, isNote: true });
          }}
        >
          <NoteIcon fill="#2D2D2D" />
        </div>
        <Divider
          direction={"vertical"}
          height={22}
          width={1}
          fill={"#D9D9D9"}
        />
        <div
          className={cn(styles["item"], styles["pencil"])}
          onClick={async () => {
            handleAddExtract({
              type: "text",
              color: color as ColorType,
              isShare: true,
            });
          }}
        >
          {/* <FatShareLined className="text-lg" /> */}
          <ShareFat fill="#2D2D2D" size={22} />
        </div>

        {/* TODO: 概述功能暂时关闭 */}
        {/* {getSelectionTextLength() < 200 ? (
          <Tooltip
            title="字数过少"
            getPopupContainer={(node: ParentNode) =>
              node.parentNode?.parentNode as HTMLElement
            }
            arrow={false}
          >
            <div className={cn(styles["item"], styles.disabled)}>
              <Quotes fill={"#2D2D2D"} /> <span>概述1</span>
            </div>
          </Tooltip>
        ) : (
          <div className={styles["item"]} onClick={handleRead}>
            <Quotes fill={"#2D2D2D"} /> <span>概述2</span>
          </div>
        )} */}
        {isDisableSearch ? null : queryComponent}
      </div>
    );
  }
  return (
    <div className={styles["tool-bar"]}>
      <Dropdown
        menu={{ items, onClick }}
        placement="bottomRight"
        mouseEnterDelay={0.8}
        getPopupContainer={(node: ParentNode) => node as HTMLElement}
      >
        <div
          className={cn(styles["item"])}
          onClick={(e) => {
            e.stopPropagation();
            color && handleAddExtract({ type: "image", color });
          }}
        >
          <PencilColor fill={color?.color} pencilFill="#2D2D2D" />
          <span>摘录</span>
        </div>
      </Dropdown>
      <Divider direction={"vertical"} height={22} width={1} fill={"#D9D9D9"} />
      <Popover
        align={{ offset: [0, 8] }}
        arrow={false}
        overlayInnerStyle={{ padding: 0 }}
        trigger={"hover"}
        autoAdjustOverflow
        open={openNote}
        placement="bottomRight"
        color="white"
        content={
          <NoteInput
            value={extract?.note}
            onCancel={() => {
              onCancelNote();
              setOpenNote(false);
            }}
            onSave={(value) => {
              handleAddExtract({
                type: "text",
                color: color as ColorType,
                note: value,
              });
            }}
          />
        }
        getPopupContainer={(node) => node as HTMLElement}
      >
        <NoteLined
          className="cursor-pointer text-lg"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpenNote(true);
          }}
        />
      </Popover>
      <Divider direction={"vertical"} height={22} width={1} fill={"#D9D9D9"} />
      <div className={cn(styles["item"])}>
        <FatShareLined onClick={() => onShareHandler({ type: "text" })} />
      </div>
    </div>
  );
}
