import { createContext } from "react";

import { Maybe } from "@deeplang/shared";
import { IRelatedCharts } from "../types";
import { useStore } from "./all-store";

export interface Options {
  children?: React.ReactNode;
  defaultParams: IRelatedCharts;
}

export const RelatedChartsContext =
  createContext<Maybe<IRelatedCharts & ReturnType<typeof useStore>>>(null);

export const RelatedChartsProvider = (options: Options) => {
  const { children, defaultParams } = options;
  const store = useStore(defaultParams);

  return (
    <RelatedChartsContext.Provider value={{ ...defaultParams, ...store }}>
      {children}
    </RelatedChartsContext.Provider>
  );
};
