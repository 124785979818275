"use client";

import EmptyContent from "@/components/empty-content";
import { eventBus, EventMessage } from "@/utils/event-bus";
import { Close, ClockWise } from "@deeplang/icons";
import {
  AddSearchHistoryRequest,
  DeleteSearchHistoryRequest,
  GetSearchHistoryRequest,
  HistoryType,
} from "@deeplang/shared";

interface HistoryListProps<T = string> {
  historyList: T[];
  addHistory: (data: AddSearchHistoryRequest) => Promise<any>;
  deleteHistory: (data: DeleteSearchHistoryRequest) => Promise<any>;
  getHistories: () => Promise<any>;
  historyType?: HistoryType;
}
const HistoryList = (props: HistoryListProps) => {
  const {
    historyList,
    addHistory,
    deleteHistory,
    historyType = HistoryType.Library,
    getHistories,
  } = props;

  const handleItemClick = async (item: string) => {
    eventBus.emit(EventMessage.SUG_SEARCH, { value: item });
    await addHistory({ query: item, history_type: historyType });
    await getHistories();
  };

  const handleDelete = async (e: any, item: string) => {
    e.stopPropagation();
    await deleteHistory({ query: item, history_type: historyType });
    await getHistories();
  };

  return (
    <div className="w-full flex flex-col h-[480px]">
      <p className="text-third text-base mx-4"></p>
      <div className="w-full max-h-[440px] overflow-auto">
        {historyList.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className="w-full flex flex-row items-center justify-between hover:bg-neutral-3 rounded-lg cursor-pointer mt-2 py-[14px] px-[16px]"
              onClick={() => {
                handleItemClick(item);
              }}
            >
              <div className="history-item flex flex-1 items-center">
                <span className="mr-2">
                  <ClockWise size={16} />
                </span>
                <span className="w-[500px] text-sm text-neutral text-ellipsis overflow-hidden text-nowrap">
                  {item}
                </span>
              </div>
              <span
                className="delete-icon"
                onClick={(e) => {
                  handleDelete(e, item);
                }}
              >
                <Close size={12} />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HistoryList;
