import {
  AddMaterialToLibraryRequest,
  AddMaterialToLibraryResponse,
  AddSearchHistoryRequest,
  CheckCanAddToLibraryRequest,
  CheckCanAddToLibraryResponse,
  DeleteSearchHistoryRequest,
  GetElementListRequest,
  GetElementListResponse,
  GetMaterialDetailRequest,
  GetMaterialDetailResponse,
  GetRelatedChartsRequest,
  GetRelatedChartsResponse,
  GetSearchHistoryResponse,
  HistoryType,
  SearchRelatedElementsRequest,
  SearchRelatedElementsResponse,
} from "@deeplang/shared";
import { useAnyHooksStore } from "@/provider/any-hooks-layout";
import { READER_DETAIL } from "@/constant/api";

export function useApi() {
  const { request } = useAnyHooksStore();

  const getRelatedCharts = (params: GetRelatedChartsRequest) => {
    return request<GetRelatedChartsResponse>("/api/plugin/element/recommend", {
      method: "POST",
      data: params,
    });
  };

  const getHistories = () => {
    return request<GetSearchHistoryResponse>("/api/plugin/queryHistory/list", {
      method: "GET",
      params: {
        history_type: HistoryType.Chart,
      },
    });
  };

  const addHistory = (data: AddSearchHistoryRequest) => {
    const { query, history_type } = data;
    return request("/api/plugin/queryHistory/add/", {
      method: "POST",
      data: {
        history_type,
        query,
      },
    });
  };

  const deleteHistory = (data: DeleteSearchHistoryRequest) => {
    const { query, history_type } = data;
    return request("/api/plugin/queryHistory/delete/", {
      method: "POST",
      data: {
        query,
        history_type,
      },
    });
  };

  const getElementList = (data: GetElementListRequest) => {
    return request<GetElementListResponse>("/api/plugin/element/list", {
      method: "POST",
      data,
    });
  };

  const searchRelatedElements = (data: SearchRelatedElementsRequest) => {
    return request<SearchRelatedElementsResponse>(
      "/api/plugin/element/search",
      {
        method: "POST",
        data,
      },
    );
  };

  const getMaterialDetail = (data: GetMaterialDetailRequest) => {
    return request<GetMaterialDetailResponse>(READER_DETAIL, {
      method: "GET",
      params: data,
    });
  };

  const checkCanAddToLibrary = (data: CheckCanAddToLibraryRequest) => {
    if (!data.file_id) {
      return Promise.reject("file_id is required");
    }
    return request<CheckCanAddToLibraryResponse>(
      "/api/plugin/file/copy_to_personal/can",
      {
        method: "GET",
        params: data,
      },
    );
  };

  const addMaterialToLibrary = (data: AddMaterialToLibraryRequest) => {
    return request<AddMaterialToLibraryResponse>(
      "/api/plugin/file/copy_to_personal/save",
      {
        method: "POST",
        data,
      },
    );
  };
  return {
    getRelatedCharts,
    getHistories,
    addHistory,
    deleteHistory,
    getElementList,
    searchRelatedElements,
    getMaterialDetail,
    checkCanAddToLibrary,
    addMaterialToLibrary,
  };
}
