export interface ApiResponseError extends Error {
  code: number;
  msg: string;
  data: any;
}

/**
 * @param { Promise } promise
 * @param { Object= } errorExt
 * @return { Promise }
 */
export function to<T = any, U = ApiResponseError>(
  promise: Promise<T>,
  errorExt?: object,
): Promise<[U, undefined] | [null, T]> {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[U, undefined]>((err: U) => {
      if (errorExt) {
        const parsedError = Object.assign({}, err, errorExt);
        return [parsedError, undefined];
      }

      return [err, undefined];
    });
}

export default to;
