import { HAS_READ_LIST } from "@/constant/api";
import { AddExtractRequest, AddPdfExtractRequest, DetailRequest, DownloadPdfFileRequest, GetHasReadListRequest, GetHasReadListResponse, UploadOriginTextRequest } from "../type";
import { useAnyHooksStore } from "@/provider/any-hooks-layout";

export function useApi() {
  const { request, fileRequest } = useAnyHooksStore();

  return {
    addExtract(data: AddExtractRequest) {
      const {
        title,
        url,
        tags,
        color,
        metaInfo,
        sourceType,
        author,
        originalContent,
        content,
      } = data;
      return request({
        url: '/api/plugin/extract/add',
        method: 'post',
        data: {
          title,
          url,
          tags,
          color,
          meta_info: metaInfo,
          source_type: sourceType,
          author,
          original_content: originalContent,
          content,
        },
      });
    },
    addPdfExtract(data: AddPdfExtractRequest) {

      const {
        type,
        content,
        tags,
        color,
        metaInfo,
        sourceType,
        fileId,
      } = data;
      return request({
        url: '/api/plugin/extract/add',
        method: 'post',
        data: {
          tags,
          color,
          meta_info: metaInfo,
          source_type: sourceType,
          file_id: fileId,
          content,
          type,
        },
      });
    },

    detail(data: DetailRequest) {

      const { type, keyword, colors, sourceType } = data;

      return request({
        url: '/api/plugin/extract/detail',
        method: 'post',
        data: {
          type,
          keyword,
          colors,
          source_type: sourceType,
        }
      })
    },
    uploadOriginText(params: UploadOriginTextRequest) {
      const convertParam = {
        update_dict: {
          html_content: params.updateDict.htmlContent,
        }
      };
      return request({
        url: '/api/plugin/file/update',
        method: 'POST',
        data: {
          file_id: params.fileId,
          update_dict: JSON.stringify(convertParam.update_dict),
        }
      })
    },

    downloadPdfFile(params: DownloadPdfFileRequest) {
      return fileRequest({
        url: '/api/plugin/file/download',
        params: {
          file_id: params.fileId,
        },
        responseType: 'arraybuffer',
      })
    },
    getHasReadMaterials(data: GetHasReadListRequest) {
      return request<GetHasReadListResponse>({
        url: HAS_READ_LIST,
        method: 'POST',
        data,
      })
    },
  }
}