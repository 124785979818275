import { TAG_LIST, TAG_QUERY } from "@/constant/api";
import request from "@/utils/request";
import { TagItem } from "@deeplang/shared";

export interface GetQueryTagsResponse {
  list: TagItem[];
}
export default {
  list(params?: any) {
    return request({
      url: TAG_LIST,
      method: "get",
      params,
    });
  },
  query(params?: any) {
    return request<GetQueryTagsResponse>({
      url: TAG_QUERY,
      method: "get",
      params,
    });
  },
  add(data: any) {
    return request({
      url: "/api/plugin/tag/create",
      method: "post",
      data,
    });
  },
  create(data: any) {
    return request({
      url: "/api/plugin/tag/create",
      method: "post",
      data,
    });
  },
  update(data: any) {
    return request({
      url: "/plugin/tag/update",
      method: "post",
      data,
    });
  },
  delete(data: any) {
    return request({
      url: "/plugin/tag/delete",
      method: "post",
      data,
    });
  },
};
