import { ISearchModelRef, SearchModal } from "@deeplang/dui";
import {
  ChartElement,
  ChartSource,
  HistoryType,
  Merge,
  RecommendElement,
} from "@deeplang/shared";
import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSearchElementList } from "../hooks/useSearchElementList";
import { History } from "../components/History";
import { ChartList } from "../components/ChartList";
import { useApi } from "../hooks/useApi";
import EmptyContent from "@/components/empty-content";
import "./MSearchModal.css";
import { InitSearch } from "../components/InitSearch";
import SkeletonScreen from "@/components/skeleton-screen";

export interface MSearchModalRef {
  show: ISearchModelRef["show"];
  search: (value: string) => void;
  updateQuery: (value: string) => void;
}

type ComplexTab = Merge<
  Exclude<Parameters<typeof SearchModal>[0]["tabs"]["items"], undefined>[0],
  {
    key: "all" | "library";
  }
>;
export const MSearchModal = forwardRef<MSearchModalRef>((props, ref) => {
  const searchModalRef = useRef<ISearchModelRef | null>(null);
  const [hasSearch, setHasSearch] = useState(false);
  const [query, setQuery] = useState("");
  const { addHistory } = useApi();
  const [searchElementListForLibrary, setSearchElementListForLibrary] =
    useState<(RecommendElement & ChartElement)[]>([]);

  const [activeTab, setActiveTab] = useState<ComplexTab["key"]>("all");
  const activeTabRef = useRef<ComplexTab["key"]>("all");

  const {
    searchElementList,
    getSearchElementList,
    searchElementListRef,
    setSearchElementList,
    isLoading,
  } = useSearchElementList();

  useEffect(() => {
    setSearchElementListForLibrary(
      searchElementListRef.current.filter(
        (item) => item.source === ChartSource.Library,
      ),
    );
  }, [searchElementList]);
  const handleCancel = () => {
    setSearchElementListForLibrary([]);
    setSearchElementList([]);
    searchElementListRef.current = [];
    setQuery("");
    setHasSearch(false);
    searchModalRef.current?.hide();
  };
  useImperativeHandle(ref, () => ({
    show: () => {
      searchModalRef.current?.show();
    },
    search: (value: string) => {
      handleSearch(value);
    },
    updateQuery: (value: string) => {
      setQuery(value);
    },
  }));

  const handleTabClick = (activeKey: string) => {
    const activeTab = activeKey as ComplexTab["key"];
    setActiveTab(activeTab);
    activeTabRef.current = activeTab;
    setHasSearch(false);
    handleSearch(query);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHasSearch(false);
    setQuery(e.target.value);
  };

  const handleSearch = (data?: string) => {
    const keyword = data === undefined ? query : data;
    if (keyword === "") {
      return;
    }
    setHasSearch(true);

    if (activeTabRef.current === "all") {
      getSearchElementList({
        keyword,
      });
      return;
    }
    getSearchElementList({
      keyword,
      search_type: "personal",
    });
  };
  const handleClickSearchBtn = () => {
    addHistory({
      query,
      history_type: HistoryType.Chart,
    });
    handleSearch();
  };

  const handlePressEnter = () => {
    addHistory({
      query,
      history_type: HistoryType.Chart,
    });
    handleSearch();
  };

  const ChartListForLibraryComponent = (
    <ChartList
      chartList={searchElementListForLibrary}
      options={{
        isShowTitle: true,
        showChartItemColCount: 3,
        isShowBorder: true,
        isShowTitleUnderline: false,
        isGoBackOrigin: true,
        openFixedHeightContainer: false,
      }}
    />
  );

  const ChartListForAllComponent = (
    <ChartList
      chartList={searchElementList}
      options={{
        isShowTitle: true,
        showChartItemColCount: 3,
        isShowBorder: true,
        isShowTitleUnderline: false,
        isGoBackOrigin: true,
        openFixedHeightContainer: false,
      }}
    />
  );

  // 区分搜索内容是否为空
  const ContentComponent = (
    <div
      className="flex justify-center"
      style={{ width: "100%", padding: "24px 32px 32px 32px" }}
    >
      {searchElementList.length === 0 ? (
        <EmptyContent />
      ) : (
        ChartListForAllComponent
      )}
    </div>
  );

  const HistoryComponent = (
    <div
      className="flex justify-center"
      style={{ width: "100%", padding: "16px 32px 32px 32px" }}
    >
      <History />
    </div>
  );

  // 区分搜索内容是否为空
  const SearchComponentFilterLibrary = (
    <div
      className="flex justify-center"
      style={{ width: "100%", padding: "24px 32px 32px 32px" }}
    >
      {searchElementListForLibrary.length === 0 ? (
        <EmptyContent />
      ) : (
        ChartListForLibraryComponent
      )}
    </div>
  );

  // 区分是否发起搜索
  const SearchComponentForLibrary = hasSearch ? (
    SearchComponentFilterLibrary
  ) : (
    <div className="flex justify-center w-full pt-6 px-8 pb-8">
      <InitSearch />
    </div>
  );

  // 区分是否发起搜索
  const AllSearchComponent = hasSearch ? ContentComponent : HistoryComponent;

  const LoadingSearchComponent = isLoading ? (
    <SkeletonScreen />
  ) : (
    AllSearchComponent
  );

  // 区分 loading 态
  const LoadingSearchComponentForLibrary = isLoading ? (
    <SkeletonScreen />
  ) : (
    SearchComponentForLibrary
  );
  return (
    <SearchModal
      wrapClassName={"related-chart-search-modal"}
      ref={searchModalRef}
      modal={{
        onCancel: handleCancel,
      }}
      tabs={{
        activeKey: activeTab,
        onTabClick: handleTabClick,
        items: [
          {
            label: <span className="text-base">综合</span>,
            key: "all",
            children: LoadingSearchComponent,
          },
          {
            label: <span className="text-base">个人库</span>,
            key: "library",
            children: LoadingSearchComponentForLibrary,
          },
        ] as ComplexTab[],
      }}
      input={{
        onChange: handleInputChange,
        placeholder: "搜索更多图表",
        onPressEnter: handlePressEnter,
        value: query,
        allowClear: true,
      }}
      handleClickSearchBtn={handleClickSearchBtn}
    />
  );
});

MSearchModal.displayName = "MSearchModal";
