"use client";

import { RelatedChartsProvider } from "./store";
import { IRelatedCharts, IRelatedChartsRef, ThirdParty } from "./types";
import { checkIsThirdPartyAvailability } from "./utils";
import { Merge } from "@deeplang/shared";
import { RelatedCharts } from "./src";
import { forwardRef } from "react";

type WrapRelatedChartsProps = Merge<
  Omit<IRelatedCharts, "relatedChartsRef">,
  {
    thirdParty?: ThirdParty;
  }
>;

const WrapRelatedCharts = forwardRef<IRelatedChartsRef, WrapRelatedChartsProps>(
  (props: WrapRelatedChartsProps, ref) => {
    const {
      thirdParty,
      material,
      activeElementRef,
      defaultElementListRef,
      defaultChartListCollapsedRef,
    } = props;

    if (!thirdParty) {
      return null;
    }

    const result = checkIsThirdPartyAvailability(thirdParty);
    if (!result) {
      return null;
    }

    return (
      <RelatedChartsProvider
        defaultParams={{
          thirdParty,
          material,
          relatedChartsRef: ref,
          activeElementRef,
          defaultElementListRef,
          defaultChartListCollapsedRef,
        }}
      >
        <RelatedCharts />
      </RelatedChartsProvider>
    );
  },
);

export default WrapRelatedCharts;

WrapRelatedCharts.displayName = "WrapRelatedCharts";
