import Image from "next/image";

import styles from "./no-render.module.css";

interface NoRenderProps {
  handleClick: () => void;
}
export function NoRender(props: NoRenderProps) {
  const { handleClick } = props;

  return (
    <div className={styles.container}>
      <Image
        src={
          "https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/lingoreader/pics/norender.png"
        }
        alt="no material render"
        width={240}
        height={266}
      />

      <div className={styles.noRenderDescriptionContainer}>
        <span>
          加载失败了，
          <span className={styles.refresh} onClick={handleClick}>
            刷新页面
          </span>
          试试吧
        </span>
      </div>
    </div>
  );
}
