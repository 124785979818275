import buryHatchet from "@/batch";
import { BATCH } from "@/batch/type";
import { UploadFileItem } from "@/modules/upload/types";
import { useRef } from "react";

export function useGetBatchMultiUploadToSummary() {
  const track = buryHatchet.track<BATCH.TModuleName>("Multi");

  const uploadTimeRef = useRef("");
  const uploadTraceId = useRef("");
  const summaryTraceId = useRef("");
  const mergeFinishTimerRef = useRef("");

  const uploadToSummaryBatch = () => {
    track(BATCH.EventName.LG_MultiFile_Summary_Cost, {
      channel: "灵狗-web",
      trace_id: summaryTraceId.current,
      start_time: uploadTimeRef.current,
      merge_finish_time: mergeFinishTimerRef.current,
    });
  };

  const isFirstUploaded = (files: UploadFileItem[]) => {
    // 是否为首次上传成功
    return !files.some((file) => file.options?.uploaded);
  };

  const updateUploadTimeRef = (time: string) => {
    uploadTimeRef.current = time;
  };
  const getUploadTimeRef = () => uploadTimeRef.current;

  const updateUploadTraceId = (traceId: string) => {
    uploadTraceId.current = traceId;
  };

  const getUploadTraceId = () => uploadTraceId.current;

  const updateSummaryTraceId = (traceId: string) => {
    summaryTraceId.current = traceId;
  };

  const getSummaryTraceId = () => summaryTraceId.current;

  const updateMergeFinishTimerRef = (time: string) => {
    mergeFinishTimerRef.current = time;
  };

  return {
    uploadToSummaryBatch,
    updateUploadTimeRef,
    getUploadTimeRef,
    updateUploadTraceId,
    getUploadTraceId,
    updateSummaryTraceId,
    getSummaryTraceId,
    updateMergeFinishTimerRef,
    isFirstUploaded,
  };
}
