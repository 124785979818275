export enum TagStatus {
  Normal,
  Deleted,
}

export enum MESSAGE_TIP {
  // 摘录失败
  EXTRACT_ERR = '你所选中的内容目前无法摘录，请尝试摘录其他内容',
  // 内容最小长度限制
  ARGS_AGC_MIN_ERROR = '当前内容不足200，暂无法准确为您生成内容，试试选择其他部分内容试试吧',
  // 内容最大长度限制
  ARGS_AGC_MAX_ERROR = '当前内容超过2万字，暂无法准确为您生成内容，试试选择其中部分内容试试吧',
  // 概述生成信息敏感
  ARGS_AGC_SENSITIVE_ERROR = '文章信息敏感',
  // 生成输入的文本内容敏感
  ARGS_AGC_INPUT_SENSITIVE_ERROR = '当前页面暂不支持生成摘要，请换个内容重新尝试。',
  // 操作过于频繁
  SPIDER_ERROR = '操作过于频繁，稍后再试吧☕',
  // 生成成功
  GENERATE_SUCCESS = '已生成完毕',
  // 额度申请到账
  ACCEPT_AMOUT = '你的新额度已到账',
  // 点赞成功
  LIKE_SUCCESS = '感谢您的反馈',
  // 取消点赞成功
  CANCEL_LIKE_SUCCESS = '取消成功',
  // // 点踩成功
  // DISLIKE_SUCCESS = '感谢您的反馈',
  // 内容反馈成功
  FEEDBACK_SUCCESS = '收到您的反馈，谢谢',
  // 复制成功
  COPY_SUCCESS = '已复制到粘贴板',
  // 分享成功
  SHARE_SUCCESS = '分享内容已复制到剪贴板',
  // 创建标签失败
  CREATE_TAG_ERR = '无法创建标签名，换一个试试',
  // 请求失败
  REQUEST_ERR = '灵狗正在忙碌，稍等几分钟再重试吧~',
  // 请求失败再试试
  REQUEST_FAIL_TRY = '请求异常，稍后再试试吧',
  // 最大图片大小限制
  MAX_IMAGE_SIZE = '图片大小不能超过10M',
  // 生成失败兜底提示
  GENERATE_ERROR_DEFAULT_MSG = '抱歉，出现了一点问题生成失败了',
}

export enum SummaryType {
  Article,
  Paragraph,
}

export const EXTRACT_ADD = 'EXTRACT_ADD';
export const EXTRACT_REMOVE = 'EXTRACT_REMOVE';
export const EXTRACT_UPDATE = 'EXTRACT_UPDATE';
export const KEYWORD_SEARCH = 'EXTRACT_SEARCH';

export const SUMMARY_GENERATE = 'SUMMARY_GENERATE';

export enum QueryBusiness {
  Word = 'word',
  Quote = 'quote',
  Library = 'library',
}

export enum TabTypeEnum {
  Read = 'read',
  Query = 'query',
  Library = 'library',
  Login = 'login',
  ReadHistory = 'read-history',
}

export const TOAST_MESSAGE = {
  TAG_CREATE_SUCCESS: '标签创建成功',
  TAG_DELETE_SUCCESS: '已删除',
  TAG_UPDATE_SUCCESS: '标签更新成功',
  TAG_CREATE_FAIL: '标签创建失败',
  TAG_DELETE_FAIL: '标签删除失败',
  TAG_UPDATE_FAIL: '标签更新失败',
  TAG_NAME_LENGTH_LIMIT: '标签长度不能超过30个字符',
  TAG_NAME_EMPTY: '标签名称不能为空',
};

export enum PubMessage {
  RenderExtractByPage = 'RenderExtractByPage',
  ScrollToPdfPage = 'ScrollToPdfPage',
  LeftBarExpand = 'LeftBarExpand',
  LeftBarRetract = 'LeftBarExpandRetract',
}
