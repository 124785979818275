import { useGetBatchMultiUploadToSummary } from "@/batch/utils/getBatchMultiUploadToSummary";
import { useArms } from "@/client/hooks/useArms";

export function useStore() {
  const {
    uploadToSummaryBatch,
    updateUploadTimeRef,
    getUploadTimeRef,
    updateUploadTraceId,
    getUploadTraceId,
    updateSummaryTraceId,
    getSummaryTraceId,
    updateMergeFinishTimerRef,
    isFirstUploaded,
  } = useGetBatchMultiUploadToSummary();

  const { captureException } = useArms();

  return {
    uploadToSummaryBatch,
    updateUploadTimeRef,
    getUploadTimeRef,
    updateUploadTraceId,
    getUploadTraceId,
    updateSummaryTraceId,
    getSummaryTraceId,
    updateMergeFinishTimerRef,
    captureException,
    isFirstUploaded,
  };
}
