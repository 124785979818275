import { useState } from 'react';
import cx from 'classnames';

import styles from './CloseModal.module.css';

import buryHatchet from '@/batch/index';
import { BATCH } from '@/batch/type';
import { Close } from '@deeplang/icons';

type CloseType = 'current' | 'forever' | '';

type CircleIconProps = {
  state: 'on' | 'off';
};

function CircleIcon({ state }: CircleIconProps) {
  const circleClasses = cx(styles.circle, { [styles.on || '']: state === 'on' });
  return <span className={circleClasses}></span>;
}

type ToolbarType = 'toolbar' | 'sidebar';

type CloseModalProps = {
  toolbarType: ToolbarType;
  title?: string;
  children?: React.ReactNode;
  open?: boolean;
  cancelText?: string;
  confirmText?: string;
  onCancel?: () => void;
  onOk?: (type: CloseType) => void;
};

function CloseModal({
  toolbarType,
  title = '关闭固定工具栏',
  children,
  open = false,
  cancelText = '取消',
  confirmText = '确定',
  onCancel,
  onOk,
}: CloseModalProps) {
  const [type, setType] = useState<CloseType>('');

  const handleClick = (type: CloseType) => {
    setType(type);
  };

  const handleOk = () => {
    if (!type) {
      return;
    }
    onOk?.(type);

    if (toolbarType === 'toolbar') {
      const track = buryHatchet.track<BATCH.TModuleName>('Toolbar');
      const params: BATCH.ToolbarClose = {
        is_permanent: type === 'forever',
      };
      track(BATCH.EventName.CloseToolbar, params);
    }

    // if (toolbarType === 'sidebar') {
    //   const track = buryHatchet.track<BATCH.TModuleName>('Sidebar');
    //   const params: BATCH.ToolbarClose = {
    //     is_permanent: type === 'forever',
    //   };
    //   track(BATCH.EventName.CloseFixedToolbar, params);
    // }
    // track
  };

  if (!open) {
    return null;
  }

  return (
    <div
      className={styles.overlay}
      style={{
        zIndex: Number.MAX_SAFE_INTEGER - 1,
      }}
    >
      <div className={styles.modal}>
        <div className={styles.header}>
          <h2>{title}</h2>
          <div
            className={styles.close}
            onClick={(e) => {
              e.stopPropagation();
              onCancel?.();
            }}
          >
            <Close />
          </div>
        </div>
        <div className={styles.body}>
          {children}
          <ul className={styles.list}>
            <li className={styles.item} onClick={() => handleClick('current')}>
              <CircleIcon state={type === 'current' ? 'on' : 'off'} />
              当前页面本次关闭
            </li>
            <li className={styles.item} onClick={() => handleClick('forever')}>
              <CircleIcon state={type === 'forever' ? 'on' : 'off'} />
              此浏览器不再展示
            </li>
          </ul>
        </div>
        <div className={styles.footer}>
          <button className={styles.cancel} onClick={onCancel}>
            {cancelText}
          </button>
          <button
            className={cx(styles.confirm, {
              [styles.disabled || '']: !type,
            })}
            onClick={handleOk}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CloseModal;
