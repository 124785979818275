import materialAdapter from "./material";
import { ReadingMaterial } from "../constants/common";
import { singleton } from ".";
class MaterialDetailAdapter {
    _downloadFileUrl = "";
    initial(downloadFileUrl) {
        this._downloadFileUrl = downloadFileUrl;
    }
    from(materialDetail) {
        if ("file_id" in materialDetail) {
            return {
                fullName: materialDetail.name,
                materialName: materialAdapter.dropSuffixForFullName(materialDetail.name),
                readingMaterial: ReadingMaterial.PDF,
                link: this._downloadFileUrl + "?file_id=" + materialDetail.file_id,
                id: materialDetail.file_id,
                ...materialDetail,
            };
        }
        if ("url" in materialDetail) {
            return {
                fullName: materialDetail.title,
                materialName: materialDetail.title,
                readingMaterial: ReadingMaterial.WebSite,
                link: materialDetail.url,
                id: materialDetail.url,
                ...materialDetail,
            };
        }
        return {
            ...materialDetail,
            materialName: '',
            id: '',
            link: '',
            readingMaterial: ReadingMaterial.Unknown,
            fullName: '',
        };
    }
}
const SingleMaterialDetailAdapter = singleton(MaterialDetailAdapter);
export default new SingleMaterialDetailAdapter();
