import { Related, Wrapper } from "@deeplang/icons";
import { ChartElement, RecommendElement } from "@deeplang/shared";

import styles from "./ChartCard.module.css";
import { DButton, DTooltip } from "@deeplang/dui";
import { ChartList } from "./ChartList";

interface ChartCardProps {
  relatedChartCount: number;
  relatedCharts: (RecommendElement & ChartElement)[];
  sourceChartName: string;
  handleClickSearchBtn: () => void;
  handleClickSourceChart: () => void;
  collapsed: boolean;
}
export function ChartCard(props: ChartCardProps) {
  const {
    relatedChartCount,
    sourceChartName,
    handleClickSearchBtn,
    relatedCharts,
    handleClickSourceChart,
    collapsed,
  } = props;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.relatedChartsText}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <Related />
            </div>
            <div>根据以下图表，为你找到 {relatedChartCount} 个相关图表</div>
          </div>

          <div>
            <DTooltip title="搜索图表" mode="night">
              <DButton
                className={styles.searchBtnContainer}
                icon={<Wrapper />}
                onClick={handleClickSearchBtn}
              >
                {collapsed ? "搜索图表" : ""}
              </DButton>
            </DTooltip>
          </div>
        </div>

        <div className={styles.sourceChart} onClick={handleClickSourceChart}>
          <div className={styles.divider}></div>

          <div className={styles.sourceChartText}>{sourceChartName}</div>
        </div>
      </div>

      <ChartList chartList={relatedCharts} />
    </div>
  );
}
