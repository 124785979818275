"use client";
import { useState, useEffect, useRef } from "react";
import { message } from "@deeplang/dui";
import cx from "classnames";

import { BATCH } from "@/batch/type";
import buryHatchet from "@/batch/index";

import { prefix } from "./config";
import { DOMMeta } from "./core/highlight";
import { Highlight } from "./core";
import useUrlExtract from "./hooks/useUrlExtract";
import useSafe from "./hooks/useSafe";
import Toolbar from "./Toolbar";

import styles from "./Toolbar.module.css";
import { colorDescription, createNoteIcon, removePdfNoteIcon } from "./util";
import { getUrl } from "./util";
import { PdfMetaInfo, TextToolbarProps } from "./types";
import { ExtractDetailResponse } from "./types";
import { PubMessage } from "./constants";
import HighlightSource from "./core/highlight/source";
import { useMoveToolbarByScrollContainer } from "./hooks/use-move-toolbar-by-scroll-container";
import { useApi } from "./hooks/useApi";
import { Maybe, ReadingSource } from "@deeplang/shared";
import { useFloatToolbarStore } from "./store";
import { EventMessage } from "@/utils/event-bus";
import { usePassport } from "@/packages/passport";
import NoteInput from "@/components/NoteInput";
import useExtract from "@/client/hooks/useExtract";
import ShareImageViewer from "@/components/share-image-viewer";

import "./style.css";
import { produce } from "immer";

export interface ExtractItem {
  meta_info: string;
  color: number;
}

export interface TagItem {
  id: number;
  tag: string;
}

export interface MetaInfo {
  start: DOMMeta;
  end: DOMMeta;
  text: string;
  id: string;
  extra?: string;
}

const panelWidthWhenOpened = 548;

function getTopLeft(x: number, panelOpened: boolean, rect: DOMRect) {
  const windowWidth = window.innerWidth;
  const toolbarWidth = 205;
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;
  let left = x;
  const delta = panelOpened ? panelWidthWhenOpened : 0;
  if (x + toolbarWidth + delta > windowWidth) {
    left = windowWidth - toolbarWidth - delta;
  }
  const top = rect.bottom + scrollTop + 5;
  return { top, left };
}

const TextToolbar = function (props: TextToolbarProps) {
  const {
    panelOpened = false,
    isToolbarVisible,
    setToolbarVisible,
    syncPluginSettings,
    thirdParty,
    options = {
      noCheckSafe: false,
      hideCloseButton: false,
      isDisableSearch: false,
    },
    fileId = "",
    channelType = ReadingSource.LingoPdf,
    toolbar,
  } = props;

  const { eventBus } = useFloatToolbarStore();

  const { noCheckSafe, hideCloseButton, isDisableSearch } = options;
  const toolbarRef = useRef<HTMLDivElement>(null);
  const highlightRef = useRef<Highlight>();
  const [isToolbarShow, setIsToolbarShow] = useState(false);
  const isToolbarVisibleRef = useRef(false);
  const [extractId, setExtractId] = useState("");
  const [includeImg, setIncludeImg] = useState(false);
  const extractRef = useRef<any>(null);
  const addParamsRef = useRef<any>(null);
  const highlightIdRef = useRef("");
  const prevColorRef = useRef(1);
  const [closed, setClosed] = useState(false);
  const { highlights, getHighlights, setHighlights } = useUrlExtract(
    0,
    fileId,
    channelType,
  );
  const highlightsRef = useRef<any[]>([]);
  const mouseUpRef = useRef(false);
  const { addPdfExtract } = useApi();

  const {
    isScrolled,
    setIsScrolled,
    pdfViewContainerRef,
    currentToolbarScrollTopRef,
  } = useMoveToolbarByScrollContainer({
    toolbarRef,
    isToolbarVisibleRef,
  });

  const isSafe = useSafe(getUrl(), noCheckSafe);

  const noteOpenRef = useRef(false);
  const [isNoteShow, setIsNoteShow] = useState(false);
  const shareImageRef = useRef<any>(null);
  const toolbarOpenStatusRef = useRef(false);
  const { onSaveNote } = useExtract();
  const [addStatus, setAddStatus] = useState(false);

  const onNoteSave = async (note: string, isClose = true) => {
    if (extractRef.current.note === note) {
      setIsNoteShow(false);
      return;
    }
    const data = {
      note,
      color: extractRef.current.color,
      extract_id: extractRef.current.extract_id || "",
      tags: extractRef.current.tags || [],
    };
    await onSaveNote(data);
    getHighlights();
    eventBus.emit(EventMessage.EXTRACT_CHANGE);
    if (!note) {
      try {
        const metaInfo = JSON.parse(extractRef.current.meta_info) as MetaInfo;
        removePdfNoteIcon(metaInfo.id);
      } catch (error) { }
    }
    if (isClose) {
      setIsNoteShow(false);
      extractRef.current = null;
    }
  };

  const handleAddExtractFailed = (sources: HighlightSource[], err: any) => {
    sources[0] && highlightRef.current?.remove(sources[0].id);
    highlightRef.current?.removeSelection();
    setIsToolbarShow(false);
    isToolbarVisibleRef.current = false;
    currentToolbarScrollTopRef.current = 0;
    message.error(err.msg);
    try {
      const track = buryHatchet.track<BATCH.TModuleName>("Extract");
      const params: BATCH.ExtractProperties = {
        words_count: extractRef.current.content.length, // 摘录字数
        data_type: "文字",
        color: colorDescription(extractRef.current.color), // 摘录颜色
        failure_reason: err.msg, // 失败原因
        is_success: false, // 是否成功
      };
      track(BATCH.EventName.Extract, params);
    } catch {
      console.log("埋点错误");
    }
  };

  const handleClickListenerForHighlight = (
    { id }: { id: string },
    hs: Highlight,
    e: MouseEvent,
  ) => {
    console.log("click", id);
    function getExtractInfo(highlightId: string) {
      return highlightsRef.current.find((item) => {
        try {
          const meta = JSON.parse(item.meta_info) as MetaInfo;
          return meta.id === highlightId;
        } catch (err) {
          console.log("parse error");
          return false;
        }
      });
    }

    const extract = getExtractInfo(id);
    console.log("click", id, extract);

    // get extractInfo by highlightId
    extractRef.current = getExtractInfo(id);
    highlightIdRef.current = id;
    setExtractId(extract?.extract_id || extract?.extractId || "");
    const target = e.target as HTMLElement;
    if (target) {
      handleShowToolbar(target, e);
    }
  };

  useEffect(() => {
    const highlight = (highlightRef.current = new Highlight({
      wrapTag: "deep-highlight",
      excludeSelectors: ["code", "pre", "textarea", "input"],
      verbose: true,
      bgColors: ["#FEE78C", "#F5AAAB", "#ACEAC6"],
    }));

    highlight.on(Highlight.event.CLICK, handleClickListenerForHighlight);

    highlight.on(Highlight.event.CREATE, ({ sources, type }) => {
      if (type === "from-input") {
        extractRef.current = {
          ...extractRef.current,
          meta_info: JSON.stringify(sources[0]),
        };
        const params = {
          ...addParamsRef.current,
          meta_info: JSON.stringify(
            Object.assign(sources[0] || {}, {
              pageNumber: toolbar.config.currentPageNumber,
            }),
          ),
        };

        addPdfExtract(params)
          .then((res) => {
            if (!res) {
              handleAddExtractFailed(sources, res);
              return;
            }
            const extract_id = res.extract_id;
            if (extract_id) {
              extractRef.current = {
                ...extractRef.current,
                ...addParamsRef.current,
                extract_id,
                meta_info: JSON.stringify(sources[0]),
              };
              highlightsRef.current = [
                ...highlightsRef.current,
                extractRef.current,
              ];
              highlightIdRef.current = sources[0]?.id || "";
              setExtractId(extract_id);
              const extractPayload = {
                channelType: extractRef.current.channel_type,
                color: extractRef.current.color,
                content: extractRef.current.content,
                extractId: extractRef.current.extract_id,
                id: extractRef.current.fileId,
                metaInfo: extractRef.current.meta_info,
                tags: extractRef.current.tags,
                type: extractRef.current.type,
                note: extractRef.current.note,
              };
              eventBus.emit(EventMessage.EXTRACT_ADD, extractPayload);

              highlightRef.current?.removeSelection();

              if (addParamsRef.current.isNote) {
                setAddStatus(true);
              }
              if (addParamsRef.current.isShare) {
                onShare(extractRef.current);
              }
              getHighlights();
              message.success("新增摘录成功");
              try {
                const track = buryHatchet.track<BATCH.TModuleName>("Extract");
                const params: BATCH.ExtractProperties = {
                  words_count: extractRef.current.content.length, // 摘录字数
                  data_type: "文字",
                  color: colorDescription(extractRef.current.color), // 摘录颜色
                  failure_reason: "", // 失败原因
                  is_success: true, // 是否成功
                };
                track(BATCH.EventName.Extract, params);
              } catch {
                console.log("埋点错误");
              }
            }
          })
          .catch((err) => {
            handleAddExtractFailed(sources, err);
          });

        // addPdfExtract({
        //   ...addParamsRef.current,
        //   meta_info: JSON.stringify(
        //     Object.assign(sources[0] || {}, {
        //       pageNumber: toolbar.config.currentPageNumber,
        //     }),
        //   ),
        // })
        //   .then((res) => {
        //     if (!res) {
        //       handleAddExtractFailed(sources, res);
        //       return;
        //     }
        //   })
        //   .catch((err) => {
        //     handleAddExtractFailed(sources, err);
        //   });
      }
    });

    highlight.hooks.Serialize.RecordInfo.tap(() => {
      const extraInfo = Math.random().toFixed(4);
      return extraInfo;
    });

    return () => {
      if (highlight) {
        highlight.off(Highlight.event.CLICK, handleClickListenerForHighlight);
      }
    };
  }, []);

  useEffect(() => {
    const removeHighlight = (data: { id: string }) => {
      handleRemoveHighlight(data.id);
    };

    const updateHighlight = (extracts: any) => {
      setHighlights(extracts);
    };

    eventBus.on(EventMessage.UPDATE_READER_EXTRACT, updateHighlight);
    eventBus.on(EventMessage.IFRAME_HIGHLIGHT_REMOVE, removeHighlight);

    return () => {
      eventBus.off(EventMessage.UPDATE_READER_EXTRACT, updateHighlight);
      eventBus.off(EventMessage.IFRAME_HIGHLIGHT_REMOVE, removeHighlight);
    };
  }, []);

  // 这里更加精细化的做法，应该是明确知道，pdf 的文本 dom 哪几页是渲染出来的，哪几页没渲染。
  // 但是维护成本较高，暂不采用这种方案。后续使用自己的方式渲染 pdf，就能更加方便的解决这个问题。
  useEffect(() => {
    highlights.forEach((item) => {
      handleHighlight(item);
    });
    highlightsRef.current = highlights;
  }, [highlights]);

  useEffect(() => {
    setIsNoteShow(false);
    setIsScrolled(false);
  }, [isScrolled]);

  // 为了满足 pdf 在滚动到某一页时，依然可以渲染出摘录的需求，就加了这个事件，专门用来监听 pdf 文本 dom 是否渲染完成。
  const handleRenderExtractByPage = (message: string, pageNumber: number) => {
    if (message !== PubMessage.RenderExtractByPage) {
      return;
    }

    highlightsRef.current.forEach((item) => {
      let metaInfo: PdfMetaInfo | undefined;
      try {
        metaInfo = JSON.parse(item.meta_info) as PdfMetaInfo;
      } catch (err) {
        console.warn(err);
      }

      if (metaInfo !== undefined && metaInfo.pageNumber === pageNumber) {
        handleHighlight(item);
      }
    });
  };

  const handleRemoveExtract = () => {
    mouseUpRef.current = false;
  };
  useEffect(() => {
    PubSub.subscribe(PubMessage.RenderExtractByPage, handleRenderExtractByPage);
    eventBus.on(EventMessage.EXTRACT_REMOVE, handleRemoveExtract);

    return () => {
      highlightRef.current?.dispose();
      PubSub.unsubscribe(PubMessage.RenderExtractByPage);
      eventBus.off(EventMessage.EXTRACT_REMOVE, handleRemoveExtract);
    };
  }, []);

  useEffect(() => {
    const pdfViewDom = document.querySelector(
      "#pdf-view-container",
    ) as HTMLElement;

    const toolbarDom = document.querySelector(
      "#deep-lang-toolbar-text",
    ) as HTMLElement;

    // toolbarDom.onselectstart = () => false;

    pdfViewDom.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("selectionchange", handleSelectionChange);
    window.addEventListener("resize", handleResize);

    return () => {
      pdfViewDom.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("selectionchange", handleSelectionChange);
      window.removeEventListener("resize", handleResize);
    };
  }, [panelOpened]);

  useEffect(() => {
    handleResize();
  }, [panelOpened]);

  const handleResize = () => {
    const selection = window.getSelection();
    if (
      selection?.isCollapsed === false &&
      selection?.toString().trim() !== ""
    ) {
      const rect = selection?.getRangeAt(0)?.getBoundingClientRect();
      const x = rect.right;
      const { top, left } = getTopLeft(x, panelOpened, rect);
      toolbarRef.current?.style.setProperty("top", `${top}px`);
      toolbarRef.current?.style.setProperty("left", `${left}px`);
    }
  };

  useEffect(() => {
    function getExtractInfo(highlightId: string) {
      return highlightsRef.current.find((item) => {
        try {
          const meta = JSON.parse(item.meta_info) as MetaInfo;
          return meta.id === highlightId;
        } catch (err) {
          console.log("parse error");
          return false;
        }
      });
    }

    const clickHandler = (e: any) => {
      const target = e.target;
      const nodeName = target.nodeName || "";

      if (
        toolbarOpenStatusRef.current &&
        nodeName === "DEEPLANG-NOTE-ICON-PDF"
      ) {
        return;
      }

      if (nodeName === "DEEPLANG-NOTE-ICON-PDF") {
        const id = target.getAttribute("data-note-id");
        extractRef.current = getExtractInfo(id);
        let { top, left } = target.getBoundingClientRect();
        setIsNoteShow(true);
        setIsToolbarShow(false);
        if (left > 420) {
          left = left - 404;
        }

        if (top < 240) {
          top = top + 16;
        } else {
          top = top - 240;
        }

        toolbarRef.current?.style.setProperty("display", `block`);
        toolbarRef.current?.style.setProperty("top", `${top}px`);
        toolbarRef.current?.style.setProperty("left", `${left}px`);
      } else {
        handleSelectionChange();
      }
    };

    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, []);

  const isSelectionInPdfComponent = (parent: Node | null) => {
    let isSelectedOutside = false;
    while (parent) {
      if (parent.nodeName === "#text") {
        parent = parent.parentElement;
        continue;
      }
      if (!(parent instanceof HTMLElement)) {
        break;
      }
      if (
        typeof parent.className === "string" &&
        parent.className.includes("pdfViewer")
      ) {
        isSelectedOutside = true;
        break;
      }
      parent = parent.parentElement;
    }

    return isSelectedOutside;
  };

  const isSelectionCrossPage = (
    focusNode: Maybe<Node>,
    anchorNode: Maybe<Node>,
  ) => {
    let isCrossPage = false;

    if (!anchorNode || !focusNode) {
      return isCrossPage;
    }

    const pageNumberForFocusNode = getPageNumber(
      focusNode as Maybe<HTMLElement | ParentNode>,
    );
    const pageNumberForAnchorNode = getPageNumber(
      anchorNode as Maybe<HTMLElement | ParentNode>,
    );

    if (pageNumberForAnchorNode === pageNumberForFocusNode) {
      isCrossPage = false;
    } else {
      isCrossPage = true;
    }

    return isCrossPage;
  };

  const getPageNumber = (
    currentNodeForFocusNode: Maybe<HTMLElement | ParentNode>,
  ) => {
    while (currentNodeForFocusNode) {
      if (currentNodeForFocusNode.nodeName === "#text") {
        currentNodeForFocusNode = currentNodeForFocusNode.parentNode;
        continue;
      }

      if (!("getAttribute" in currentNodeForFocusNode)) {
        break;
      }

      const pageNumber =
        currentNodeForFocusNode.getAttribute("data-page-number");
      if (pageNumber === null) {
        currentNodeForFocusNode = currentNodeForFocusNode.parentNode;
        continue;
      }
      const newPageNumber = +pageNumber;
      toolbar.updateCurrentPageNumber(newPageNumber);
      return newPageNumber;
    }
    return -1;
  };

  const handleMouseUp = (e: any): void => {
    const nodeName = e?.target?.nodeName;
    setIsNoteShow(false);
    if (nodeName === "DEEPLANG-NOTE-ICON-PDF") {
      return;
    }
    toolbarOpenStatusRef.current = false;

    const selection = window.getSelection();
    if (
      selection?.isCollapsed === false &&
      selection?.toString().trim() !== ""
    ) {
      const range = selection?.getRangeAt(0);

      const path = e.composedPath() as HTMLElement[];
      const { focusNode, anchorNode } = selection;

      if (!isSelectionInPdfComponent(focusNode)) {
        return;
      }

      if (isSelectionCrossPage(focusNode, anchorNode)) {
        return;
      }

      const isInPdfComponent = path.find(
        (item) =>
          typeof item.className === "string" &&
          item.className.includes("pdfViewer"),
      );

      if (!isInPdfComponent) {
        return;
      }

      const selectText = range?.toString();
      const extract = highlightsRef.current.find(item => item.content === selectText);
      if (extract) return;

      if (range) {
        const imgs = range.cloneContents().querySelectorAll("img");
        imgs.length > 0 ? setIncludeImg(true) : setIncludeImg(false);
        extractRef.current = null;
        highlightIdRef.current = "";
        setExtractId("");
        if (mouseUpRef.current) return;

        const rect = range.getBoundingClientRect();
        const x = e.clientX > rect.right ? rect.right : e.clientX;
        const { top, left } = getTopLeft(x, panelOpened, rect);
        toolbarRef.current?.style.setProperty("display", `block`);
        toolbarRef.current?.style.setProperty("top", `${top}px`);
        toolbarRef.current?.style.setProperty("left", `${left}px`);
        mouseUpRef.current = true;
        setIsToolbarShow(true);
        toolbarRef.current?.style.setProperty("visibility", "visible");
        isToolbarVisibleRef.current = true;
        if (pdfViewContainerRef.current) {
          currentToolbarScrollTopRef.current =
            pdfViewContainerRef.current.scrollTop;
        }
        return;
      }
    }

    const path = e.composedPath() as HTMLElement[];
    const toolbarInfo = path.find(
      (item) =>
        typeof item.className === "string" &&
        item.className.includes(`${prefix}-toolbar`),
    );
    if (!toolbarInfo) {
      mouseUpRef.current = false;
      setIsToolbarShow(false);
      isToolbarVisibleRef.current = false;
      currentToolbarScrollTopRef.current = 0;
      extractRef.current = null;
      highlightIdRef.current = "";
    }
  };

  useEffect(() => {
    if (isToolbarShow) {
      toolbarRef.current?.style.setProperty(
        "z-index",
        (Number.MAX_SAFE_INTEGER - 1).toString(), // TODO: 为什么要最大，影响分享图
      );
    } else {
      toolbarRef.current?.style.setProperty("transform", "translateY(0)");
    }
  }, [isToolbarShow]);

  const handleSelectionChange = () => {
    if (extractRef.current) {
      return;
    }

    const selection = window.getSelection();
    const text = selection?.toString();
    const divElement = document.getElementById(
      "pdf-view-container",
    ) as HTMLElement;
    const range = selection?.getRangeAt?.(0);
    const rangeAncestor = range?.commonAncestorContainer;

    if (rangeAncestor && !divElement?.contains(rangeAncestor)) {
      return;
    }

    if (selection?.isCollapsed === true || !text) {
      mouseUpRef.current = false;
      setIsToolbarShow(false);
      setIsNoteShow(false);
      isToolbarVisibleRef.current = false;
      currentToolbarScrollTopRef.current = 0;
      extractRef.current = null;
      highlightIdRef.current = "";
    }
  };

  const handleShowToolbar = (target: HTMLElement, e: MouseEvent) => {
    const rect = target.getBoundingClientRect();
    const x = e.clientX;
    const { top, left } = getTopLeft(x, panelOpened, rect);
    toolbarRef.current?.style.setProperty("display", `block`);
    toolbarRef.current?.style.setProperty("top", `${top}px`);
    toolbarRef.current?.style.setProperty("left", `${left}px`);
    setIsToolbarShow(true);
    setIsNoteShow(false);
    toolbarRef.current?.style.setProperty("visibility", "visible");
    isToolbarVisibleRef.current = true;
    if (pdfViewContainerRef.current) {
      currentToolbarScrollTopRef.current =
        pdfViewContainerRef.current.scrollTop;
    }
  };

  const handleHighlight = (item: ExtractDetailResponse) => {
    try {
      const hs = JSON.parse(item.meta_info || "") as PdfMetaInfo;
      const range = document.createRange();

      const root =
        document.querySelector<HTMLDivElement>(
          `div[data-page-number="${hs.pageNumber}"]`,
        ) || document.body;
      const rangeInfo = highlightRef.current?.getRange(
        hs.start,
        hs.end,
        hs.text,
        hs.id,
        root,
      );
      range.setStart(
        rangeInfo?.startNode?.node as Node,
        rangeInfo?.startNode?.offset as number,
      );
      range.setEnd(
        rangeInfo?.endNode?.node as Node,
        rangeInfo?.endNode?.offset as number,
      );
      const text = range?.toString();
      if (text !== hs.text) {
        return;
      }
      highlightRef.current?.setOption({
        style: {
          className: ["highlight-wrap", `highlight-color-${item.color}`],
        },
      });
      highlightRef.current?.fromStore(
        hs.start,
        hs.end,
        hs.text,
        hs.id,
        hs.pageNumber,
        hs?.extra,
      );

      if (item.note) {
        createNoteIcon(hs);
      } else {
        let id = hs.id;
        if (item.type == 1) {
          id = item.extract_id;
        }
        const noteDomList = document.querySelectorAll(`[data-note-id="${id}"]`);
        if (noteDomList) {
          noteDomList.forEach((item) => {
            item.parentNode?.removeChild(item);
          });
        }
      }
    } catch {
      // 这里的错误应当需要关注
      // 目前因为 pdf 首次渲染出文本时，摘录的信息还没拿到，所以触发 RenderExtractByPage 这个事件时，渲染不出摘录
      // 所以在 useEffect 那里又遍历执行了一次 handleHighlight，那里的执行会引起这里错误。这又是因为 pdf 文件的文本 dom
      // 是懒加载，所以根本就不存在相应的文本节点，也就渲染不出摘录。
      // console.error(err);
    }
  };

  const handleRemoveHighlight = async (id?: string) => {
    const highlightId = id || highlightIdRef.current;
    highlightRef.current?.remove(highlightId);
    highlightsRef.current = highlightsRef.current.filter(
      (item) => item.extract_id !== extractId,
    );

    setIsToolbarShow(false);
    isToolbarVisibleRef.current = false;
    currentToolbarScrollTopRef.current = 0;
    removePdfNoteIcon(highlightId);
  };

  const handleUpdateHighlight = async (options: any) => {
    const { color } = options;
    if (color) {
      const highlightDOMs = document.querySelectorAll(
        `[data-highlight-id="${highlightIdRef.current}"]`,
      );
      if (!highlightDOMs.length) {
        return;
      }

      highlightDOMs.forEach((item) => {
        item.classList.remove(`highlight-color-${extractRef.current.color}`);
        item.classList.add(`highlight-color-${color}`);
      });
    }
    extractRef.current = {
      ...extractRef.current,
      ...options,
    };
    highlightsRef.current = highlightsRef.current.map((item) => {
      if (item.extract_id === extractId || item.extractId === extractId) {
        return {
          ...item,
          ...options,
        };
      }
      return item;
    });
  };

  const handleAddHighlight = async (extract: any) => {
    addParamsRef.current = {
      content: extract.content,
      meta_info: extract.meta_info,
      fileId,
      tags: extract.tags,
      type: extract.type,
      color: extract.color,
      channel_type: channelType,
      note: extract.note,
      isShare: extract.isShare,
    };
    highlightRef.current?.setOption({
      style: {
        className: ["highlight-wrap", `highlight-color-${extract.color}`],
      },
    });
    prevColorRef.current = extract.color;
    const selection = window.getSelection();
    if (!selection) {
      return;
    }
    const text = selection?.toString();
    addParamsRef.current = {
      ...addParamsRef.current,
      content: text || extract.content,
    };
    const range = selection?.getRangeAt(0);
    highlightRef.current?.fromRange(
      range,
      toolbar.config.currentPageNumber || 1,
    );
  };

  const handleClose = () => {
    extractRef.current = null;
    setClosed(true);
  };

  const onShare = async (data: any) => {
    shareImageRef.current.onOpen({
      extractId: data.extract_id,
    });
  };

  const updateExtract = (params: any) => {
    const { extract_id, meta_info } = params;
    const newStatus = highlights.map((item) => {
      if (item.extract_id === extract_id) {
        return { ...item, ...params };
      }
      return item;
    });
    const highlight = highlights.find((item) => item.extract_id === extract_id);
    if (!highlight) {
      newStatus.push(params);
    }
    setHighlights(newStatus);
    const metaInfo = JSON.parse(meta_info);
    highlightIdRef.current = metaInfo.id;
    extractRef.current = params;
    if (extract_id) {
      setExtractId(extract_id);
    }
  };

  const onRefreshHandler = (params: any) => {
    getHighlights();
    const { extract_id, meta_info } = params;
    const metaInfo = JSON.parse(meta_info);
    highlightIdRef.current = metaInfo.id;
    extractRef.current = params;
    if (extract_id) {
      setExtractId(extract_id);
    }
  };

  if (!isSafe || !isToolbarVisible || closed) {
    return null;
  }

  return (
    <>
      <div
        ref={toolbarRef}
        className={cx(styles.toolbar, `${prefix}-toolbar`)}
        id={`${prefix}-toolbar-text`}
      >
        {isToolbarShow && (
          <Toolbar
            type="text"
            thirdParty={thirdParty}
            id={extractId}
            fileId={fileId}
            extract={extractRef.current}
            prevColor={prevColorRef.current}
            onAdd={handleAddHighlight}
            onUpdate={handleUpdateHighlight}
            onRemove={handleRemoveHighlight}
            onClose={handleClose}
            setToolbarVisible={setToolbarVisible}
            syncPluginSettings={syncPluginSettings}
            includeImg={includeImg}
            options={{ hideCloseButton, isDisableSearch }}
            toolbar={toolbar}
            noteOpenChange={(open: boolean) => {
              toolbarOpenStatusRef.current = open;
            }}
            noteOpenRef={noteOpenRef}
            onNoteSave={onNoteSave}
            onShare={onShare}
            refreshExtract={onRefreshHandler}
            updateExtractData={updateExtract}
            onCancelNote={() => {
              setIsToolbarShow(false);
            }}
            addStatus={addStatus}
          />
        )}

        {isNoteShow && (
          <NoteInput
            hasFooter={false}
            value={extractRef.current?.note}
            onCancel={() => setIsNoteShow(false)}
            onSave={(value) => onNoteSave(value)}
          />
        )}
      </div>

      <ShareImageViewer ref={shareImageRef} />
    </>
  );
};
export default TextToolbar;
