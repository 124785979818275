import styles from "./file-violation.module.css";
export function FileViolation() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={
            "https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/extensions/imgs/empty.png"
          }
          alt="empty render"
          width={240}
          style={{ height: "auto" }}
        />

        <div className={styles.description}>
          <span>该文件涉嫌违规，已根据相关法律条件予以屏蔽</span>
        </div>
      </div>
    </div>
  );
}
