import { ChartSource } from "../constants/common";
export function convertEnumToStringForChartSource(chartSource) {
    switch (chartSource) {
        case ChartSource.Public:
            return "公共广场";
        case ChartSource.Library:
            return "个人库";
        default:
            return "";
    }
}
// 单例模式
export function singleton(className) {
    let ins;
    return new Proxy(className, {
        construct(target, argArray) {
            if (!ins) {
                ins = new target(...argArray);
                return ins;
            }
            return ins;
        },
    });
}
export function mergeSort(arr, path, get) {
    if (arr.length <= 1) {
        return arr;
    }
    const mid = Math.floor(arr.length / 2);
    const left = arr.slice(0, mid);
    const right = arr.slice(mid);
    return merge(mergeSort(left, path, get), mergeSort(right, path, get), path, get);
}
function merge(left, right, path, get) {
    let result = [];
    let leftIndex = 0;
    let rightIndex = 0;
    while (leftIndex < left.length && rightIndex < right.length) {
        if (get(left[leftIndex], path) <= get(right[rightIndex], path)) {
            result.push(left[leftIndex]);
            leftIndex++;
        }
        else {
            result.push(right[rightIndex]);
            rightIndex++;
        }
    }
    return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
}
export function insertionSort(array, path, get) {
    for (let i = 1; i < array.length; i++) {
        let j = i - 1;
        let tmp = array[i];
        while (j >= 0 && get(array[j], path) > get(tmp, path)) {
            array[j + 1] = array[j];
            j--;
        }
        array[j + 1] = tmp;
    }
    return array;
}
