import { useEffect, useState } from "react";
import { toast } from "@deeplang/dui";
import { extractApi } from "@/api/extract";
import { ExtractDetail, ExtractUpdateParams, to } from "@deeplang/shared";
import { message } from "@deeplang/dui";

export const EXTRACT_MESSAGE = {
  UPDATE_SUCCESS: "摘录已更新",
  NOTE_UPDATE_SUCCESS: "批注已更新",
};

export interface IListOptions {
  isLoad?: boolean;
  defaultParams?: any;
}

function useExtract(options: IListOptions = {}) {
  const { isLoad = false, defaultParams = {} } = options;

  const [loading, setLoading] = useState(false);
  const [extractList, setExtractList] = useState<ExtractDetail[] | undefined>(
    [],
  );
  const [shareImgUrl, setShareImgUrl] = useState<string>("");
  const [shareFailed, setShareFailed] = useState(false);

  useEffect(() => {
    load();
  }, []);

  const load = (status?: boolean) => {
    if (status || isLoad) {
      getExtractList();
    }
  };

  const getExtractList = async (params?: any) => {
    setLoading(true);
    const data = { ...defaultParams, ...params };
    const [err, result] = await to<ExtractDetail[]>(extractApi.list(data));
    if (err) {
      console.error(err);
      return;
    }
    setExtractList(result);
    setLoading(false);
  };

  const addExtract = async (params?: any) => {
    const data = {
      color: 1,
      tags: [],
      note: "",
      ...params,
    };
    const [err, result] = await to(extractApi.add(data));
    if (err) {
      console.error(err);
      return;
    }
    load();
    return result;
  };

  const updateExtract = async (params?: ExtractUpdateParams) => {
    const data = { ...defaultParams, ...params };
    const [err, result] = await to(extractApi.update(data));
    if (err) {
      console.error(err);
      return;
    }
    load();
  };

  const deleteExtract = async (extractId: string) => {
    const data = { extract_id: extractId };
    const [err] = await to(extractApi.update(data));
    if (err) {
      console.error(err);
      return;
    }
    load();
  };

  const onSaveNote = async (data: ExtractUpdateParams) => {
    const [err] = await to(extractApi.update(data));
    if (err) {
      message.error(err.msg || "服务异常，批注保存失败");
      return;
    }
    toast.success(EXTRACT_MESSAGE.NOTE_UPDATE_SUCCESS);
  };

  const shareExtract = async (extractId: string) => {
    setLoading(true);
    setShareImgUrl("");
    const data = {
      extract_id: extractId,
    };
    const [err, result] = await to(extractApi.share(data));
    setLoading(false);
    if (err) {
      setShareFailed(true);
      console.error(err);
      message.error(err.msg || "服务异常，分享图加载失败");
      return;
    }
    setShareFailed(false);
    setShareImgUrl(result.img_url);
    return result;
  };

  return {
    loading,
    shareFailed,
    shareImgUrl,
    extractList,
    getExtractList,
    addExtract,
    onSaveNote,
    updateExtract,
    deleteExtract,
    shareExtract,
  };
}

export default useExtract;
