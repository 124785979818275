import { ImageViewer } from "@deeplang/dui";
import { browser } from "@deeplang/shared";
import { useRelatedChartsStore } from "@/modules/related-charts/store";

import { MOperationBar } from "./MOperationBar";
import "./MImageViewer.css";
import { ImageViewListItem } from "../../../types";
import { toast } from "@deeplang/dui";

export const MImageViewer = () => {
  const { imageViewerRef, imageViewerOperationBarRef } =
    useRelatedChartsStore();
  const handleClickPrev = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    imageViewerRef.current?.prev();
    imageViewerOperationBarRef.current?.updateIsLastItem(
      imageViewerRef.current?.isLastItem() || false,
    );
    imageViewerOperationBarRef.current?.updateIsFirstItem(
      imageViewerRef.current?.isFirstItem() || false,
    );
  };
  const handleClickNext = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    imageViewerRef.current?.next();
    imageViewerOperationBarRef.current?.updateIsLastItem(
      imageViewerRef.current?.isLastItem() || false,
    );
    imageViewerOperationBarRef.current?.updateIsFirstItem(
      imageViewerRef.current?.isFirstItem() || false,
    );
  };

  const handleClickCopy = () => {
    const currentItem =
      imageViewerRef.current?.getCurrentItem() as ImageViewListItem;

    try {
      browser.copyTextAndImage(currentItem.resourceTitle, currentItem.imageSrc);
      toast.success("复制图片成功");
    } catch {
      toast.error("复制图片失败");
    }
  };
  const handleClickDownload = () => {
    const currentItem = imageViewerRef.current?.getCurrentItem();
    let link: HTMLAnchorElement | null = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);
    link.href = currentItem?.imageSrc || "";
    link.click();
    document.body.removeChild(link);
    link = null;
  };
  const handleClickBackOriginal = () => {
    const currentItem =
      imageViewerRef.current?.getCurrentItem() as ImageViewListItem;
    window.open(
      `${window.location.origin}/reader/pdf/${currentItem.resourceId}?elementId=${currentItem.elementId}`,
    );
  };

  return (
    <ImageViewer
      ref={imageViewerRef}
      footer={
        <MOperationBar
          ref={imageViewerOperationBarRef}
          handleClickNext={handleClickNext}
          handleClickPrev={handleClickPrev}
          handleClickCopy={handleClickCopy}
          handleClickDownload={handleClickDownload}
          handleClickBackOriginal={handleClickBackOriginal}
        />
      }
    />
  );
};
