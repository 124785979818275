import {
  PdfSource,
  ReadingSource,
  WebSource,
  FileType,
  MultiDocSource,
  ReadingMaterial,
} from "@deeplang/shared";

const getLocalStorageItem = (key: string): string => {
  const item = localStorage.getItem(key);
  if (!item) {
    throw new Error(`Missing ${key} in localStorage`);
  }
  return item;
};

export const getToken = (): string => {
  return getLocalStorageItem("WantWordQuotes-AccessToken");
};

export const getUserId = (): string => {
  return getLocalStorageItem("WantWordQuotes-UID");
};

export const getBaseURL = (): string => {
  const baseURL = process.env.NEXT_PUBLIC_API_URL;
  if (!baseURL) {
    // throw new Error('Missing NEXT_PUBLIC_API_URL environment variable');
  }
  return baseURL || "";
};

export function getReaderViewRouteByType(type: ReadingSource) {
  if (WebSource.has(type)) {
    return "web";
  }

  if (PdfSource.has(type)) {
    return "pdf";
  }
  if (MultiDocSource.has(type)) {
    return "multi";
  }
}

export function getReaderViewRouteByReadingMaterial(type: ReadingMaterial) {
  if (type === ReadingMaterial.WebSite) {
    return "web";
  }

  if (type === ReadingMaterial.PDF) {
    return "pdf";
  }

  return "";
}

// 数组去重
export function uniqueByKey(arr: any[], key: any) {
  const seen = new Set();
  return arr.filter((item) => {
    const serializedKey = JSON.stringify(item[key]);
    return seen.has(serializedKey) ? false : seen.add(serializedKey);
  });
}

// 获取文件类型
export function getFileType(fileType: number) {
  switch (fileType) {
    case FileType.Web:
      return "WEB";
    case FileType.PDF:
      return "PDF";
    default:
      return "";
  }
}

export function getDomain(url: string) {
  try {
    const parsedUrl = new URL(url);
    let domain = parsedUrl.hostname;

    // 去掉 www.
    if (domain.startsWith("www.")) {
      domain = domain.substring(4);
    }

    return domain;
  } catch (error) {
    console.error("无效的 URL:", error);
    return null;
  }
}
