"use client";
import { getDocument, GlobalWorkerOptions, PDFDocumentProxy } from "pdfjs-dist";
import { TextItem, BinaryData } from "pdfjs-dist/types/src/display/api";
import {
  EventBus,
  PDFFindController,
  PDFLinkService,
  PDFScriptingManager,
  PDFViewer,
} from "pdfjs-dist/web/pdf_viewer";
import { normalize } from "./normalize";

GlobalWorkerOptions.workerSrc = "/pdf.worker.js";
const SANDBOX_BUNDLE_SRC = "/pdf.sandbox.js";

export function initPDF(fileContent: BinaryData) {
  const loadingTask = getDocument({
    data: fileContent,
    cMapUrl: "/cmaps/",
    cMapPacked: true,
    enableXfa: true,
  });
  // const loadingTask = getDocument('../../1.pdf');
  return loadingTask.promise;
}

export function createPDFView(
  document: PDFDocumentProxy,
  container: HTMLDivElement,
) {
  const eventBus = new EventBus();
  const pdfLinkService = new PDFLinkService({
    eventBus,
  });
  const pdfFindController = new PDFFindController({
    eventBus,
    linkService: pdfLinkService,
  });

  const pdfScriptingManager = new PDFScriptingManager({
    eventBus,
    sandboxBundleSrc: SANDBOX_BUNDLE_SRC,
  });

  const pdfView = new PDFViewer({
    container,
    linkService: pdfLinkService,
    findController: pdfFindController,
    scriptingManager: pdfScriptingManager,
    eventBus,
  });

  pdfLinkService.setViewer(pdfView);
  pdfScriptingManager.setViewer(pdfView);

  eventBus.on("pagesinit", () => {
    pdfView.currentScaleValue = "auto";
  });

  pdfView.setDocument(document);
  pdfLinkService.setDocument(document, null);

  return pdfView;
}

export async function extractPdfText(pDocument: PDFDocumentProxy | null) {
  let originText = "";

  if (!pDocument) {
    return originText;
  }

  for (let pageNumber = 1; pageNumber <= pDocument.numPages; pageNumber++) {
    const page = await pDocument.getPage(pageNumber);
    const textContent = await page.getTextContent();

    const strBuf = [];
    for (const textItem of textContent.items) {
      strBuf.push((textItem as TextItem).str);
      if ((textItem as TextItem).hasEOL) {
        strBuf.push("\n");
      }
    }

    const { normalized } = normalize(strBuf.join(""));

    originText += normalized + "\n";
  }

  return originText;
}
