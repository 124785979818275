import { useImperativeHandle, useState, useRef } from "react";
import { IPdfReader, TMaterial } from "../type";

export default function useStore(props: IPdfReader) {
  const { pdfReaderRef } = props;
  const [material, setMaterial] = useState<TMaterial>();
  // TODO 不一定需要了，现在如果出现左边栏没有的东西就直接隐藏侧边栏，仍允许阅读
  const [materialIsNotExist, setMaterialIsNotExist] = useState(false);
  const originTextRef = useRef("");
  const [originTextFromPdf, setOriginTextFromPdf] = useState("");
  // 原文定位的高度
  const originTextTopRef = useRef(0);

  useImperativeHandle(pdfReaderRef, () => ({
    updateMaterial: (value) => {
      setMaterial(value);
    },
    updateMaterialIsNotExist: (value) => {
      setMaterialIsNotExist(value);
    },
    getOriginTextTop: () => {
      return originTextTopRef.current;
    },
  }));

  const updateOriginTextFromPdf = (value: string) => {
    setOriginTextFromPdf(value);
  };
  const updateOriginTextTop = (value: number) => {
    originTextTopRef.current = value;
  };

  return {
    material,
    materialIsNotExist,
    setMaterialIsNotExist,
    originTextRef,
    pdfReaderRef,
    originTextFromPdf,
    updateOriginTextFromPdf,
    updateOriginTextTop,
  };
}
