import { ThirdParty } from "./types";

export function checkIsThirdPartyAvailability(thirdParty: ThirdParty) {

  const { 
    request, 
  } = thirdParty;

  if(!request) {
    throw new Error(`Request must be provided`);
  }

  return true;
}