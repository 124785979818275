"use client";

import { useEffect } from "react";
import { PdfReaderProvider } from "../store";
import { IPdfReader, IPdfReaderRef, ThirdParty } from "../type";
import { checkIsThirdPartyAvailability } from "../utils";
import Main from ".";
import { Merge } from "@deeplang/shared";
import { forwardRef } from "react";
import { useParams } from "next/navigation";
import useReadTime from "@/client/hooks/useReadTime";

type WrapPdfReaderProps = Merge<
  Omit<IPdfReader, "pdfReaderRef">,
  {
    thirdParty?: ThirdParty;
  }
>;

// PDF reader wrapper
const WrapPdfReader = forwardRef<IPdfReaderRef, WrapPdfReaderProps>(
  (props: WrapPdfReaderProps, pdfReaderRef) => {
    const {
      thirdParty,
      isAutoGeneratedRef,
      options,
      canRenderKeywords,
      readerLayout,
      rid,
      mode = "normal",
    } = props;
    // 阅读时长统计
    useReadTime({
      mode,
      rid,
      type: "pdf",
    });

    if (!thirdParty) {
      return null;
    }

    const result = checkIsThirdPartyAvailability(thirdParty);
    if (!result) {
      return null;
    }

    return (
      <PdfReaderProvider
        defaultParams={{
          thirdParty,
          isAutoGeneratedRef,
          options,
          pdfReaderRef,
          canRenderKeywords,
          readerLayout,
          mode,
          rid,
        }}
      >
        <Main />
      </PdfReaderProvider>
    );
  },
);

export default WrapPdfReader;
WrapPdfReader.displayName = "WrapPdfReader";
