import { useState, useEffect, useRef } from 'react';
import { getAuthor } from '../util';
import { useFloatToolbarStore } from '../store';

const useSafe = (url: string, noCheckSafe: boolean) => {
  const [isSafe, setIsSafe] = useState(false);
  const [safeReason, setReason] = useState(0);
  const [isWhiteListTest, setIsWhiteListTest] = useState(false); // 白名单测试
  const [isWhiteList, setIsWhiteList] = useState(false); // 白名单
  const [isBannedTest, setIsBannedTest] = useState(false); // 黑名单测试
  const [isNoIcpTest, setIsNoIcpTest] = useState(false); // 无备案测试
  const [failureReason, setFailureReason] = useState(''); // 失败原因
  const isSafeRef = useRef<boolean | undefined>(undefined);
  const { request } = useFloatToolbarStore();

  useEffect(() => {
    if(noCheckSafe) {
      return;
    }
    const checkSafe = async () => {
      try {
        if (isSafeRef.current !== undefined) {
          setIsSafe(isSafeRef.current);
        } else {
          const content = document.documentElement.innerText?.toLowerCase();
          const isICP =
            content?.includes('icp证') ||
            content?.includes('icp备') ||
            content?.includes('增值电信业务经营许可证');

          const icp = isICP ? 1 : 0;
          const author = getAuthor();
          const resp: {
            is_display: boolean;
            reason: number;
            failure_reason: string;
            is_whitelist_test: boolean;
            is_whitelist: boolean;
            is_banned_test: boolean;
            is_no_icp_test: boolean;
          } = await request({
            url: `/api/plugin/safety/display?url=${encodeURIComponent(url)}&icp=${icp}&author=${author}`,
          });

          setIsSafe(resp?.is_display);
          setReason(resp?.reason);
          setIsWhiteListTest(resp?.is_whitelist_test);
          setIsWhiteList(resp?.is_whitelist);
          setIsBannedTest(resp?.is_banned_test);
          setIsNoIcpTest(resp?.is_no_icp_test);
          setFailureReason(resp?.failure_reason);
          isSafeRef.current = resp?.is_display;
        }
      } catch (error: any) {
        console.error(error.msg);
      }
    };

    checkSafe();
  }, [url]);

  if(noCheckSafe) {
    return noCheckSafe;
  }

  return {
    isSafe,
    safeReason,
    isWhiteListTest,
    isWhiteList,
    isBannedTest,
    isNoIcpTest,
    failureReason,
  };
};

export default useSafe;
