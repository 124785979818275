import { AxiosResponse } from "axios";

// 通用文件下载
export function downloadFile(response: AxiosResponse) {
  const { data, headers } = response;
  const disposition = headers["content-disposition"];
  const fileName = decodeURI(disposition.split("=")[1])?.replace(/"/g, "");
  const blob = new Blob([data]);
  const blobUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.setAttribute("download", fileName);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(blobUrl); // 释放内存
  document.body.removeChild(link);
}

export function download(filename: string, content: string, type: string) {
  const blob = new Blob([content], { type });
  const reader = new FileReader();
  reader.onload = (event) => {
    const link = document.createElement('a');
    link.href = event.target?.result!.toString() as string;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  reader.readAsDataURL(blob);
}

export function downloadMd(filename: string, content: string) {
  download(filename, content,'text/markdown');
}
