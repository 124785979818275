import { ChromeCore } from "./chrome/chrome";
import { singleton } from "../utils";
class Browser {
    _self = null;
    constructor() {
        this.init();
    }
    init() {
        this._self = new ChromeCore();
    }
    copy(content) {
        this._self?.copy(content);
    }
    copyImage(src) {
        this._self?.copyImage(src);
    }
    copyTextAndImage(content, imageSrc) {
        this._self?.copyTextAndImage(content, imageSrc);
    }
    async copyHtmlByClipboard(content) {
        await this._self?.copyHtmlByClipboard(content);
    }
    copyHtmlByExecCommand(element, handleError, handleSuccess) {
        this._self?.copyHtmlByExecCommand(element, handleError, handleSuccess);
    }
    async copyElementByImage(element, handleError, handleSuccess) {
        await this._self?.copyElementByImage(element, handleError, handleSuccess);
    }
}
const SingleBrowser = singleton(Browser);
export default new SingleBrowser();
