import { OperationBar, BarItem } from "@deeplang/dui";
import cx from "classnames";
import {
  BackOriginal,
  Copy,
  Divider,
  Download,
  GreaterThan,
  RightOutlined,
} from "@deeplang/icons";

import "./MOperationBar.css";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useRelatedChartsStore } from "@/modules/related-charts/store";

export interface MOperationBarRef {
  updateIsLastItem: (value: boolean) => void;
  updateIsFirstItem: (value: boolean) => void;
}
interface MOperationBarProps {
  handleClickPrev: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleClickNext: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleClickCopy: () => void;
  handleClickDownload: () => void;
  handleClickBackOriginal: () => void;
}
export const MOperationBar = forwardRef<MOperationBarRef, MOperationBarProps>(
  (props: MOperationBarProps, ref) => {
    const {
      handleClickPrev,
      handleClickBackOriginal,
      handleClickNext,
      handleClickCopy,
      handleClickDownload,
    } = props;

    const { imageViewerOperationBarRef, imageViewerRef } =
      useRelatedChartsStore();
    const [isLastItem, setIsLastItem] = useState(false);
    const [isFirstItem, setIsFirstItem] = useState(false);
    useImperativeHandle(ref, () => ({
      updateIsFirstItem: (value: boolean) => {
        setIsFirstItem(value);
      },
      updateIsLastItem: (value: boolean) => {
        setIsLastItem(value);
      },
    }));

    useEffect(() => {
      imageViewerOperationBarRef.current?.updateIsLastItem(
        imageViewerRef.current?.isLastItem() || false,
      );
      imageViewerOperationBarRef.current?.updateIsFirstItem(
        imageViewerRef.current?.isFirstItem() || false,
      );
    }, []);

    const list = [
      <BarItem
        className={cx({ "prev-btn-container": isFirstItem })}
        key={"上一张"}
        title="上一张"
        content={
          <GreaterThan size={16} fill={isFirstItem ? "#8C8C8C" : "#FFFFFF"} />
        }
        onClick={handleClickPrev}
      />,
      <BarItem
        className={cx({ "next-btn-container": isLastItem })}
        key={"下一张"}
        title="下一张"
        onClick={handleClickNext}
        content={
          <RightOutlined color={isLastItem ? "#8C8C8C" : "#FFFFFF"} size={16} />
        }
      />,
      <BarItem
        key={"divider"}
        title=""
        content={
          <Divider
            strokeWidth={1}
            stroke={"rgba(241, 241, 241, 0.50)"}
            width={2}
            height={16}
            direction={"vertical"}
          />
        }
      />,
      <BarItem
        key={"复制"}
        title="复制"
        content={<Copy size={16} color={"#FFFFFF"} />}
        onClick={handleClickCopy}
      />,
      <BarItem
        key={"下载"}
        title="下载"
        content={<Download size={16} color={"#FFFFFF"} />}
        onClick={handleClickDownload}
      />,
      <BarItem
        key={"在新标签页中打开"}
        title="在新标签页中打开"
        content={
          <BackOriginal
            color="#FFFFFF"
            size={16}
            onClick={handleClickBackOriginal}
          />
        }
      />,
      ,
    ];
    return (
      <div className="related-charts-image-viewer-container">
        {" "}
        <OperationBar list={list} />
      </div>
    );
  },
);

MOperationBar.displayName = "MImageViewerOperationBar";
